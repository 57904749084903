import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useUpdateEffect } from 'react-use';
import {
    makeStyles,
    Table as MuiTable,
    TableContainer,
    TableHead,
    TableBody,
    MenuItem,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { TextField } from '@avangard/ui/core';
import { black, white } from '@avangard/ui/colors';
import { breakpoints } from '@avangard/ui/utils';

import { CommonHelper } from '@helpers';
import { useTableFiltersQuery } from '@modules/layout/hooks';
import { EmptyList } from '@modules/layout/icons';
import { Loader } from '@modules/layout/moleculas';
import { tablePerPage } from '@modules/layout/constants/table';
import { Sort } from '@modules/types/graphql';
import { getColumnFilterId } from './get-column-filter-id';
import { TableRow } from './table-row';
import { TableHeadCell } from './table-head-cell';
import { TableCell } from './table-cell';

import type { Theme } from '@material-ui/core';

export type TableCellType = {
    id: string;
    label: string;
    align?: 'left' | 'center' | 'right';
    width?: number;
    sortable?: boolean;
    orderBy?: string;
    orderField?: string | string[];
    orderByWith?: Record<string, any> | (() => Record<string, any>);
};

export type TableTotalProps = {
    title?: string | string[];
    value?: number;
    disabled?: boolean;
};

type TableProps = {
    id: string;
    columns: TableCellType[];
    total?: TableTotalProps;
    children: React.ReactNode;
    initialPerPage?: 10 | 20 | 50 | 100;
    loading?: boolean;
    freezeFirstColumn?: boolean;
    freezeLastColumn?: boolean;
    disabledPagination?: boolean;
    disabledFooter?: boolean;
    scrollable?: boolean;
    OriginTableProps?: Record<string, any>;
    onPageChange?: (perPage: number, page: number) => any;
    onPerPageChange?: (perPage: number, page: number) => any;
};

const useTableContainerStyles = makeStyles<
    Theme,
    Pick<TableProps, 'freezeFirstColumn' | 'freezeLastColumn'>
>({
    root: p => ({
        position: 'relative',

        '& table': {
            borderCollapse: 'separate',

            '& tr': {
                '& th, td': {
                    '&:first-child': !!p.freezeFirstColumn
                        ? {
                              position: 'sticky',
                              left: 0,
                              zIndex: 50,
                          }
                        : {},

                    '&:last-child': !!p.freezeLastColumn
                        ? {
                              position: 'sticky',
                              right: 0,
                              zIndex: 50,
                          }
                        : {},
                },
            },
        },
    }),
});

const usePerPageSelectPopoverStyles = makeStyles({
    paper: {
        marginTop: 32,
    },
});

const Root = styled.div<Pick<TableProps, 'scrollable'>>`
    width: 100%;

    ${p =>
        p.scrollable &&
        css`
            overflow-x: auto;

            &::-webkit-scrollbar {
                appearance: none;
                width: 0;
                height: 3px;
            }

            &::-webkit-scrollbar-track {
                background-color: ${white[100]};
            }

            &::-webkit-scrollbar-thumb {
                background-color: ${white[80]};
                outline: none;
                border-radius: 3px;
            }
        `}
`;

const Empty = styled.div`
    width: 100%;
    min-height: 70px;
    margin: 32px 0;
    text-align: center;

    > p {
        margin-top: 4px;
        color: ${black[20]};
    }
`;

const Footer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin: 16px 0;
`;

const TableTotal = styled.div`
    > p {
        font-size: 1.4rem;
    }

    ${breakpoints.down('xs')} {
        order: 1;
    }
`;

const TablePagePagination = styled.div`
    ${breakpoints.down('xs')} {
        display: flex;
        justify-content: center;
        width: 100%;
        order: 3;
        margin-top: 20px;
    }
`;

const TablePerPagePagination = styled.div`
    min-width: 110px;

    ${breakpoints.down('xs')} {
        order: 2;
    }
`;

const Table = (props: TableProps): React.ReactElement => {
    const {
        id: tableId,
        total,
        columns,
        children,
        initialPerPage,
        loading,
        freezeFirstColumn,
        freezeLastColumn,
        disabledPagination,
        disabledFooter,
        OriginTableProps,
        ...otherProps
    } = props;

    const { t } = useTranslation('common');

    const tableContainerClasses = useTableContainerStyles({
        freezeFirstColumn,
        freezeLastColumn,
    });
    const perPageSelectPopoverClasses = usePerPageSelectPopoverStyles();

    const { filters, query, setQuery } = useTableFiltersQuery();

    const initialPage = filters.page;
    const originPerPage = filters.perPage;

    const handleChangeOrder =
        (field: string, payload: Pick<TableCellType, 'orderField' | 'orderByWith'>) => () => {
            let orderBy: string | undefined = field;
            let orderDirection;
            let orderField = payload.orderField;
            let orderByWith = payload.orderByWith;

            if (filters.orderBy !== field || !filters.orderDirection) {
                orderDirection = Sort.desc;
            } else if (filters.orderDirection === Sort.desc) {
                orderDirection = Sort.asc;
            } else {
                orderBy = undefined;
                orderDirection = undefined;
                orderField = undefined;
                orderByWith = undefined;
            }

            setQuery({
                ...query,
                ...filters,
                orderBy,
                orderDirection,
                orderField,
                orderByWith,
            });
        };

    const handleChangePagination = (page: number, perPage: number): void =>
        setQuery({ ...query, page, perPage });

    const handlePageChange = (_: React.ChangeEvent<unknown>, page: number): void =>
        handleChangePagination(page, originPerPage);

    const handlePerPageChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ): void => handleChangePagination(1, Number(event.target.value));

    React.useEffect(() => {
        if (disabledPagination) {
            return;
        }

        if (initialPerPage) {
            handleChangePagination(1, initialPerPage);
        }
    }, []);

    useUpdateEffect(() => {
        if (disabledPagination || loading) {
            return;
        }

        const firstPage = initialPage === 1;
        const perPageMoreThenTotal = originPerPage > (total?.value ?? 0);

        if ((!total?.disabled && perPageMoreThenTotal && !firstPage) || !query.page) {
            handleChangePagination(1, originPerPage);
        }
    }, [loading, total?.value]);

    const hasRows = total?.value !== 0;
    const countPages = Math.ceil((total?.value ?? 1) / originPerPage);

    const renderedBody = React.useMemo((): React.ReactNode => {
        if (loading) {
            return (
                <TableRow>
                    <TableCell width='100%' colSpan={12}>
                        <Empty>
                            <Loader />
                            <p></p>
                        </Empty>
                    </TableCell>
                </TableRow>
            );
        }

        if (!loading && !hasRows) {
            return (
                <TableRow>
                    <TableCell width='100%' colSpan={12}>
                        <Empty>
                            <EmptyList />
                            <p>{t('stubs.empty')}</p>
                        </Empty>
                    </TableCell>
                </TableRow>
            );
        }

        return children;
    }, [t, loading, hasRows, children]);

    const renderedTotal = React.useMemo((): React.ReactElement | null => {
        if (total?.disabled || !total?.title) {
            return null;
        }

        const totalValue = total?.value ?? 0;

        let displayTotal = '';

        if (Array.isArray(total?.title)) {
            const titles = total?.title.map(title => title.replace('%d', String(totalValue)));

            displayTotal = CommonHelper.createCountFormatter(totalValue, titles, false);
        } else {
            displayTotal = total?.title.replace('%d', String(totalValue));
        }

        return (
            <TableTotal>
                <p>{displayTotal}</p>
            </TableTotal>
        );
    }, [t, total?.value]);

    return (
        <Root {...otherProps}>
            <TableContainer classes={tableContainerClasses}>
                <MuiTable {...OriginTableProps}>
                    <TableHead>
                        <TableRow size='xl'>
                            {columns.map(column => {
                                const columnFilterId = getColumnFilterId({
                                    orderBy: filters.orderBy,
                                    orderField: filters.orderField,
                                    orderByWith: filters.orderByWith,
                                });

                                const current = column.id === columnFilterId;

                                return (
                                    <TableHeadCell
                                        key={column.id}
                                        column={column}
                                        active={current}
                                        direction={current ? filters.orderDirection : Sort.desc}
                                        onClick={handleChangeOrder(column.orderBy ?? column.id, {
                                            orderField: column.orderField,
                                            orderByWith: column.orderByWith,
                                        })}
                                    />
                                );
                            })}
                        </TableRow>
                    </TableHead>

                    <TableBody>{renderedBody}</TableBody>
                </MuiTable>
            </TableContainer>

            {!disabledFooter ? (
                <Footer>
                    {renderedTotal}

                    {disabledPagination || !hasRows ? null : (
                        <>
                            <TablePagePagination>
                                <Pagination
                                    count={countPages}
                                    page={initialPage}
                                    siblingCount={0}
                                    boundaryCount={1}
                                    variant='outlined'
                                    shape='rounded'
                                    onChange={handlePageChange}
                                />
                            </TablePagePagination>

                            <TablePerPagePagination>
                                <TextField
                                    select
                                    id={`${tableId}-per-page`}
                                    size='extra-small'
                                    value={originPerPage}
                                    onChange={handlePerPageChange}
                                    SelectProps={{
                                        MenuProps: {
                                            classes: perPageSelectPopoverClasses,
                                            anchorOrigin: {
                                                vertical: 'top',
                                                horizontal: 'right',
                                            },
                                            transformOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            },
                                        },
                                    }}
                                >
                                    {tablePerPage.map(perPage => (
                                        <MenuItem key={perPage.titleKey} value={perPage.value}>
                                            {t(perPage.titleKey)}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </TablePerPagePagination>
                        </>
                    )}
                </Footer>
            ) : null}
        </Root>
    );
};

export { Table };
