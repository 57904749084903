import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    Date: any;
    Time: any;
};

/** Элемент журнала действий */
export type ActionLog = {
    __typename?: 'ActionLog';
    /** Время, когда произошло действие */
    at?: Maybe<Scalars['Time']>;
    /** Описание действия */
    description?: Maybe<Scalars['String']>;
    /** ID элемента журнала действий */
    id?: Maybe<Scalars['ID']>;
    /** Заезд, в котором произошло действие */
    lap?: Maybe<Lap>;
    /** ID заезда, в котором произошло действие */
    lapId?: Maybe<Scalars['ID']>;
    /** Платформа, в которой произошло действие */
    platform?: Maybe<Platform>;
    /** Пользователь, который совершил действие */
    user?: Maybe<User>;
    /** ID пользователя, который совершил действие */
    userId?: Maybe<Scalars['ID']>;
    /** Поля объекта, которые были изменены */
    values?: Maybe<Array<Maybe<ActionLogItemValue>>>;
};

export type ActionLogCollection = {
    __typename?: 'ActionLogCollection';
    page?: Maybe<ActionLogCollectionPage>;
    sections?: Maybe<Array<Maybe<ActionLogCollectionSection>>>;
};

export type ActionLogCollectionArgs = {
    filter?: Maybe<ActionLogFilter>;
    page?: Maybe<ActionLogCollectionPageArgs>;
};

export type ActionLogCollectionPage = {
    __typename?: 'ActionLogCollectionPage';
    hasNext?: Maybe<Scalars['Boolean']>;
    hasPrev?: Maybe<Scalars['Boolean']>;
    nextPage?: Maybe<Scalars['String']>;
    prevPage?: Maybe<Scalars['String']>;
};

export type ActionLogCollectionPageArgs = {
    /** токен следующей страницы */
    nextPage?: Maybe<Scalars['String']>;
    /** токен предыдущей страницы */
    prevPage?: Maybe<Scalars['String']>;
};

export type ActionLogCollectionSection = {
    __typename?: 'ActionLogCollectionSection';
    date?: Maybe<Scalars['Date']>;
    items?: Maybe<Array<Maybe<ActionLog>>>;
};

export type ActionLogFilter = {
    /** Показывать элементы журнала действий за все заезды */
    allLaps?: Maybe<Scalars['Boolean']>;
    /** Дата действия */
    date?: Maybe<Scalars['Date']>;
    /** ID сотрудника */
    employeeId?: Maybe<Scalars['ID']>;
    /** Часовой пояс */
    timezone?: Maybe<Scalars['String']>;
};

export type ActionLogItemValue = {
    __typename?: 'ActionLogItemValue';
    /** Название поля */
    label?: Maybe<Scalars['String']>;
    /** Новое значение поля */
    newValue?: Maybe<Scalars['String']>;
    /** Старое значение поля */
    oldValue?: Maybe<Scalars['String']>;
};

export type Building = {
    __typename?: 'Building';
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    rooms?: Maybe<Array<Maybe<BuildingRoom>>>;
};

export type BuildingRoom = {
    __typename?: 'BuildingRoom';
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
};

export type CdbCompanyRating = {
    __typename?: 'CDBCompanyRating';
    company?: Maybe<Scalars['Int']>;
    score?: Maybe<Scalars['Int']>;
};

export type CdbCounts = {
    __typename?: 'CDBCounts';
    date?: Maybe<CdbCountsDate>;
    onlineStudents?: Maybe<Scalars['Int']>;
    schools?: Maybe<Scalars['Int']>;
    students?: Maybe<Scalars['Int']>;
    weather?: Maybe<CdbCountsWeather>;
};

export type CdbCountsDate = {
    __typename?: 'CDBCountsDate';
    date?: Maybe<Scalars['String']>;
    time?: Maybe<Scalars['String']>;
};

export type CdbCountsWeather = {
    __typename?: 'CDBCountsWeather';
    condition?: Maybe<Scalars['String']>;
    icon?: Maybe<Scalars['String']>;
    temp?: Maybe<Scalars['Int']>;
};

export type CdbLessonSchedule = {
    __typename?: 'CDBLessonSchedule';
    current?: Maybe<Scalars['Boolean']>;
    end?: Maybe<Scalars['String']>;
    future?: Maybe<Scalars['Boolean']>;
    id?: Maybe<Scalars['ID']>;
    instructors?: Maybe<Array<Maybe<Scalars['String']>>>;
    place?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['String']>;
    subject?: Maybe<Scalars['String']>;
    subjectColor?: Maybe<Scalars['String']>;
};

export type CdbPlatoonRating = {
    __typename?: 'CDBPlatoonRating';
    company?: Maybe<Scalars['Int']>;
    platoonX?: Maybe<Scalars['Int']>;
    score?: Maybe<Scalars['Int']>;
};

export type CdbPlatoonsRatingCollection = {
    __typename?: 'CDBPlatoonsRatingCollection';
    count?: Maybe<Scalars['Int']>;
    items?: Maybe<Array<Maybe<CdbPlatoonsRatingCollectionItem>>>;
};

export type CdbPlatoonsRatingCollectionItem = {
    __typename?: 'CDBPlatoonsRatingCollectionItem';
    company?: Maybe<Scalars['Int']>;
    platoon?: Maybe<Scalars['Int']>;
    platoonX?: Maybe<Scalars['Int']>;
    score?: Maybe<Scalars['Int']>;
};

export type CdbRatings = {
    __typename?: 'CDBRatings';
    companies?: Maybe<Array<Maybe<CdbCompanyRating>>>;
    platoons?: Maybe<Array<Maybe<CdbPlatoonRating>>>;
};

export type CdbStudentsRatingCollection = {
    __typename?: 'CDBStudentsRatingCollection';
    items?: Maybe<Array<Maybe<CdbStudentsRatingCollectionItem>>>;
    page?: Maybe<PageReply>;
};

export type CdbStudentsRatingCollectionArgs = {
    filter?: Maybe<CdbStudentsRatingCollectionFilter>;
    page?: Maybe<PageArgs>;
};

export type CdbStudentsRatingCollectionFilter = {
    companies?: Maybe<Array<Maybe<Scalars['Int']>>>;
    fullName?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    platoons?: Maybe<Array<Maybe<Scalars['Int']>>>;
    platoonsX?: Maybe<Array<Maybe<Scalars['Int']>>>;
    school?: Maybe<SchoolCollectionFilter>;
};

export type CdbStudentsRatingCollectionItem = {
    __typename?: 'CDBStudentsRatingCollectionItem';
    company?: Maybe<Scalars['Int']>;
    /** Баллы за учебную деятельность */
    egScore?: Maybe<Scalars['Int']>;
    /** Баллы за критерии по Оцениванию */
    evaluationScore?: Maybe<Scalars['Int']>;
    fullName?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    isCommercial?: Maybe<Scalars['Boolean']>;
    isOnline?: Maybe<Scalars['Boolean']>;
    isOut?: Maybe<Scalars['Boolean']>;
    location?: Maybe<Scalars['String']>;
    photo?: Maybe<FileInfo>;
    place?: Maybe<Scalars['Int']>;
    platoon?: Maybe<Scalars['Int']>;
    platoonX?: Maybe<Scalars['Int']>;
    /** Итоговый балл */
    score?: Maybe<Scalars['Int']>;
    shortName?: Maybe<Scalars['String']>;
};

export enum CreditOfPoint {
    inCompany = 'inCompany',
    inPlatoon = 'inPlatoon',
    personalCredit = 'personalCredit',
    ranks = 'ranks',
    ratings = 'ratings',
}

export enum EgAttendance {
    absent = 'absent',
    attended = 'attended',
    released = 'released',
}

export type EgExportSchoolsModule = {
    mode: EgExportSchoolsModuleMode;
    moduleId: Scalars['ID'];
};

export enum EgExportSchoolsModuleMode {
    allSubjects = 'allSubjects',
    noExport = 'noExport',
    onlyModule = 'onlyModule',
    passModule = 'passModule',
}

export type EgLesson = {
    __typename?: 'EGLesson';
    /** рота */
    company?: Maybe<Scalars['Int']>;
    /** оценка за дисциплину по занятию */
    disciplineScore?: Maybe<Scalars['Int']>;
    id?: Maybe<Scalars['ID']>;
    instructor?: Maybe<Employee>;
    /** инструктор */
    instructorId?: Maybe<Scalars['ID']>;
    /** инструкторы */
    instructorIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
    instructors?: Maybe<Array<Maybe<Employee>>>;
    /** название занятия (дисциплина + тема) или мероприятия */
    name?: Maybe<Scalars['String']>;
    place?: Maybe<EgLessonPlace>;
    /** место проведения занятия */
    placeId?: Maybe<Scalars['ID']>;
    /** Название места проведения занятия или мероприятия */
    placeName?: Maybe<Scalars['String']>;
    /** взвод */
    platoon?: Maybe<Scalars['Int']>;
    /** общий взвод */
    platoonX?: Maybe<Scalars['Int']>;
    subject?: Maybe<EgSubject>;
    /** предмет */
    subjectId?: Maybe<Scalars['ID']>;
    time?: Maybe<EgLessonTime>;
    /** время занятия */
    timeId?: Maybe<Scalars['ID']>;
    topic?: Maybe<EgTopic>;
    /** тема */
    topicId?: Maybe<Scalars['ID']>;
    /** тип: занятие в электронном журнале или мероприятие в распорядке дня */
    type?: Maybe<EgType>;
};

export type EgLessonArgs = {
    company?: Maybe<Scalars['Int']>;
    instructorId?: Maybe<Scalars['ID']>;
    instructorIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
    name?: Maybe<Scalars['String']>;
    placeId?: Maybe<Scalars['ID']>;
    placeName?: Maybe<Scalars['String']>;
    platoon?: Maybe<Scalars['Int']>;
    platoonX?: Maybe<Scalars['Int']>;
    subjectId?: Maybe<Scalars['ID']>;
    timeId?: Maybe<Scalars['ID']>;
    topicId?: Maybe<Scalars['ID']>;
    type?: Maybe<EgType>;
};

export type EgLessonCollectionFilter = {
    company?: Maybe<Scalars['Int']>;
    id?: Maybe<Scalars['ID']>;
    instructor?: Maybe<EmployeeCollectionFilter>;
    place?: Maybe<EgLessonPlaceCollectionFilters>;
    platoon?: Maybe<Scalars['Int']>;
    subject?: Maybe<EgSubjectCollectionFilter>;
    time?: Maybe<EgLessonTimeCollectionFilter>;
};

export type EgLessonCollectionOrderBy = {
    company?: Maybe<Sort>;
    id?: Maybe<Sort>;
    instructor?: Maybe<EmployeeCollectionOrderBy>;
    place?: Maybe<EgLessonPlaceCollectionOrderBy>;
    platoon?: Maybe<Sort>;
    subject?: Maybe<EgSubjectCollectionOrderBy>;
    time?: Maybe<EgLessonTimeCollectionOrderBy>;
};

export type EgLessonCopyParam = {
    company?: Maybe<Scalars['Int']>;
    platoon?: Maybe<Scalars['Int']>;
    timeId?: Maybe<Scalars['ID']>;
};

export type EgLessonDisciplineScore = {
    __typename?: 'EGLessonDisciplineScore';
    createdAt?: Maybe<Scalars['Time']>;
    id?: Maybe<Scalars['ID']>;
    lessonId?: Maybe<Scalars['ID']>;
    score?: Maybe<Scalars['Int']>;
    updatedAt?: Maybe<Scalars['Time']>;
};

export type EgLessonMoveParams = {
    company?: Maybe<Scalars['Int']>;
    platoon?: Maybe<Scalars['Int']>;
    timeId?: Maybe<Scalars['ID']>;
};

export type EgLessonPlace = {
    __typename?: 'EGLessonPlace';
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    shortName?: Maybe<Scalars['String']>;
};

export type EgLessonPlaceArgs = {
    name?: Maybe<Scalars['String']>;
    shortName?: Maybe<Scalars['String']>;
};

export type EgLessonPlaceCollection = {
    __typename?: 'EGLessonPlaceCollection';
    items?: Maybe<Array<Maybe<EgLessonPlace>>>;
    page?: Maybe<PageReply>;
};

export type EgLessonPlaceCollectionArgs = {
    filter?: Maybe<EgLessonPlaceCollectionFilters>;
    orderBy?: Maybe<EgLessonPlaceCollectionOrderBy>;
    page?: Maybe<PageArgs>;
};

export type EgLessonPlaceCollectionFilters = {
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
};

export type EgLessonPlaceCollectionOrderBy = {
    name?: Maybe<Sort>;
};

export type EgLessonScore = {
    __typename?: 'EGLessonScore';
    attendance?: Maybe<EgAttendance>;
    createdAt?: Maybe<Scalars['Time']>;
    id?: Maybe<Scalars['ID']>;
    lesson?: Maybe<EgLesson>;
    lessonId?: Maybe<Scalars['ID']>;
    score?: Maybe<Scalars['Int']>;
    student?: Maybe<Student>;
    studentId?: Maybe<Scalars['ID']>;
    updatedAt?: Maybe<Scalars['Time']>;
};

export type EgLessonScoreArgs = {
    attendance?: Maybe<EgAttendance>;
    score?: Maybe<Scalars['Int']>;
    studentId: Scalars['ID'];
};

export type EgLessonScoreCollection = {
    __typename?: 'EGLessonScoreCollection';
    company?: Maybe<Scalars['Int']>;
    disciplineScore?: Maybe<Scalars['Int']>;
    instructors?: Maybe<Array<Maybe<Employee>>>;
    items?: Maybe<Array<Maybe<EgLessonScoreCollectionItem>>>;
    lessonDate?: Maybe<Scalars['String']>;
    lessonIndex?: Maybe<Scalars['Int']>;
    lessonPlace?: Maybe<Scalars['String']>;
    lessonTime?: Maybe<Scalars['String']>;
    page?: Maybe<PageReply>;
    platoon?: Maybe<Scalars['Int']>;
    platoonX?: Maybe<Scalars['Int']>;
};

export type EgLessonScoreCollectionArgs = {
    filter?: Maybe<EgLessonScoreCollectionFilter>;
    orderBy?: Maybe<EgLessonScoreCollectionOrderBy>;
    page?: Maybe<PageArgs>;
};

export type EgLessonScoreCollectionFilter = {
    id?: Maybe<Scalars['ID']>;
    lessonId: Scalars['ID'];
    score?: Maybe<Scalars['Int']>;
    student?: Maybe<StudentCollectionFilter>;
};

export type EgLessonScoreCollectionItem = {
    __typename?: 'EGLessonScoreCollectionItem';
    attendance?: Maybe<EgAttendance>;
    locationName?: Maybe<Scalars['String']>;
    schoolName?: Maybe<Scalars['String']>;
    schoolType?: Maybe<Scalars['String']>;
    score?: Maybe<Scalars['Int']>;
    student?: Maybe<StudentMiniInfo>;
};

export type EgLessonScoreCollectionOrderBy = {
    createdAt?: Maybe<Sort>;
    id?: Maybe<Sort>;
    score?: Maybe<Sort>;
    student?: Maybe<StudentCollectionOrderBy>;
    updatedAt?: Maybe<Sort>;
};

export type EgLessonScoreCollectionSchool = {
    __typename?: 'EGLessonScoreCollectionSchool';
    items?: Maybe<Array<Maybe<EgLessonScoreCollectionSchoolItem>>>;
    page?: Maybe<PageReply>;
};

export type EgLessonScoreCollectionSchoolArgs = {
    filter?: Maybe<EgLessonScoreCollectionSchoolFilter>;
    orderBy?: Maybe<EgLessonScoreCollectionSchoolOrderBy>;
    page?: Maybe<PageArgs>;
};

export type EgLessonScoreCollectionSchoolFilter = {
    companies?: Maybe<Array<Maybe<Scalars['Int']>>>;
    date?: Maybe<Scalars['Date']>;
    fullName?: Maybe<Scalars['String']>;
    platoons?: Maybe<Array<Maybe<Scalars['Int']>>>;
    score?: Maybe<Scalars['Int']>;
    subjectId?: Maybe<Scalars['ID']>;
};

export type EgLessonScoreCollectionSchoolItem = {
    __typename?: 'EGLessonScoreCollectionSchoolItem';
    attendance?: Maybe<EgAttendance>;
    company?: Maybe<Scalars['Int']>;
    fullName?: Maybe<Scalars['String']>;
    instructors?: Maybe<Array<Maybe<Employee>>>;
    platoon?: Maybe<Scalars['Int']>;
    platoonX?: Maybe<Scalars['Int']>;
    score?: Maybe<Scalars['Int']>;
    subject?: Maybe<Scalars['String']>;
    subjectColor?: Maybe<Scalars['String']>;
    time?: Maybe<Scalars['String']>;
    topic?: Maybe<EgTopic>;
};

export type EgLessonScoreCollectionSchoolOrderBy = {
    company?: Maybe<Sort>;
    fullName?: Maybe<Sort>;
    platoon?: Maybe<Sort>;
    score?: Maybe<Sort>;
    subject?: Maybe<Sort>;
    time?: Maybe<Sort>;
};

export type EgLessonScoresStatistic = {
    __typename?: 'EGLessonScoresStatistic';
    dates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    items?: Maybe<Array<Maybe<EgLessonScoresStatisticItem>>>;
};

export type EgLessonScoresStatisticArgs = {
    platoonX?: Maybe<Scalars['Int']>;
    studentName?: Maybe<Scalars['String']>;
    subjectId?: Maybe<Scalars['ID']>;
};

export type EgLessonScoresStatisticItem = {
    __typename?: 'EGLessonScoresStatisticItem';
    scores?: Maybe<Array<Maybe<Scalars['Int']>>>;
    student?: Maybe<StudentMiniInfo>;
    totalScore?: Maybe<Scalars['Int']>;
};

export type EgLessonScoresStatisticItemScore = {
    __typename?: 'EGLessonScoresStatisticItemScore';
    date?: Maybe<Scalars['Date']>;
    score?: Maybe<Scalars['Int']>;
};

export type EgLessonScoresStudent = {
    __typename?: 'EGLessonScoresStudent';
    items?: Maybe<Array<Maybe<EgLessonScoresStudentItem>>>;
    student?: Maybe<StudentMiniInfo>;
};

export type EgLessonScoresStudentItem = {
    __typename?: 'EGLessonScoresStudentItem';
    attendance?: Maybe<EgAttendance>;
    date?: Maybe<Scalars['Date']>;
    instructors?: Maybe<Array<Maybe<Scalars['String']>>>;
    score?: Maybe<Scalars['Int']>;
    subject?: Maybe<Scalars['String']>;
    subjectColor?: Maybe<Scalars['String']>;
    timeRange?: Maybe<Scalars['String']>;
};

export type EgLessonStatisticArgs = {
    filter?: Maybe<EgLessonsStatisticFilter>;
    orderBy?: Maybe<EgLessonsStatisticOrderBy>;
    page?: Maybe<PageArgs>;
};

export type EgLessonTime = {
    __typename?: 'EGLessonTime';
    createdAt?: Maybe<Scalars['Time']>;
    date?: Maybe<Scalars['Date']>;
    end?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    lapId?: Maybe<Scalars['ID']>;
    start?: Maybe<Scalars['String']>;
    /** отрезок времени основа для электронного журнала или распорядка дня */
    type?: Maybe<EgType>;
    updatedAt?: Maybe<Scalars['Time']>;
};

export type EgLessonTimeArgs = {
    date?: Maybe<Scalars['Date']>;
    end?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['String']>;
    type?: Maybe<EgType>;
};

export type EgLessonTimeCollectionFilter = {
    date?: Maybe<Scalars['Date']>;
    end?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    lap?: Maybe<LapCollectionFilter>;
    start?: Maybe<Scalars['String']>;
    type?: Maybe<EgType>;
};

export type EgLessonTimeCollectionOrderBy = {
    createdAt?: Maybe<Sort>;
    date?: Maybe<Sort>;
    end?: Maybe<Sort>;
    id?: Maybe<Sort>;
    lap?: Maybe<LapCollectionOrderBy>;
    start?: Maybe<Sort>;
    updatedAt?: Maybe<Sort>;
};

export type EgLessonTimeListArgs = {
    lapFrom?: Maybe<Scalars['Date']>;
    lapId?: Maybe<Scalars['ID']>;
    lapTo?: Maybe<Scalars['Date']>;
    studentId?: Maybe<Scalars['ID']>;
    type: EgType;
};

export enum EgLessonTimelineStatus {
    current = 'current',
    future = 'future',
    passed = 'passed',
}

export type EgLessonsCopyParams = {
    fromDate: Scalars['Date'];
    fromLapId: Scalars['ID'];
    toDate: Scalars['Date'];
    toLapId?: Maybe<Scalars['ID']>;
};

export type EgLessonsList = {
    __typename?: 'EGLessonsList';
    items?: Maybe<Array<Maybe<EgLessonsListItem>>>;
    page?: Maybe<PageReply>;
    timeList?: Maybe<Array<Maybe<EgLessonTime>>>;
};

export type EgLessonsListArgs = {
    filter: EgLessonsListFilter;
    page?: Maybe<PageArgs>;
    settings?: Maybe<EgLessonsSettings>;
};

export type EgLessonsListFilter = {
    date: Scalars['Date'];
    instructorId?: Maybe<Scalars['ID']>;
    lapId?: Maybe<Scalars['ID']>;
    onlyMyLessons?: Maybe<Scalars['Boolean']>;
    subjectId?: Maybe<Scalars['ID']>;
    topicId?: Maybe<Scalars['ID']>;
};

export type EgLessonsListItem = {
    __typename?: 'EGLessonsListItem';
    /** средняя оценка */
    averageScore?: Maybe<Scalars['Float']>;
    /** цвет предмета */
    color?: Maybe<Scalars['String']>;
    company?: Maybe<Scalars['Int']>;
    /** ID занятия */
    id?: Maybe<Scalars['ID']>;
    /** Список ФИО инструкторов */
    instructors?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** название предмета */
    name?: Maybe<Scalars['String']>;
    /** название места проведения занятия */
    place?: Maybe<Scalars['String']>;
    platoon?: Maybe<Scalars['Int']>;
    platoonX?: Maybe<Scalars['Int']>;
    /** кол-во проставленных оценок */
    scoresCount?: Maybe<Scalars['Int']>;
    /** общее кол-во курсантов на занятии */
    studentsCount?: Maybe<Scalars['Int']>;
    timeRange?: Maybe<Scalars['String']>;
    /** тип занятия */
    type?: Maybe<EgType>;
};

export type EgLessonsSettings = {
    includeStatistics?: Maybe<Scalars['Boolean']>;
};

export type EgLessonsStatistic = {
    __typename?: 'EGLessonsStatistic';
    items?: Maybe<Array<Maybe<EgLessonsStatisticItem>>>;
    page?: Maybe<PageReply>;
};

export type EgLessonsStatisticFilter = {
    company?: Maybe<Scalars['Int']>;
    date?: Maybe<Scalars['Date']>;
    instructorFullName?: Maybe<Scalars['String']>;
    instructorId?: Maybe<Scalars['ID']>;
    lapId?: Maybe<Scalars['ID']>;
    platoon?: Maybe<Scalars['Int']>;
    platoonX?: Maybe<Scalars['Int']>;
    subjectId?: Maybe<Scalars['ID']>;
    timeRange?: Maybe<Scalars['String']>;
    topicId?: Maybe<Scalars['ID']>;
};

export type EgLessonsStatisticItem = {
    __typename?: 'EGLessonsStatisticItem';
    /** средняя оценка */
    averageScore?: Maybe<Scalars['Float']>;
    /** рота */
    company?: Maybe<Scalars['Int']>;
    /** дата */
    date?: Maybe<Scalars['Date']>;
    /** ID занятия */
    id?: Maybe<Scalars['ID']>;
    /** список инструкторов, которые ведут занятия */
    instructors?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** название место проведения */
    place?: Maybe<Scalars['String']>;
    /** взвод */
    platoon?: Maybe<Scalars['Int']>;
    /** общий взвод */
    platoonX?: Maybe<Scalars['Int']>;
    /** кол-во проставленных оценок */
    scoresCount?: Maybe<Scalars['Int']>;
    /** кол-во всего курсантов на занятии */
    studentsCount?: Maybe<Scalars['Int']>;
    /** название дисциплины */
    subject?: Maybe<Scalars['String']>;
    /** цвет дисциплины */
    subjectColor?: Maybe<Scalars['String']>;
    /** время занятия */
    timeRange?: Maybe<Scalars['String']>;
};

export type EgLessonsStatisticOrderBy = {
    averageScore?: Maybe<Sort>;
    company?: Maybe<Sort>;
    date?: Maybe<Sort>;
    id?: Maybe<Sort>;
    instructors?: Maybe<Sort>;
    place?: Maybe<Sort>;
    platoon?: Maybe<Sort>;
    platoonX?: Maybe<Sort>;
    scoresCount?: Maybe<Sort>;
    subject?: Maybe<Sort>;
    timeRange?: Maybe<Sort>;
};

export type EgLessonsStudentArgs = {
    date?: Maybe<Scalars['Date']>;
    lapByStudentId?: Maybe<Scalars['Boolean']>;
    lapFrom?: Maybe<Scalars['Date']>;
    lapTo?: Maybe<Scalars['Date']>;
    orderBy?: Maybe<EgLessonsStudentOrderBy>;
    studentId?: Maybe<Scalars['ID']>;
};

export type EgLessonsStudentItem = {
    __typename?: 'EGLessonsStudentItem';
    attendance?: Maybe<EgAttendance>;
    instructors?: Maybe<Array<Maybe<Employee>>>;
    place?: Maybe<Scalars['String']>;
    score?: Maybe<Scalars['Int']>;
    subject?: Maybe<Scalars['String']>;
    subjectColor?: Maybe<Scalars['String']>;
    time?: Maybe<Scalars['String']>;
    topic?: Maybe<EgTopic>;
};

export type EgLessonsStudentOrderBy = {
    place?: Maybe<Sort>;
    score?: Maybe<Sort>;
    subject?: Maybe<Sort>;
    time?: Maybe<Sort>;
};

export type EgLessonsTable = {
    __typename?: 'EGLessonsTable';
    rows?: Maybe<Array<Maybe<EgLessonsTableRow>>>;
    timeList?: Maybe<Array<Maybe<EgLessonTime>>>;
};

export type EgLessonsTableArgs = {
    date: Scalars['Date'];
    lapId?: Maybe<Scalars['ID']>;
    settings?: Maybe<EgLessonsSettings>;
    /**
     * Для какого раздела нужно вывести таблицу занятий/мероприятий.
     * Если `gradebook`, то будут выведены только отрезки времени для Электронного журнала.
     * Иначе будут выведены отрезки времени и для Распорядка дня
     */
    type?: Maybe<EgType>;
};

export type EgLessonsTableCol = {
    __typename?: 'EGLessonsTableCol';
    /** средняя оценка */
    averageScore?: Maybe<Scalars['Float']>;
    /** цвет предмета */
    color?: Maybe<Scalars['String']>;
    /** ID занятия */
    id?: Maybe<Scalars['ID']>;
    /** Индекс занятия. Отсчет с нуля */
    index?: Maybe<Scalars['Int']>;
    /** Фамилия И.О. инструктора */
    instructor?: Maybe<Scalars['String']>;
    instructorId?: Maybe<Scalars['ID']>;
    /** Список ФИО инструкторов */
    instructors?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** название предмета */
    name?: Maybe<Scalars['String']>;
    /** название места проведения занятия */
    place?: Maybe<Scalars['String']>;
    /** кол-во проставленных оценок */
    scoresCount?: Maybe<Scalars['Int']>;
    /** общее кол-во курсантов на занятии */
    studentsCount?: Maybe<Scalars['Int']>;
    /** время занятий */
    timeRange?: Maybe<Scalars['String']>;
    /** тип занятия */
    type?: Maybe<EgType>;
};

export type EgLessonsTableRow = {
    __typename?: 'EGLessonsTableRow';
    cols?: Maybe<Array<Maybe<EgLessonsTableCol>>>;
    company?: Maybe<Scalars['Int']>;
    platoon?: Maybe<Scalars['Int']>;
    platoonX?: Maybe<Scalars['Int']>;
};

export type EgLessonsTimeline = {
    __typename?: 'EGLessonsTimeline';
    items?: Maybe<Array<Maybe<EgLessonsTimelineItem>>>;
};

export type EgLessonsTimelineArgs = {
    filter: EgLessonsTimelineFilter;
};

export type EgLessonsTimelineFilter = {
    date: Scalars['Date'];
    lapId?: Maybe<Scalars['ID']>;
    platoonX: Scalars['Int'];
};

export type EgLessonsTimelineItem = {
    __typename?: 'EGLessonsTimelineItem';
    /** длительность в минутах, например, 45 */
    duration?: Maybe<Scalars['Int']>;
    /** ID занятия */
    id?: Maybe<Scalars['ID']>;
    /** Список ФИО инструкторов */
    instructors?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** название предмета */
    name?: Maybe<Scalars['String']>;
    /** название места проведения занятия */
    place?: Maybe<Scalars['String']>;
    /** статус времени занятия */
    status?: Maybe<EgLessonTimelineStatus>;
    /** промежуток времени занятия, например 09:00 - 09:45 */
    timeRange?: Maybe<Scalars['String']>;
    /** тип занятия */
    type?: Maybe<EgType>;
};

export type EgModule = {
    __typename?: 'EGModule';
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    subjects?: Maybe<Array<Maybe<EgSubject>>>;
};

export type EgModuleCollection = {
    __typename?: 'EGModuleCollection';
    items?: Maybe<Array<Maybe<EgModule>>>;
    page?: Maybe<PageReply>;
};

export type EgModuleCollectionArgs = {
    filter?: Maybe<EgModuleCollectionFilter>;
    orderBy?: Maybe<EgModuleCollectionOrderBy>;
    page?: Maybe<PageArgs>;
};

export type EgModuleCollectionFilter = {
    name?: Maybe<Scalars['String']>;
};

export type EgModuleCollectionOrderBy = {
    name?: Maybe<Sort>;
};

export type EgModuleCreateArgs = {
    name?: Maybe<Scalars['String']>;
};

export type EgModuleUpdateArgs = {
    name?: Maybe<Scalars['String']>;
    subjectsIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type EgSubject = {
    __typename?: 'EGSubject';
    color?: Maybe<EgSubjectColor>;
    createdAt?: Maybe<Scalars['Time']>;
    description?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    moduleId?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    shortName?: Maybe<Scalars['String']>;
    topics?: Maybe<Array<Maybe<EgTopic>>>;
};

export type EgSubjectCollection = {
    __typename?: 'EGSubjectCollection';
    items?: Maybe<Array<Maybe<EgSubject>>>;
    page?: Maybe<PageReply>;
};

export type EgSubjectCollectionArgs = {
    filter?: Maybe<EgSubjectCollectionFilter>;
    orderBy?: Maybe<EgSubjectCollectionOrderBy>;
    page?: Maybe<PageArgs>;
};

export type EgSubjectCollectionFilter = {
    description?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    moduleId?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
};

export type EgSubjectCollectionOrderBy = {
    createdAt?: Maybe<Sort>;
    id?: Maybe<Sort>;
    name?: Maybe<Sort>;
};

export type EgSubjectColor = {
    __typename?: 'EGSubjectColor';
    name?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['String']>;
};

export type EgSubjectColorArgs = {
    name?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['String']>;
};

export type EgSubjectCreateArgs = {
    color?: Maybe<EgSubjectColorArgs>;
    description?: Maybe<Scalars['String']>;
    moduleId?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    shortName?: Maybe<Scalars['String']>;
};

export type EgSubjectUpdateArgs = {
    color?: Maybe<EgSubjectColorArgs>;
    description?: Maybe<Scalars['String']>;
    moduleId?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    shortName?: Maybe<Scalars['String']>;
    topics?: Maybe<Array<Maybe<EgTopicArgs>>>;
};

export type EgTopic = {
    __typename?: 'EGTopic';
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
};

export type EgTopicArgs = {
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
};

export enum EgType {
    /** распорядок дня */
    dailySchedule = 'daily_schedule',
    /** электронный журнал */
    gradebook = 'gradebook',
}

export type Employee = {
    __typename?: 'Employee';
    accessSentAt?: Maybe<Scalars['Time']>;
    accessSentStatus?: Maybe<Scalars['String']>;
    accessSentStatusText?: Maybe<Scalars['String']>;
    attachedUser?: Maybe<User>;
    biography?: Maybe<Scalars['String']>;
    biographyTranslations?: Maybe<Array<Maybe<Translation>>>;
    birthdate?: Maybe<Scalars['Date']>;
    createdAt?: Maybe<Scalars['Time']>;
    displayInStand?: Maybe<Scalars['Boolean']>;
    email?: Maybe<Scalars['String']>;
    fullName?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    isAccessSent?: Maybe<Scalars['Boolean']>;
    isArchived?: Maybe<Scalars['Boolean']>;
    isBlocked?: Maybe<Scalars['Boolean']>;
    phone?: Maybe<Scalars['String']>;
    photo?: Maybe<FileInfo>;
    photoId?: Maybe<Scalars['ID']>;
    qrCodeUrl?: Maybe<Scalars['String']>;
    role?: Maybe<EmployeeRole>;
    specialization?: Maybe<Scalars['String']>;
    specializationTranslations?: Maybe<Array<Maybe<Translation>>>;
};

export type EmployeeArgs = {
    biography?: Maybe<Scalars['String']>;
    biographyTranslations?: Maybe<Array<Maybe<TranslationInput>>>;
    birthdate?: Maybe<Scalars['Date']>;
    displayInStand?: Maybe<Scalars['Boolean']>;
    email?: Maybe<Scalars['String']>;
    fullName?: Maybe<Scalars['String']>;
    isBlocked?: Maybe<Scalars['Boolean']>;
    phone?: Maybe<Scalars['String']>;
    photoId?: Maybe<Scalars['ID']>;
    role?: Maybe<EmployeeRole>;
    specialization?: Maybe<Scalars['String']>;
    specializationTranslations?: Maybe<Array<Maybe<TranslationInput>>>;
};

export type EmployeeCollection = {
    __typename?: 'EmployeeCollection';
    items?: Maybe<Array<Maybe<Employee>>>;
    page?: Maybe<PageReply>;
};

export type EmployeeCollectionArgs = {
    filter?: Maybe<EmployeeCollectionFilter>;
    orderBy?: Maybe<EmployeeCollectionOrderBy>;
    page?: Maybe<PageArgs>;
};

export type EmployeeCollectionFilter = {
    birthdate?: Maybe<Scalars['Date']>;
    email?: Maybe<Scalars['String']>;
    fullName?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    isArchived?: Maybe<Scalars['Boolean']>;
    isBlocked?: Maybe<Scalars['Boolean']>;
    phone?: Maybe<Scalars['String']>;
    role?: Maybe<EmployeeRole>;
    roles?: Maybe<Array<Maybe<EmployeeRole>>>;
};

export type EmployeeCollectionOrderBy = {
    birthdate?: Maybe<Sort>;
    createdAt?: Maybe<Sort>;
    email?: Maybe<Sort>;
    fullName?: Maybe<Sort>;
    id?: Maybe<Sort>;
    phone?: Maybe<Sort>;
    role?: Maybe<Sort>;
};

export enum EmployeeRole {
    admin = 'admin',
    instructor = 'instructor',
    juniorLeader = 'junior_leader',
    leader = 'leader',
    mentor = 'mentor',
    methodist = 'methodist',
    organizingTeacher = 'organizing_teacher',
    psychologist = 'psychologist',
    registrar = 'registrar',
    seniorInstructor = 'senior_instructor',
}

export type Evaluation = {
    __typename?: 'Evaluation';
    avgScore?: Maybe<Scalars['Float']>;
    company?: Maybe<Scalars['Int']>;
    date?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    leader?: Maybe<Employee>;
    mentor?: Maybe<Employee>;
    platoon?: Maybe<Scalars['Int']>;
    platoonX?: Maybe<Scalars['Int']>;
    settledScore?: Maybe<Scalars['Int']>;
    summaryScore?: Maybe<Scalars['Int']>;
};

export type EvaluationCollection = {
    __typename?: 'EvaluationCollection';
    items?: Maybe<Array<Maybe<Evaluation>>>;
};

export type EvaluationCriteria = {
    __typename?: 'EvaluationCriteria';
    /** если `true`, то этот критерий для оценивания курсантов */
    forStudent?: Maybe<Scalars['Boolean']>;
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
};

export type EvaluationCriteriaArgs = {
    /** если `true`, то этот критерий для оценивания курсантов */
    forStudent?: Maybe<Scalars['Boolean']>;
    name?: Maybe<Scalars['String']>;
};

export type EvaluationCriteriaCollection = {
    __typename?: 'EvaluationCriteriaCollection';
    items?: Maybe<Array<Maybe<EvaluationCriteria>>>;
};

export type EvaluationResponsible = {
    __typename?: 'EvaluationResponsible';
    company?: Maybe<Scalars['Int']>;
    lapId?: Maybe<Scalars['ID']>;
    leader?: Maybe<Employee>;
    mentor?: Maybe<Employee>;
    platoon?: Maybe<Scalars['Int']>;
    platoonX?: Maybe<Scalars['Int']>;
};

export type EvaluationResponsibleArgs = {
    leaderId?: Maybe<Scalars['ID']>;
    mentorId?: Maybe<Scalars['ID']>;
};

export type EvaluationResponsibleCollection = {
    __typename?: 'EvaluationResponsibleCollection';
    items?: Maybe<Array<Maybe<EvaluationResponsible>>>;
};

export type EvaluationScore = {
    __typename?: 'EvaluationScore';
    createdAt?: Maybe<Scalars['Time']>;
    criteria?: Maybe<EvaluationCriteria>;
    evaluation?: Maybe<Evaluation>;
    id?: Maybe<Scalars['ID']>;
    score?: Maybe<Scalars['Int']>;
    updatedAt?: Maybe<Scalars['Time']>;
};

export type EvaluationScoreCollection = {
    __typename?: 'EvaluationScoreCollection';
    items?: Maybe<Array<Maybe<EvaluationScoreCollectionItem>>>;
};

export type EvaluationScoreCollectionArgs = {
    filter?: Maybe<EvaluationScoreCollectionFilter>;
    orderBy?: Maybe<EvaluationScoreCollectionOrderBy>;
};

export type EvaluationScoreCollectionFilter = {
    evaluationId?: Maybe<Scalars['ID']>;
};

export type EvaluationScoreCollectionItem = {
    __typename?: 'EvaluationScoreCollectionItem';
    createdAt?: Maybe<Scalars['Time']>;
    criteriaId?: Maybe<Scalars['ID']>;
    criteriaName?: Maybe<Scalars['String']>;
    score?: Maybe<Scalars['Int']>;
    updatedAt?: Maybe<Scalars['Time']>;
};

export type EvaluationScoreCollectionOrderBy = {
    criteriaName?: Maybe<Sort>;
    score?: Maybe<Sort>;
};

export type EvaluationStudentCollection = {
    __typename?: 'EvaluationStudentCollection';
    items?: Maybe<Array<Maybe<EvaluationStudentCollectionItem>>>;
};

export type EvaluationStudentCollectionItem = {
    __typename?: 'EvaluationStudentCollectionItem';
    avgScore?: Maybe<Scalars['Float']>;
    company?: Maybe<Scalars['Int']>;
    evaluationId?: Maybe<Scalars['ID']>;
    id?: Maybe<Scalars['ID']>;
    leader?: Maybe<Employee>;
    mentor?: Maybe<Employee>;
    platoon?: Maybe<Scalars['Int']>;
    platoonX?: Maybe<Scalars['Int']>;
    settledScore?: Maybe<Scalars['Int']>;
    summaryScore?: Maybe<Scalars['Int']>;
};

export type EvaluationStudentScore = {
    __typename?: 'EvaluationStudentScore';
    criteria?: Maybe<EvaluationCriteria>;
    id?: Maybe<Scalars['ID']>;
    score?: Maybe<Scalars['Int']>;
    student?: Maybe<StudentMiniInfo>;
};

export type EvaluationStudentScoreArgs = {
    criteriaId: Scalars['ID'];
    score: Scalars['Int'];
    studentId: Scalars['ID'];
};

export type EvaluationStudentScoreCollection = {
    __typename?: 'EvaluationStudentScoreCollection';
    criteriaList?: Maybe<Array<Maybe<EvaluationCriteria>>>;
    items?: Maybe<Array<Maybe<EvaluationStudentScoreCollectionItem>>>;
};

export type EvaluationStudentScoreCollectionItem = {
    __typename?: 'EvaluationStudentScoreCollectionItem';
    scores?: Maybe<Array<Maybe<EvaluationStudentScoreCollectionItemScore>>>;
    student?: Maybe<StudentMiniInfo>;
};

export type EvaluationStudentScoreCollectionItemScore = {
    __typename?: 'EvaluationStudentScoreCollectionItemScore';
    criteriaId?: Maybe<Scalars['ID']>;
    score?: Maybe<Scalars['Int']>;
};

export type EvaluationStudentScores = {
    __typename?: 'EvaluationStudentScores';
    scores?: Maybe<Array<Maybe<EvaluationStudentScoresItem>>>;
    student?: Maybe<StudentMiniInfo>;
};

export type EvaluationStudentScoresArgs = {
    scores: Array<EvaluationStudentScoresItemArgs>;
    studentId: Scalars['ID'];
};

export type EvaluationStudentScoresItem = {
    __typename?: 'EvaluationStudentScoresItem';
    criteriaId?: Maybe<Scalars['ID']>;
    criteriaName?: Maybe<Scalars['String']>;
    score?: Maybe<Scalars['Int']>;
};

export type EvaluationStudentScoresItemArgs = {
    criteriaId: Scalars['ID'];
    score?: Maybe<Scalars['Int']>;
};

export type Event = {
    __typename?: 'Event';
    date?: Maybe<Scalars['Date']>;
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    photo?: Maybe<FileInfo>;
    photoId?: Maybe<Scalars['ID']>;
    placeName?: Maybe<Scalars['String']>;
    time?: Maybe<Scalars['String']>;
};

export type EventCollection = {
    __typename?: 'EventCollection';
    items?: Maybe<Array<Maybe<Event>>>;
    page?: Maybe<PageReply>;
};

export type EventCollectionArgs = {
    filter?: Maybe<EventCollectionFilter>;
    orderBy?: Maybe<EventCollectionOrderBy>;
    page?: Maybe<PageArgs>;
};

export type EventCollectionFilter = {
    id?: Maybe<Scalars['ID']>;
};

export type EventCollectionOrderBy = {
    date?: Maybe<Sort>;
    id?: Maybe<Sort>;
    name?: Maybe<Sort>;
    placeName?: Maybe<Sort>;
    time?: Maybe<Sort>;
};

export type EventCreateArgs = {
    date?: Maybe<Scalars['Date']>;
    name?: Maybe<Scalars['String']>;
    photoId?: Maybe<Scalars['ID']>;
    placeName?: Maybe<Scalars['String']>;
    time?: Maybe<Scalars['String']>;
};

export type EventUpdateArgs = {
    date?: Maybe<Scalars['Date']>;
    name?: Maybe<Scalars['String']>;
    photoId?: Maybe<Scalars['ID']>;
    placeName?: Maybe<Scalars['String']>;
    time?: Maybe<Scalars['String']>;
};

/**
 * Название материала
 * и ID загруженного файла
 *
 * Файл загружается POST запросом по `{api_url}/files`
 */
export type FileDoc = {
    __typename?: 'FileDoc';
    file?: Maybe<FileInfo>;
    fileId?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
};

/**
 * Название материала
 * и ID загруженного файла
 *
 * Файл загружается POST запросом по `{api_url}/files`
 */
export type FileDocArg = {
    fileId?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
};

/**
 * Информация о файле
 *
 * Файл загружается POST запросом по `{api_url}/files`
 */
export type FileInfo = {
    __typename?: 'FileInfo';
    createdAt?: Maybe<Scalars['Time']>;
    cropInfo?: Maybe<ImageCropInfo>;
    croppedThumbnail?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    mime?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    origin?: Maybe<Scalars['String']>;
    thumbnail?: Maybe<Scalars['String']>;
};

export type FindInternalWikiArticle = {
    __typename?: 'FindInternalWikiArticle';
    categoryId?: Maybe<Scalars['ID']>;
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
};

export type FindInternalWikiCategory = {
    __typename?: 'FindInternalWikiCategory';
    articlesCount?: Maybe<Scalars['Int']>;
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
};

export type FindInternalWikiFilter = {
    categoryId?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    searchInCategories?: Maybe<Scalars['Boolean']>;
};

export type FindInternalWikiReply = {
    __typename?: 'FindInternalWikiReply';
    articles?: Maybe<Array<Maybe<FindInternalWikiArticle>>>;
    categories?: Maybe<Array<Maybe<FindInternalWikiCategory>>>;
    itemsCount?: Maybe<Scalars['Int']>;
};

export type ForgotPasswordReply = {
    __typename?: 'ForgotPasswordReply';
    email?: Maybe<Scalars['String']>;
};

export enum Gender {
    female = 'female',
    male = 'male',
    nogender = 'nogender',
}

export type HandbookCenterStudent = {
    __typename?: 'HandbookCenterStudent';
    fullName?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    isCommercial?: Maybe<Scalars['Boolean']>;
    isOnline?: Maybe<Scalars['Boolean']>;
    isOut?: Maybe<Scalars['Boolean']>;
    lapAccessList?: Maybe<Array<Maybe<LapAccess>>>;
    location?: Maybe<Location>;
    photo?: Maybe<FileInfo>;
    school?: Maybe<School>;
    score?: Maybe<Scalars['Int']>;
};

export type HandbookCenterStudentCollection = {
    __typename?: 'HandbookCenterStudentCollection';
    items?: Maybe<Array<Maybe<HandbookCenterStudent>>>;
    page?: Maybe<PageReply>;
};

export type HandbookOnlineStudent = {
    __typename?: 'HandbookOnlineStudent';
    fullName?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    isOnline?: Maybe<Scalars['Boolean']>;
    lapAccessList?: Maybe<Array<Maybe<LapAccess>>>;
    location?: Maybe<Location>;
    school?: Maybe<School>;
    score?: Maybe<Scalars['Int']>;
};

export type HandbookOnlineStudentCollection = {
    __typename?: 'HandbookOnlineStudentCollection';
    items?: Maybe<Array<Maybe<HandbookOnlineStudent>>>;
    page?: Maybe<PageReply>;
};

export type HandbookSchool = {
    __typename?: 'HandbookSchool';
    email?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    lapAccessList?: Maybe<Array<Maybe<LapAccess>>>;
    location?: Maybe<Location>;
    number?: Maybe<Scalars['Int']>;
    phone?: Maybe<Scalars['String']>;
    region?: Maybe<Region>;
    shortName?: Maybe<Scalars['String']>;
    type?: Maybe<SchoolType>;
};

export type HandbookSchoolCollection = {
    __typename?: 'HandbookSchoolCollection';
    items?: Maybe<Array<Maybe<HandbookSchool>>>;
    page?: Maybe<PageReply>;
};

export type HandbookSchoolCollectionArgs = {
    filter?: Maybe<HandbookSchoolCollectionFilter>;
    orderBy?: Maybe<HandbookSchoolCollectionOrderBy>;
    page?: Maybe<PageArgs>;
};

export type HandbookSchoolCollectionFilter = {
    lapIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
    locationId?: Maybe<Scalars['ID']>;
    platform?: Maybe<PlatformFilter>;
    regionId?: Maybe<Scalars['ID']>;
    shortName?: Maybe<Scalars['String']>;
};

export type HandbookSchoolCollectionOrderBy = {
    email?: Maybe<Sort>;
    location?: Maybe<LocationCollectionOrderBy>;
    number?: Maybe<Sort>;
    phone?: Maybe<Sort>;
    shortName?: Maybe<Sort>;
    type?: Maybe<Sort>;
};

export type HandbookStudentCollectionArgs = {
    filter?: Maybe<HandbookStudentCollectionFilter>;
    orderBy?: Maybe<HandbookStudentCollectionOrderBy>;
    page?: Maybe<PageArgs>;
};

export type HandbookStudentCollectionFilter = {
    fullName?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    platform?: Maybe<PlatformFilter>;
    school?: Maybe<SchoolCollectionFilter>;
};

export type HandbookStudentCollectionOrderBy = {
    fullName?: Maybe<Sort>;
    id?: Maybe<Scalars['ID']>;
    schoolLocationName?: Maybe<Sort>;
    schoolShortName?: Maybe<Sort>;
};

/**
 * Информация об области обрезки.
 * Значения `x`, `y`, `width` и `height` в процентном соотношении от 0 до 1.
 * Может использоваться для вывода аватара
 */
export type ImageCropInfo = {
    __typename?: 'ImageCropInfo';
    /** Высота квадрата области обрезки */
    height?: Maybe<Scalars['Float']>;
    /**
     * Угол против часовой стрелки, на который нужно повернуть изображение.
     * Например, 0, 90, 180 или 270
     */
    rotateAngle?: Maybe<Scalars['Int']>;
    scale?: Maybe<Scalars['Float']>;
    /** Ширина квадрата области обрезки */
    width?: Maybe<Scalars['Float']>;
    /** Координата X области обрезки */
    x?: Maybe<Scalars['Float']>;
    /** Координата Y области обрезки */
    y?: Maybe<Scalars['Float']>;
};

/** Информация об области обрезки, см. ImageCropInfo */
export type ImageCropInfoArg = {
    height?: Maybe<Scalars['Float']>;
    rotateAngle?: Maybe<Scalars['Int']>;
    scale?: Maybe<Scalars['Float']>;
    width?: Maybe<Scalars['Float']>;
    x?: Maybe<Scalars['Float']>;
    y?: Maybe<Scalars['Float']>;
};

export type ImportStudentsResult = {
    __typename?: 'ImportStudentsResult';
    createdCount?: Maybe<Scalars['Int']>;
    errors?: Maybe<Array<Maybe<ImportStudentsResultError>>>;
    totalCount?: Maybe<Scalars['Int']>;
    updatedCount?: Maybe<Scalars['Int']>;
};

export type ImportStudentsResultError = {
    __typename?: 'ImportStudentsResultError';
    line?: Maybe<Scalars['Int']>;
    texts?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type InfoDistributionOfStudents = {
    __typename?: 'InfoDistributionOfStudents';
    /** кол-во распределенных курсантов */
    distributed?: Maybe<Scalars['Int']>;
    /** общее кол-во курсантов */
    total?: Maybe<Scalars['Int']>;
};

export type InternalWikiArticle = {
    __typename?: 'InternalWikiArticle';
    author?: Maybe<Scalars['String']>;
    categoryId?: Maybe<Scalars['ID']>;
    files?: Maybe<Array<Maybe<FileDoc>>>;
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    text?: Maybe<Scalars['String']>;
};

export type InternalWikiArticleCreateArgs = {
    author?: Maybe<Scalars['String']>;
    categoryId?: Maybe<Scalars['ID']>;
    files?: Maybe<Array<Maybe<FileDocArg>>>;
    name?: Maybe<Scalars['String']>;
    text?: Maybe<Scalars['String']>;
};

export type InternalWikiArticleUpdateArgs = {
    author?: Maybe<Scalars['String']>;
    files?: Maybe<Array<Maybe<FileDocArg>>>;
    name?: Maybe<Scalars['String']>;
    text?: Maybe<Scalars['String']>;
};

export type InternalWikiCategory = {
    __typename?: 'InternalWikiCategory';
    articlesCount?: Maybe<Scalars['Int']>;
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
};

export type InternalWikiCategoryArgs = {
    name?: Maybe<Scalars['String']>;
};

export type JivositeInfo = {
    __typename?: 'JivositeInfo';
    contactInfo: JivositeInfoContact;
    customData: Array<JivositeInfoCustom>;
    userToken: Scalars['String'];
};

export type JivositeInfoContact = {
    __typename?: 'JivositeInfoContact';
    email: Scalars['String'];
    name: Scalars['String'];
    phone: Scalars['String'];
};

export type JivositeInfoCustom = {
    __typename?: 'JivositeInfoCustom';
    content: Scalars['String'];
    title: Scalars['String'];
};

export type Lap = {
    __typename?: 'Lap';
    canCertificate?: Maybe<Scalars['Boolean']>;
    companyCount?: Maybe<Scalars['Int']>;
    course?: Maybe<OlCourse>;
    courseId?: Maybe<Scalars['ID']>;
    from?: Maybe<Scalars['Date']>;
    id?: Maybe<Scalars['ID']>;
    integrations?: Maybe<LapIntegrations>;
    isCurrent?: Maybe<Scalars['Boolean']>;
    isFree?: Maybe<Scalars['Boolean']>;
    name?: Maybe<Scalars['String']>;
    platform?: Maybe<Platform>;
    platoonCount?: Maybe<Scalars['Int']>;
    programType?: Maybe<LapProgramType>;
    region?: Maybe<Region>;
    regionId?: Maybe<Scalars['ID']>;
    regionalCenterId?: Maybe<Scalars['ID']>;
    to?: Maybe<Scalars['Date']>;
};

export type LapAccess = {
    __typename?: 'LapAccess';
    blocked?: Maybe<Scalars['Boolean']>;
    canAccess?: Maybe<Scalars['Boolean']>;
    finishAt?: Maybe<Scalars['Date']>;
    id?: Maybe<Scalars['ID']>;
    lap?: Maybe<Lap>;
    lapId?: Maybe<Scalars['ID']>;
    startAt?: Maybe<Scalars['Date']>;
    /** only for school */
    studentCount?: Maybe<Scalars['Int']>;
    target?: Maybe<LapAccessTarget>;
    targetId?: Maybe<Scalars['ID']>;
    userId?: Maybe<Scalars['ID']>;
};

export type LapAccessCreateArgs = {
    /** Можно указать, с какого заезда скопировать данные в новый */
    fromLapId?: Maybe<Scalars['ID']>;
    lapId: Scalars['ID'];
    /** Если создается для школы, то можно указать мета информацию */
    meta?: Maybe<LapSchoolMetaArgs>;
    target: LapAccessTarget;
    targetId: Scalars['ID'];
};

export enum LapAccessTarget {
    school = 'school',
    student = 'student',
}

export type LapAccessUpdateArgs = {
    blocked?: Maybe<Scalars['Boolean']>;
    finishAt?: Maybe<Scalars['Date']>;
    lapId?: Maybe<Scalars['ID']>;
    startAt?: Maybe<Scalars['Date']>;
};

export type LapCollection = {
    __typename?: 'LapCollection';
    items?: Maybe<Array<Maybe<Lap>>>;
    page?: Maybe<PageReply>;
};

export type LapCollectionArgs = {
    filter?: Maybe<LapCollectionFilter>;
    orderBy?: Maybe<LapCollectionOrderBy>;
    page?: Maybe<PageArgs>;
};

export type LapCollectionFilter = {
    courseId?: Maybe<Scalars['ID']>;
    from?: Maybe<Scalars['Date']>;
    id?: Maybe<Scalars['ID']>;
    platform?: Maybe<Platform>;
    programType?: Maybe<LapProgramType>;
    regionId?: Maybe<Scalars['ID']>;
    to?: Maybe<Scalars['Date']>;
};

export type LapCollectionOrderBy = {
    from?: Maybe<Sort>;
    id?: Maybe<Sort>;
    platform?: Maybe<Platform>;
    programType?: Maybe<LapProgramType>;
    to?: Maybe<Sort>;
};

export type LapCreateArgs = {
    canCertificate?: Maybe<Scalars['Boolean']>;
    companyCount?: Maybe<Scalars['Int']>;
    courseId?: Maybe<Scalars['ID']>;
    from?: Maybe<Scalars['Date']>;
    isFree?: Maybe<Scalars['Boolean']>;
    platform?: Maybe<Platform>;
    platoonCount?: Maybe<Scalars['Int']>;
    programType?: Maybe<LapProgramType>;
    regionId?: Maybe<Scalars['ID']>;
    to?: Maybe<Scalars['Date']>;
};

export type LapIntegrations = {
    __typename?: 'LapIntegrations';
    eljur?: Maybe<Scalars['Boolean']>;
};

export enum LapProgramType {
    binn = 'binn',
    heroesWay = 'heroes_way',
    savingIra = 'saving_ira',
    swatCup = 'swat_cup',
    zone_55 = 'zone_55',
}

/** Данные, которые зависят от заезда для школы */
export type LapSchoolMetaArgs = {
    maxStudentsCount?: Maybe<Scalars['Int']>;
    studentsAddMode?: Maybe<StudentsAddMode>;
};

export type LapUpdateArgs = {
    canCertificate?: Maybe<Scalars['Boolean']>;
    from?: Maybe<Scalars['Date']>;
    isFree?: Maybe<Scalars['Boolean']>;
    programType?: Maybe<LapProgramType>;
    regionId?: Maybe<Scalars['ID']>;
    to?: Maybe<Scalars['Date']>;
};

export type LapWithCertificateUrl = {
    __typename?: 'LapWithCertificateUrl';
    certificatesUrl?: Maybe<Scalars['String']>;
    lap?: Maybe<Lap>;
};

export type LiveBroadcast = {
    __typename?: 'LiveBroadcast';
    active: Scalars['Boolean'];
    link: Scalars['String'];
};

export type Location = {
    __typename?: 'Location';
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    regionId?: Maybe<Scalars['ID']>;
};

export type LocationCollectionFilter = {
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    regionId?: Maybe<Scalars['ID']>;
};

export type LocationCollectionOrderBy = {
    id?: Maybe<Sort>;
    name?: Maybe<Sort>;
};

export type LoginReply = {
    __typename?: 'LoginReply';
    accessToken?: Maybe<Scalars['String']>;
    /** URL куда необходимо перенаправить пользователя после успешной авторизации */
    redirectUrl?: Maybe<Scalars['String']>;
    refreshToken?: Maybe<Scalars['String']>;
};

export type MoreEvaluationResponsibleArgs = {
    leaderId?: Maybe<Scalars['ID']>;
    mentorId?: Maybe<Scalars['ID']>;
    platoonX: Scalars['Int'];
};

export type Mutation = {
    __typename?: 'Mutation';
    /** Ответить на вопрос по тесту */
    answerTheQuestion?: Maybe<OlTest>;
    /**
     * Архивировать (разархивировать) сотрудника
     *
     * Roles: `admin`
     */
    archiveTheEmployee: Scalars['Boolean'];
    /** Сменить текущий пароль пользователя */
    changePassword: Scalars['Boolean'];
    /** Удалить оценку курсанта за дисциплину */
    clearEGLessonDisciplineScore?: Maybe<Scalars['Boolean']>;
    /** Удалить оценку курсанта за занятие */
    clearEGLessonScore?: Maybe<Scalars['Boolean']>;
    /** Скопировать одно занятие в расписании */
    copyEGLesson: Scalars['Boolean'];
    /** Скопировать занятия расписаний */
    copyEGLessons: Scalars['Boolean'];
    /** Скопировать ответственных оценивания из выбранного заезда `fromLapId` в текущий */
    copyEvaluationResponsible: Scalars['Boolean'];
    /** Скопировать курс. Скопированному курсу к названию будет добавлено `(copy)` */
    copyOLCourse?: Maybe<Scalars['Boolean']>;
    /**
     * Добавить занятия.
     *
     * Roles: `admin`
     */
    createEGLesson?: Maybe<EgLesson>;
    /**
     * Добавить место проведения занятия.
     *
     * Roles: `admin`
     */
    createEGLessonPlace?: Maybe<EgLessonPlace>;
    /**
     * Добавить время занятия.
     *
     * Roles: `admin`
     */
    createEGLessonTime?: Maybe<EgLessonTime>;
    /** Создать модуль электронного журнала */
    createEGModule?: Maybe<EgModule>;
    /**
     * Создать предмет. Цвет можно указывать в HEX значении.
     *
     * Roles: `admin`
     */
    createEGSubject?: Maybe<EgSubject>;
    /**
     * Добавить сотрудника.
     *
     * Roles: `admin`
     */
    createEmployee?: Maybe<Employee>;
    /**
     * Создать критерий оценивания
     *
     * Roles: `admin`
     */
    createEvaluationCriteria?: Maybe<EvaluationCriteria>;
    /** Создать событие */
    createEvent?: Maybe<Event>;
    /** Создать новую статью */
    createInternalWikiArticle?: Maybe<InternalWikiArticle>;
    /** Создать новую категорию */
    createInternalWikiCategory?: Maybe<InternalWikiCategory>;
    /**
     * Добавить заезд
     *
     * Roles: `admin`
     */
    createLap?: Maybe<Lap>;
    /** Добавить новый доступ к заезду */
    createLapAccess?: Maybe<LapAccess>;
    /**
     * Добавить занятия массово.
     *
     * Roles: `admin`
     */
    createMoreEGLessons?: Maybe<Array<Maybe<EgLesson>>>;
    /**
     * Добавить курс
     *
     * Roles: `admin`
     */
    createOLCourse?: Maybe<OlCourse>;
    /**
     * Добавить дисциплину.
     *
     * Roles: `admin`
     */
    createOLSubject?: Maybe<OlSubject>;
    /**
     * Добавить вопрос к тесту
     *
     * Roles: `admin`
     */
    createOLTestQuestion?: Maybe<OlTestQuestion>;
    /**
     * Создать региональный центр
     *
     * Roles: `super_admin`
     */
    createRegionalCenter?: Maybe<RegionalCenter>;
    /**
     * Добавить школу.
     *
     * Roles: `admin`
     */
    createSchool?: Maybe<School>;
    /**
     * Добавить курсанта.
     *
     * Roles: `admin` | `school`
     */
    createStudent?: Maybe<Student>;
    /**
     * Распределить курсантов по взводам в заезде
     *
     * Roles: `admin`
     */
    distributeStudents?: Maybe<InfoDistributionOfStudents>;
    /** Обменять код ЭЛЖУР на токен доступа Авангард */
    eljurExchangeCode?: Maybe<LoginReply>;
    /** Импорт курсантов */
    importStudents?: Maybe<ImportStudentsResult>;
    /**
     * Авторизация пользователя по логину и паролю.
     * Возвращает JWT
     */
    login?: Maybe<LoginReply>;
    /** Переместить занятие на новое место в расписании */
    moveEGLesson?: Maybe<Scalars['Boolean']>;
    /**
     * Обновляет access_token используя refresh_token.
     * Возвращает новую пару токенов
     */
    refreshToken?: Maybe<LoginReply>;
    /** Регистрация нового студента через авторизационный сервис */
    registerStudent?: Maybe<LoginReply>;
    /** Зарегистрировать нового студента анонимно */
    registerStudentAnonymously?: Maybe<LoginReply>;
    /**
     * Удалить занятия.
     *
     * Roles: `admin`
     */
    removeEGLesson?: Maybe<Scalars['Boolean']>;
    /**
     * Удалить место проведения занятия.
     *
     * Roles: `admin`
     */
    removeEGLessonPlace?: Maybe<Scalars['Boolean']>;
    /**
     * Удалить время занятия.
     *
     * Roles: `admin`
     */
    removeEGLessonTime?: Maybe<Scalars['Boolean']>;
    /** Удалить модуль электронного журнала */
    removeEGModule: Scalars['Boolean'];
    /**
     * Удалить предмет
     *
     * Roles: `admin`
     */
    removeEGSubject?: Maybe<Scalars['Boolean']>;
    /**
     * Удалить сотрудника.
     *
     * Roles: `admin`
     */
    removeEmployee: Scalars['Boolean'];
    /**
     * Удалить критерий оценивания
     *
     * Roles: `admin`
     */
    removeEvaluationCriteria?: Maybe<Scalars['Boolean']>;
    /** Удалить событие */
    removeEvent?: Maybe<Scalars['Boolean']>;
    /** Удалить статью */
    removeInternalWikiArticle: Scalars['Boolean'];
    /** Удалить категорию */
    removeInternalWikiCategory: Scalars['Boolean'];
    /**
     * Удалить заезд
     *
     * Roles: `admin`
     */
    removeLap?: Maybe<Scalars['Boolean']>;
    /** Удалить доступ к заезду */
    removeLapAccess?: Maybe<Scalars['Boolean']>;
    /** Массово удалить занятия из расписания */
    removeMoreEGLessons?: Maybe<Scalars['Boolean']>;
    /**
     * Удалить курс.
     *
     * Roles: `admin`
     */
    removeOLCourse?: Maybe<Scalars['Boolean']>;
    /** Удалить лекцию */
    removeOLLecture: Scalars['Boolean'];
    /**
     * Удалить дисциплину.
     *
     * Roles: `admin`
     */
    removeOLSubject?: Maybe<Scalars['Boolean']>;
    /**
     * Удалить вопрос теста
     *
     * Roles: `admin`
     */
    removeOLTestQuestion?: Maybe<Scalars['Boolean']>;
    /**
     * Удалить региональный центр
     *
     * Roles: `super_admin`
     */
    removeRegionalCenter?: Maybe<Scalars['Boolean']>;
    /**
     * Удалить школу.
     *
     * Roles: `admin`
     */
    removeSchool: Scalars['Boolean'];
    /**
     * Удалить курсанта.
     *
     * Roles: `admin` | `school`
     */
    removeStudent: Scalars['Boolean'];
    /** Восстановить пароль */
    resetPassword?: Maybe<LoginReply>;
    /** Отозвать текущий токен (выход из системы). */
    revokeToken: Scalars['Boolean'];
    /**
     * Отправить доступы сотруднику.
     * `id` is employee's id
     *
     * Roles: `admin`
     */
    sendEmployeeAccess: Scalars['Boolean'];
    /**
     * Отправить доступы сотруднику в региональном центре
     * `id` is employee's id
     *
     * Roles: `super_admin`
     */
    sendEmployeeAccessInRegionalCenter: Scalars['Boolean'];
    /** Отправка письма с ссылкой на восстановление пароля */
    sendForgotPassword?: Maybe<ForgotPasswordReply>;
    /**
     * Отправить доступы школе.
     * `id` is school's id
     *
     * Roles: `admin`
     */
    sendSchoolAccess: Scalars['Boolean'];
    /**
     * Отправить доступы курсанту.
     * `id` is student's id
     *
     * Roles: `admin` | `school`
     */
    sendStudentAccess: Scalars['Boolean'];
    /** Установить текущий заезд для пользователя */
    setCurrentLap?: Maybe<Scalars['Boolean']>;
    /**
     * Поменять региональный центр
     *
     * Roles: `super_admin`
     */
    setCurrentRegionalCenter: Scalars['Boolean'];
    /** Поставить оценку курсанту за дисциплину */
    setEGLessonDisciplineScore?: Maybe<EgLessonDisciplineScore>;
    /**
     * Поставить оценку и (или) присутствие курсанту за занятие.
     * Должны быть заполнены `score` или `attendance`, либо и то и другое сразу.
     * Если передать в `score` значение `0`, то оценка и присутствие удалятся.
     */
    setEGLessonScore?: Maybe<EgLessonScore>;
    /** Проставить оценку/посещение сразу всем курсантам (кроме выбывшим) за занятие */
    setEGLessonScoreAll?: Maybe<Scalars['Boolean']>;
    /**
     * Установить отвественных оценивания
     *
     * Roles: `admin`
     */
    setEvaluationResponsible?: Maybe<EvaluationResponsible>;
    /**
     * Установить оценку за оценивание
     *
     * Roles: `admin | mentor | leader`
     */
    setEvaluationScore?: Maybe<EvaluationScore>;
    /** Установить оценки курсанту для раздела оценивание */
    setEvaluationStudentScores: Scalars['Boolean'];
    /**
     * Установить новые данные для прямого эфира.
     * Roles: `admin`
     */
    setLiveBroadcast?: Maybe<LiveBroadcast>;
    /**
     * Метод для массовой простановки оценки и (или) присутствия курсантов на занятии.
     * Должны быть заполнены `score` или `attendance`, либо и то и другое сразу.
     * Если передать в `score` значение `0`, то оценка и присутствие удалятся.
     */
    setMoreEGLessonScores: Scalars['Boolean'];
    /** Массовое редактирование ответственных оценивания */
    setMoreEvaluationResponsibles: Scalars['Boolean'];
    /** Установить список дисциплин для сотрудника, которые он должен пройти */
    setOLSubjectsToEmployee: Scalars['Boolean'];
    /** Регистрация нового пользователя */
    signup?: Maybe<LoginReply>;
    /**
     * Начать тест.
     * Для теста по дисциплине нужно указывать `subjectId` и `courseId`.
     * Для теста по курсу нужно указывать только `courseId`.
     */
    startOLTest?: Maybe<OlTest>;
    /**
     * Закончить тест.
     * Для теста по дисциплине нужно указывать `subjectId` и `courseId`.
     * Для теста по курсу нужно указывать только `courseId`.
     */
    stopOLTest?: Maybe<OlTest>;
    /**
     * Обновить занятия.
     *
     * Roles: `admin`
     */
    updateEGLesson?: Maybe<EgLesson>;
    /**
     * Обновить место проведения занятия.
     *
     * Roles: `admin`
     */
    updateEGLessonPlace?: Maybe<EgLessonPlace>;
    /**
     * Обновить время занятия.
     *
     * Roles: `admin`
     */
    updateEGLessonTime?: Maybe<EgLessonTime>;
    /** Обновить модуль электронного журнала */
    updateEGModule?: Maybe<EgModule>;
    /**
     * Обновить предмет. Цвет можно указывать в HEX значении.
     *
     * Roles: `admin`
     */
    updateEGSubject?: Maybe<EgSubject>;
    /**
     * Обновить сотрудника.
     *
     * Roles: `admin`
     */
    updateEmployee?: Maybe<Employee>;
    /**
     * Обновить критерий оценивания
     *
     * Roles: `admin`
     */
    updateEvaluationCriteria?: Maybe<EvaluationCriteria>;
    /** Обновить событие */
    updateEvent?: Maybe<Event>;
    /** Обновить область обрезки файла изображения */
    updateFileCrop?: Maybe<UpdateFileCropReply>;
    /** Обновить статью */
    updateInternalWikiArticle?: Maybe<InternalWikiArticle>;
    /** Обновить категорию */
    updateInternalWikiCategory?: Maybe<InternalWikiCategory>;
    /**
     * Обновить заезд
     *
     * Roles: `admin`
     */
    updateLap?: Maybe<Lap>;
    /** Обновить доступ к заезду */
    updateLapAccess?: Maybe<LapAccess>;
    /**
     * Обновить курс
     *
     * Roles: `admin`
     */
    updateOLCourse?: Maybe<OlCourse>;
    /** Обновить лекцию */
    updateOLLecture?: Maybe<OlLecture>;
    /**
     * Обновить дисциплину.
     *
     * Roles: `admin`
     */
    updateOLSubject?: Maybe<OlSubject>;
    /**
     * Обновить вопрос к тесту
     *
     * Roles: `admin`
     */
    updateOLTestQuestion?: Maybe<OlTestQuestion>;
    /**
     * Редактировать региональный центр
     *
     * Roles: `super_admin`
     */
    updateRegionalCenter?: Maybe<RegionalCenter>;
    /**
     * Обновить школу.
     *
     * Roles: `admin`
     */
    updateSchool?: Maybe<School>;
    /**
     * Обновить курсанта.
     *
     * Roles: `admin` | `school`
     */
    updateStudent?: Maybe<Student>;
    /** Получить возможности ролей в виде таблицы */
    updateUserGrantsTable: Scalars['Boolean'];
};

export type MutationAnswerTheQuestionArgs = {
    answer: OlTestUserAnswerArgs;
    courseId: Scalars['ID'];
    subjectId?: Maybe<Scalars['ID']>;
};

export type MutationArchiveTheEmployeeArgs = {
    archive: Scalars['Boolean'];
    id: Scalars['ID'];
};

export type MutationChangePasswordArgs = {
    new: Scalars['String'];
    old: Scalars['String'];
};

export type MutationClearEgLessonDisciplineScoreArgs = {
    lessonId: Scalars['ID'];
};

export type MutationClearEgLessonScoreArgs = {
    lessonId: Scalars['ID'];
    studentId: Scalars['ID'];
};

export type MutationCopyEgLessonArgs = {
    id: Scalars['ID'];
    params: Array<EgLessonCopyParam>;
};

export type MutationCopyEgLessonsArgs = {
    params: EgLessonsCopyParams;
};

export type MutationCopyEvaluationResponsibleArgs = {
    fromLapId: Scalars['ID'];
};

export type MutationCopyOlCourseArgs = {
    id: Scalars['ID'];
};

export type MutationCreateEgLessonArgs = {
    item: EgLessonArgs;
};

export type MutationCreateEgLessonPlaceArgs = {
    item: EgLessonPlaceArgs;
};

export type MutationCreateEgLessonTimeArgs = {
    item: EgLessonTimeArgs;
};

export type MutationCreateEgModuleArgs = {
    item: EgModuleCreateArgs;
};

export type MutationCreateEgSubjectArgs = {
    item: EgSubjectCreateArgs;
};

export type MutationCreateEmployeeArgs = {
    item: EmployeeArgs;
};

export type MutationCreateEvaluationCriteriaArgs = {
    item: EvaluationCriteriaArgs;
};

export type MutationCreateEventArgs = {
    item: EventCreateArgs;
};

export type MutationCreateInternalWikiArticleArgs = {
    item: InternalWikiArticleCreateArgs;
};

export type MutationCreateInternalWikiCategoryArgs = {
    item: InternalWikiCategoryArgs;
};

export type MutationCreateLapArgs = {
    item: LapCreateArgs;
};

export type MutationCreateLapAccessArgs = {
    item: LapAccessCreateArgs;
};

export type MutationCreateMoreEgLessonsArgs = {
    items: Array<EgLessonArgs>;
};

export type MutationCreateOlCourseArgs = {
    item: OlCourseArgs;
};

export type MutationCreateOlSubjectArgs = {
    item: OlSubjectCreateArgs;
};

export type MutationCreateOlTestQuestionArgs = {
    item: OlTestQuestionArgs;
};

export type MutationCreateRegionalCenterArgs = {
    args: RegionalCenterCreateArgs;
};

export type MutationCreateSchoolArgs = {
    item: SchoolArgs;
};

export type MutationCreateStudentArgs = {
    item: StudentArgs;
};

export type MutationDistributeStudentsArgs = {
    lapId?: Maybe<Scalars['ID']>;
};

export type MutationEljurExchangeCodeArgs = {
    code: Scalars['String'];
    regionalCenterId: Scalars['ID'];
    state: Scalars['String'];
};

export type MutationImportStudentsArgs = {
    fileId: Scalars['ID'];
};

export type MutationLoginArgs = {
    login: Scalars['String'];
    password: Scalars['String'];
};

export type MutationMoveEgLessonArgs = {
    id: Scalars['ID'];
    params: EgLessonMoveParams;
};

export type MutationRefreshTokenArgs = {
    refreshToken: Scalars['String'];
};

export type MutationRegisterStudentArgs = {
    args: RegisterStudentArgs;
};

export type MutationRegisterStudentAnonymouslyArgs = {
    args: RegisterStudentAnonymouslyArgs;
};

export type MutationRemoveEgLessonArgs = {
    id: Scalars['ID'];
};

export type MutationRemoveEgLessonPlaceArgs = {
    id: Scalars['ID'];
};

export type MutationRemoveEgLessonTimeArgs = {
    id: Scalars['ID'];
};

export type MutationRemoveEgModuleArgs = {
    id: Scalars['ID'];
};

export type MutationRemoveEgSubjectArgs = {
    id: Scalars['ID'];
};

export type MutationRemoveEmployeeArgs = {
    id: Scalars['ID'];
};

export type MutationRemoveEvaluationCriteriaArgs = {
    id: Scalars['ID'];
};

export type MutationRemoveEventArgs = {
    id: Scalars['ID'];
};

export type MutationRemoveInternalWikiArticleArgs = {
    id: Scalars['ID'];
};

export type MutationRemoveInternalWikiCategoryArgs = {
    id: Scalars['ID'];
};

export type MutationRemoveLapArgs = {
    id: Scalars['ID'];
};

export type MutationRemoveLapAccessArgs = {
    id: Scalars['ID'];
};

export type MutationRemoveMoreEgLessonsArgs = {
    ids: Array<Scalars['ID']>;
};

export type MutationRemoveOlCourseArgs = {
    id: Scalars['ID'];
};

export type MutationRemoveOlLectureArgs = {
    id: Scalars['ID'];
};

export type MutationRemoveOlSubjectArgs = {
    id: Scalars['ID'];
};

export type MutationRemoveOlTestQuestionArgs = {
    id: Scalars['ID'];
};

export type MutationRemoveRegionalCenterArgs = {
    id: Scalars['ID'];
};

export type MutationRemoveSchoolArgs = {
    id: Scalars['ID'];
};

export type MutationRemoveStudentArgs = {
    id: Scalars['ID'];
};

export type MutationResetPasswordArgs = {
    newPassword: Scalars['String'];
    token: Scalars['String'];
};

export type MutationRevokeTokenArgs = {
    refreshToken: Scalars['String'];
};

export type MutationSendEmployeeAccessArgs = {
    id: Scalars['ID'];
};

export type MutationSendEmployeeAccessInRegionalCenterArgs = {
    id: Scalars['ID'];
    regionalCenterId: Scalars['ID'];
};

export type MutationSendForgotPasswordArgs = {
    login: Scalars['String'];
};

export type MutationSendSchoolAccessArgs = {
    id: Scalars['ID'];
};

export type MutationSendStudentAccessArgs = {
    id: Scalars['ID'];
};

export type MutationSetCurrentLapArgs = {
    id: Scalars['ID'];
    platform?: Maybe<Platform>;
};

export type MutationSetCurrentRegionalCenterArgs = {
    id: Scalars['ID'];
};

export type MutationSetEgLessonDisciplineScoreArgs = {
    lessonId: Scalars['ID'];
    score: Scalars['Int'];
};

export type MutationSetEgLessonScoreArgs = {
    item: EgLessonScoreArgs;
    lessonId: Scalars['ID'];
};

export type MutationSetEgLessonScoreAllArgs = {
    attendance?: Maybe<EgAttendance>;
    lessonId: Scalars['ID'];
    score?: Maybe<Scalars['Int']>;
};

export type MutationSetEvaluationResponsibleArgs = {
    item: EvaluationResponsibleArgs;
    platoonX: Scalars['Int'];
};

export type MutationSetEvaluationScoreArgs = {
    criteriaId: Scalars['ID'];
    evaluationId: Scalars['ID'];
    score: Scalars['Int'];
};

export type MutationSetEvaluationStudentScoresArgs = {
    args: EvaluationStudentScoresArgs;
};

export type MutationSetLiveBroadcastArgs = {
    active?: Maybe<Scalars['Boolean']>;
    link?: Maybe<Scalars['String']>;
};

export type MutationSetMoreEgLessonScoresArgs = {
    items: Array<EgLessonScoreArgs>;
    lessonId: Scalars['ID'];
};

export type MutationSetMoreEvaluationResponsiblesArgs = {
    items: Array<Maybe<MoreEvaluationResponsibleArgs>>;
};

export type MutationSetOlSubjectsToEmployeeArgs = {
    employeeId: Scalars['ID'];
    olSubjectIds: Array<Scalars['ID']>;
};

export type MutationSignupArgs = {
    email: Scalars['String'];
    fullName: Scalars['String'];
    password: Scalars['String'];
};

export type MutationStartOlTestArgs = {
    courseId: Scalars['ID'];
    subjectId?: Maybe<Scalars['ID']>;
};

export type MutationStopOlTestArgs = {
    courseId: Scalars['ID'];
    subjectId?: Maybe<Scalars['ID']>;
};

export type MutationUpdateEgLessonArgs = {
    id: Scalars['ID'];
    item: EgLessonArgs;
};

export type MutationUpdateEgLessonPlaceArgs = {
    id: Scalars['ID'];
    item: EgLessonPlaceArgs;
};

export type MutationUpdateEgLessonTimeArgs = {
    id: Scalars['ID'];
    item: EgLessonTimeArgs;
};

export type MutationUpdateEgModuleArgs = {
    id: Scalars['ID'];
    item: EgModuleUpdateArgs;
};

export type MutationUpdateEgSubjectArgs = {
    id: Scalars['ID'];
    item: EgSubjectUpdateArgs;
};

export type MutationUpdateEmployeeArgs = {
    id: Scalars['ID'];
    item: EmployeeArgs;
};

export type MutationUpdateEvaluationCriteriaArgs = {
    id: Scalars['ID'];
    item: EvaluationCriteriaArgs;
};

export type MutationUpdateEventArgs = {
    id: Scalars['ID'];
    item: EventUpdateArgs;
};

export type MutationUpdateFileCropArgs = {
    cropInfo?: Maybe<ImageCropInfoArg>;
    id: Scalars['ID'];
};

export type MutationUpdateInternalWikiArticleArgs = {
    id: Scalars['ID'];
    item: InternalWikiArticleUpdateArgs;
};

export type MutationUpdateInternalWikiCategoryArgs = {
    id: Scalars['ID'];
    item: InternalWikiCategoryArgs;
};

export type MutationUpdateLapArgs = {
    id: Scalars['ID'];
    item: LapUpdateArgs;
};

export type MutationUpdateLapAccessArgs = {
    id: Scalars['ID'];
    item: LapAccessUpdateArgs;
};

export type MutationUpdateOlCourseArgs = {
    id: Scalars['ID'];
    item: OlCourseArgs;
};

export type MutationUpdateOlLectureArgs = {
    id: Scalars['ID'];
    item: OlLectureArgs;
};

export type MutationUpdateOlSubjectArgs = {
    id: Scalars['ID'];
    item: OlSubjectUpdateArgs;
};

export type MutationUpdateOlTestQuestionArgs = {
    id: Scalars['ID'];
    item: OlTestQuestionArgs;
};

export type MutationUpdateRegionalCenterArgs = {
    args: RegionalCenterUpdateArgs;
    id: Scalars['ID'];
};

export type MutationUpdateSchoolArgs = {
    id: Scalars['ID'];
    item: SchoolArgs;
};

export type MutationUpdateStudentArgs = {
    id: Scalars['ID'];
    item: StudentArgs;
};

export type MutationUpdateUserGrantsTableArgs = {
    items: Array<UserGrantsTableItemArgs>;
};

export type OdbCounts = {
    __typename?: 'ODBCounts';
    finished?: Maybe<Scalars['Int']>;
    notFinished?: Maybe<Scalars['Int']>;
    onlineStudents?: Maybe<Scalars['Int']>;
    schools?: Maybe<Scalars['Int']>;
    students?: Maybe<Scalars['Int']>;
};

export type OdbCountsArgs = {
    filter?: Maybe<OdbFilter>;
};

export type OdbCourseTestPassing = {
    __typename?: 'ODBCourseTestPassing';
    data?: Maybe<Array<Maybe<OdbCourseTestPassingItem>>>;
};

export type OdbCourseTestPassingArgs = {
    filter?: Maybe<OdbFilter>;
};

export type OdbCourseTestPassingItem = {
    __typename?: 'ODBCourseTestPassingItem';
    counts?: Maybe<Scalars['Int']>;
    date?: Maybe<Scalars['String']>;
    label?: Maybe<Scalars['String']>;
};

export type OdbFilter = {
    locationId?: Maybe<Scalars['ID']>;
    regionId?: Maybe<Scalars['ID']>;
    schoolId?: Maybe<Scalars['ID']>;
};

export type OdbLocations = {
    __typename?: 'ODBLocations';
    colHeaders?: Maybe<Array<Maybe<OdbLocationsColHeader>>>;
    page?: Maybe<PageReply>;
    rows?: Maybe<Array<Maybe<OdbLocationsRow>>>;
};

export type OdbLocationsArgs = {
    filter?: Maybe<OdbFilter>;
    orderBy?: Maybe<OdbLocationsOrderBy>;
    page?: Maybe<PageArgs>;
};

export type OdbLocationsColHeader = {
    __typename?: 'ODBLocationsColHeader';
    id?: Maybe<Scalars['ID']>;
    label?: Maybe<Scalars['String']>;
};

export type OdbLocationsOrderBy = {
    col?: Maybe<Sort>;
    colId?: Maybe<Scalars['ID']>;
    name?: Maybe<Sort>;
};

export type OdbLocationsRow = {
    __typename?: 'ODBLocationsRow';
    cols?: Maybe<Array<Maybe<OdbLocationsRowCol>>>;
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
};

export type OdbLocationsRowCol = {
    __typename?: 'ODBLocationsRowCol';
    id?: Maybe<Scalars['ID']>;
    score?: Maybe<Scalars['Float']>;
};

export type OdbRegions = {
    __typename?: 'ODBRegions';
    colHeaders?: Maybe<Array<Maybe<OdbRegionsColHeader>>>;
    rows?: Maybe<Array<Maybe<OdbRegionsRow>>>;
};

export type OdbRegionsArgs = {
    filter?: Maybe<OdbFilter>;
    orderBy?: Maybe<OdbRegionsOrderBy>;
};

export type OdbRegionsColHeader = {
    __typename?: 'ODBRegionsColHeader';
    id?: Maybe<Scalars['ID']>;
    label?: Maybe<Scalars['String']>;
};

export type OdbRegionsOrderBy = {
    col?: Maybe<Sort>;
    colId?: Maybe<Scalars['ID']>;
    name?: Maybe<Sort>;
};

export type OdbRegionsRow = {
    __typename?: 'ODBRegionsRow';
    cols?: Maybe<Array<Maybe<OdbRegionsRowCol>>>;
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
};

export type OdbRegionsRowCol = {
    __typename?: 'ODBRegionsRowCol';
    id?: Maybe<Scalars['ID']>;
    score?: Maybe<Scalars['Float']>;
};

export type OdbSchools = {
    __typename?: 'ODBSchools';
    items?: Maybe<Array<Maybe<OdbSchoolsItem>>>;
    page?: Maybe<PageReply>;
};

export type OdbSchoolsArgs = {
    filter?: Maybe<OdbFilter>;
    orderBy?: Maybe<OdbSchoolsOrderBy>;
    page?: Maybe<PageArgs>;
};

export type OdbSchoolsItem = {
    __typename?: 'ODBSchoolsItem';
    locationName?: Maybe<Scalars['String']>;
    notPassed?: Maybe<Scalars['Int']>;
    passed?: Maybe<Scalars['Int']>;
    schoolId?: Maybe<Scalars['ID']>;
    schoolName?: Maybe<Scalars['String']>;
    studentsCount?: Maybe<Scalars['Int']>;
};

export type OdbSchoolsOrderBy = {
    locationName?: Maybe<Sort>;
    notPassed?: Maybe<Sort>;
    passed?: Maybe<Sort>;
    schoolName?: Maybe<Sort>;
    studentsCount?: Maybe<Sort>;
};

export type OdbScores = {
    __typename?: 'ODBScores';
    average?: Maybe<Scalars['Float']>;
    data?: Maybe<Array<Maybe<OdbScoresItem>>>;
};

export type OdbScoresArgs = {
    filter?: Maybe<OdbFilter>;
};

export type OdbScoresItem = {
    __typename?: 'ODBScoresItem';
    counts?: Maybe<Scalars['Int']>;
    label?: Maybe<Scalars['String']>;
    score?: Maybe<Scalars['String']>;
};

export type OdbSubjects = {
    __typename?: 'ODBSubjects';
    items?: Maybe<Array<Maybe<OdbSubjectsItem>>>;
};

export type OdbSubjectsArgs = {
    filter?: Maybe<OdbFilter>;
    orderBy?: Maybe<OdbSubjectsOrderBy>;
};

export type OdbSubjectsItem = {
    __typename?: 'ODBSubjectsItem';
    average?: Maybe<Scalars['Float']>;
    count3?: Maybe<Scalars['Int']>;
    count4?: Maybe<Scalars['Int']>;
    count5?: Maybe<Scalars['Int']>;
    subjectId?: Maybe<Scalars['ID']>;
    subjectName?: Maybe<Scalars['String']>;
};

export type OdbSubjectsOrderBy = {
    average?: Maybe<Sort>;
    count3?: Maybe<Sort>;
    count4?: Maybe<Sort>;
    count5?: Maybe<Sort>;
    subjectName?: Maybe<Sort>;
};

export type OlCourse = {
    __typename?: 'OLCourse';
    certificateType?: Maybe<OlCourseCertificateType>;
    certificateUrl?: Maybe<Scalars['String']>;
    createdAt?: Maybe<Scalars['Time']>;
    id?: Maybe<Scalars['ID']>;
    isAvailable?: Maybe<Scalars['Boolean']>;
    name?: Maybe<Scalars['String']>;
    passTestForAnyResult?: Maybe<Scalars['Boolean']>;
    shortName?: Maybe<Scalars['String']>;
    sort?: Maybe<Scalars['Int']>;
    status?: Maybe<OlStatus>;
    subjectsCount?: Maybe<Scalars['Int']>;
    test?: Maybe<OlCourseTest>;
    testAvailable?: Maybe<Scalars['Boolean']>;
    testStatus?: Maybe<OlCourseTestStatus>;
    testsCount?: Maybe<Scalars['Int']>;
    text?: Maybe<Scalars['String']>;
    textType?: Maybe<OlTextType>;
    updatedAt?: Maybe<Scalars['Time']>;
    userId?: Maybe<Scalars['ID']>;
};

export type OlCourseArgs = {
    certificateType?: Maybe<OlCourseCertificateType>;
    name?: Maybe<Scalars['String']>;
    passTestForAnyResult?: Maybe<Scalars['Boolean']>;
    shortName?: Maybe<Scalars['String']>;
    sort?: Maybe<Scalars['Int']>;
    status?: Maybe<OlStatus>;
    test?: Maybe<OlCourseTestArgs>;
    text?: Maybe<Scalars['String']>;
    textType?: Maybe<OlTextType>;
};

/** Тип шаблона для скачивания сертификата по прохождению курса */
export enum OlCourseCertificateType {
    class_10 = 'class_10',
    class_8 = 'class_8',
    default = 'default',
    dictation_2024 = 'dictation_2024',
}

export type OlCourseCollection = {
    __typename?: 'OLCourseCollection';
    items?: Maybe<Array<Maybe<OlCourse>>>;
    page?: Maybe<PageReply>;
};

export type OlCourseCollectionArgs = {
    filter?: Maybe<OlCourseCollectionFilter>;
    orderBy?: Maybe<OlCourseCollectionOrderBy>;
    page?: Maybe<PageArgs>;
};

export type OlCourseCollectionFilter = {
    name?: Maybe<Scalars['String']>;
};

export type OlCourseCollectionOrderBy = {
    createdAt?: Maybe<Sort>;
};

/** Тип баллов для вывода пользователю */
export enum OlCourseResultScoreType {
    /** Выводить в 100 баллной шкале */
    in_100 = 'in_100',
    /** Выводить в 5 баллной шкале */
    in_5 = 'in_5',
    /** Выводить баллы как есть */
    origin = 'origin',
}

export type OlCourseTest = {
    __typename?: 'OLCourseTest';
    duration: Scalars['Int'];
    questionsCount: Scalars['Int'];
    showResultScore?: Maybe<OlCourseResultScoreType>;
};

export type OlCourseTestArgs = {
    duration: Scalars['Int'];
    questionsCount: Scalars['Int'];
    showResultScore?: Maybe<OlCourseResultScoreType>;
};

export enum OlCourseTestStatus {
    /** Тест пройден, но не успешно */
    fail = 'fail',
    /** Тест не начинался */
    none = 'none',
    /** Тест пройден успешно */
    success = 'success',
}

export type OlLecture = {
    __typename?: 'OLLecture';
    author?: Maybe<Scalars['String']>;
    createdAt?: Maybe<Scalars['Time']>;
    docs?: Maybe<Array<Maybe<FileDoc>>>;
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    sort?: Maybe<Scalars['Int']>;
    status?: Maybe<OlStatus>;
    subjectName?: Maybe<Scalars['String']>;
    text?: Maybe<Scalars['String']>;
    textType?: Maybe<OlTextType>;
    type?: Maybe<OlLectureType>;
    updatedAt?: Maybe<Scalars['Time']>;
    userId?: Maybe<Scalars['ID']>;
    videos?: Maybe<Array<Maybe<FileDoc>>>;
};

export type OlLectureArgs = {
    author?: Maybe<Scalars['String']>;
    docs?: Maybe<Array<Maybe<FileDocArg>>>;
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    sort?: Maybe<Scalars['Int']>;
    status?: Maybe<OlStatus>;
    text?: Maybe<Scalars['String']>;
    textType?: Maybe<OlTextType>;
    type?: Maybe<OlLectureType>;
    videos?: Maybe<Array<Maybe<FileDocArg>>>;
};

export enum OlLectureType {
    file = 'file',
    text = 'text',
}

export enum OlStatus {
    active = 'active',
    archive = 'archive',
    draft = 'draft',
}

export type OlSubject = {
    __typename?: 'OLSubject';
    courseId?: Maybe<Scalars['ID']>;
    createdAt?: Maybe<Scalars['Time']>;
    docs?: Maybe<Array<Maybe<FileDoc>>>;
    id?: Maybe<Scalars['ID']>;
    lectures?: Maybe<Array<Maybe<OlLecture>>>;
    name?: Maybe<Scalars['String']>;
    sort?: Maybe<Scalars['Int']>;
    status?: Maybe<OlStatus>;
    test?: Maybe<OlSubjectTest>;
    testEnabled?: Maybe<Scalars['Boolean']>;
    testQuestions?: Maybe<Array<Maybe<OlTestQuestion>>>;
    text?: Maybe<Scalars['String']>;
    textType?: Maybe<OlTextType>;
    updatedAt?: Maybe<Scalars['Time']>;
    userId?: Maybe<Scalars['ID']>;
    userTest?: Maybe<OlTest>;
    videos?: Maybe<Array<Maybe<VideoFileDoc>>>;
};

export type OlSubjectCollection = {
    __typename?: 'OLSubjectCollection';
    items?: Maybe<Array<Maybe<OlSubject>>>;
    page?: Maybe<PageReply>;
};

export type OlSubjectCollectionArgs = {
    filter?: Maybe<OlSubjectCollectionFilter>;
    orderBy?: Maybe<OlSubjectCollectionOrderBy>;
    page?: Maybe<PageArgs>;
};

export type OlSubjectCollectionFilter = {
    courseId?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    status?: Maybe<OlStatus>;
    text?: Maybe<Scalars['String']>;
    textType?: Maybe<OlTextType>;
};

export type OlSubjectCollectionOrderBy = {
    createdAt?: Maybe<Sort>;
    id?: Maybe<Sort>;
    name?: Maybe<Sort>;
    sort?: Maybe<Sort>;
    status?: Maybe<Sort>;
    text?: Maybe<Sort>;
};

export type OlSubjectCreateArgs = {
    /**
     * если запрос делается с платформы center,
     * то поле будет выставлено в значение 0
     */
    courseId?: Maybe<Scalars['ID']>;
    name: Scalars['String'];
    sort?: Maybe<Scalars['Int']>;
    status?: Maybe<OlStatus>;
    text?: Maybe<Scalars['String']>;
    textType?: Maybe<OlTextType>;
};

export type OlSubjectTest = {
    __typename?: 'OLSubjectTest';
    duration: Scalars['Int'];
    questionsCount: Scalars['Int'];
};

export type OlSubjectTestArgs = {
    duration: Scalars['Int'];
    questionsCount: Scalars['Int'];
};

export type OlSubjectUpdateArgs = {
    courseId?: Maybe<Scalars['ID']>;
    docs?: Maybe<Array<Maybe<FileDocArg>>>;
    lectures?: Maybe<Array<OlLectureArgs>>;
    name?: Maybe<Scalars['String']>;
    sort?: Maybe<Scalars['Int']>;
    status?: Maybe<OlStatus>;
    test?: Maybe<OlSubjectTestArgs>;
    testEnabled?: Maybe<Scalars['Boolean']>;
    text?: Maybe<Scalars['String']>;
    textType?: Maybe<OlTextType>;
    videos?: Maybe<Array<Maybe<VideoFileDocArg>>>;
};

export type OlTest = {
    __typename?: 'OLTest';
    answers?: Maybe<Array<Maybe<OlTestUserAnswer>>>;
    courseId?: Maybe<Scalars['ID']>;
    endTime?: Maybe<Scalars['Time']>;
    finalAssessmentScore?: Maybe<Scalars['Int']>;
    finished?: Maybe<Scalars['Boolean']>;
    finishedAt?: Maybe<Scalars['Time']>;
    id?: Maybe<Scalars['ID']>;
    leftTime?: Maybe<Scalars['Int']>;
    maxOriginScore?: Maybe<Scalars['Float']>;
    maxScore?: Maybe<Scalars['Float']>;
    questionIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
    resultOriginScore?: Maybe<Scalars['Float']>;
    resultScore?: Maybe<Scalars['Float']>;
    resultSuccess?: Maybe<Scalars['Boolean']>;
    startedAt?: Maybe<Scalars['Time']>;
    subjectId?: Maybe<Scalars['ID']>;
    userId?: Maybe<Scalars['ID']>;
    usualScore?: Maybe<Scalars['Int']>;
};

export type OlTestAnswer = {
    __typename?: 'OLTestAnswer';
    id?: Maybe<Scalars['ID']>;
    isCorrect?: Maybe<Scalars['Boolean']>;
    photo?: Maybe<FileInfo>;
    photoId?: Maybe<Scalars['ID']>;
    questionId?: Maybe<Scalars['ID']>;
    text?: Maybe<Scalars['String']>;
    textType?: Maybe<Scalars['String']>;
};

export type OlTestAnswerArgs = {
    id?: Maybe<Scalars['ID']>;
    isCorrect?: Maybe<Scalars['Boolean']>;
    photoId?: Maybe<Scalars['ID']>;
    text?: Maybe<Scalars['String']>;
    textType?: Maybe<OlTextType>;
};

export type OlTestInfo = {
    __typename?: 'OLTestInfo';
    /** ID курса по которому идет тест */
    courseId?: Maybe<Scalars['ID']>;
    /** true - у пользователя есть активный тест */
    exists?: Maybe<Scalars['Boolean']>;
    /** активный тест по курсу */
    isCourse?: Maybe<Scalars['Boolean']>;
    /** активный тест по дисциплине */
    isSubject?: Maybe<Scalars['Boolean']>;
    /**
     * Название дисциплины или курса,
     * по которому тест в данный момент активен
     */
    name?: Maybe<Scalars['String']>;
    /** ID дисциплины по которой идет тест */
    subjectId?: Maybe<Scalars['ID']>;
    /** ID активного тест */
    testId?: Maybe<Scalars['ID']>;
};

export type OlTestQuestion = {
    __typename?: 'OLTestQuestion';
    answers?: Maybe<Array<Maybe<OlTestAnswer>>>;
    correctAnswersCount?: Maybe<Scalars['Int']>;
    id?: Maybe<Scalars['ID']>;
    photoIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
    photos?: Maybe<Array<Maybe<FileInfo>>>;
    subjectId?: Maybe<Scalars['ID']>;
    text?: Maybe<Scalars['String']>;
    textType?: Maybe<OlTextType>;
};

export type OlTestQuestionArgs = {
    answers?: Maybe<Array<Maybe<OlTestAnswerArgs>>>;
    id?: Maybe<Scalars['ID']>;
    photoIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
    subjectId?: Maybe<Scalars['ID']>;
    text?: Maybe<Scalars['String']>;
    textType?: Maybe<OlTextType>;
};

export type OlTestQuestionCollections = {
    __typename?: 'OLTestQuestionCollections';
    items?: Maybe<Array<Maybe<OlTestQuestion>>>;
    page?: Maybe<PageReply>;
};

export type OlTestResult = {
    __typename?: 'OLTestResult';
    resultScore?: Maybe<Scalars['Float']>;
    resultSuccess?: Maybe<Scalars['Boolean']>;
};

export type OlTestStatistic = {
    __typename?: 'OLTestStatistic';
    courseTest?: Maybe<OlTestResult>;
    fullName?: Maybe<Scalars['String']>;
    isOnline?: Maybe<Scalars['Boolean']>;
    photo?: Maybe<FileInfo>;
    studentId?: Maybe<Scalars['ID']>;
    subjectTests?: Maybe<Array<Maybe<OlTestSubjectResult>>>;
    userId?: Maybe<Scalars['ID']>;
};

export type OlTestStatisticCollection = {
    __typename?: 'OLTestStatisticCollection';
    items?: Maybe<Array<Maybe<OlTestStatistic>>>;
    page?: Maybe<PageReply>;
    subjects?: Maybe<Array<Maybe<OlSubject>>>;
};

export type OlTestStatisticCollectionArgs = {
    filter?: Maybe<OlTestStatisticCollectionFilter>;
    orderBy?: Maybe<OlTestStatisticCollectionOrderBy>;
    page?: Maybe<PageArgs>;
};

export type OlTestStatisticCollectionFilter = {
    /** Курс по которому нужно собрать статистику. По умолчанию 1 */
    courseId?: Maybe<Scalars['ID']>;
    /** Нужно ли отобрать только тех, кто прошел итоговый тест? */
    courseTestFinished?: Maybe<Scalars['Boolean']>;
    /** Роль курсанта в системе ЭлЖур */
    eljurRole?: Maybe<Scalars['String']>;
    /** ФИО студента */
    fullName?: Maybe<Scalars['String']>;
    /** ID населенного пункта */
    locationId?: Maybe<Scalars['ID']>;
    /** ID региона населенного пункта */
    locationRegionId?: Maybe<Scalars['ID']>;
    /** ID образовательного учреждения */
    schoolId?: Maybe<Scalars['ID']>;
    /** Краткое наименование образовательного учреждения */
    schoolShortName?: Maybe<Scalars['String']>;
    /** Тип образовательного учреждения */
    schoolType?: Maybe<SchoolType>;
};

export type OlTestStatisticCollectionOrderBy = {
    course?: Maybe<Sort>;
    fullName?: Maybe<Sort>;
    subject?: Maybe<Sort>;
    subjectId?: Maybe<Scalars['ID']>;
};

export type OlTestSubjectResult = {
    __typename?: 'OLTestSubjectResult';
    resultScore?: Maybe<Scalars['Float']>;
    resultSuccess?: Maybe<Scalars['Boolean']>;
    subjectId?: Maybe<Scalars['ID']>;
};

export type OlTestUserAnswer = {
    __typename?: 'OLTestUserAnswer';
    answerIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
    questionId?: Maybe<Scalars['ID']>;
};

export type OlTestUserAnswerArgs = {
    answerIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
    questionId?: Maybe<Scalars['ID']>;
};

export enum OlTextType {
    html = 'html',
    markdown = 'markdown',
    plain = 'plain',
}

export type OnlineStudentPerformance = {
    __typename?: 'OnlineStudentPerformance';
    course?: Maybe<OnlineStudentPerformanceCourse>;
    subjects?: Maybe<Array<Maybe<OnlineStudentPerformanceSubject>>>;
};

export type OnlineStudentPerformanceCourse = {
    __typename?: 'OnlineStudentPerformanceCourse';
    resultSuccess?: Maybe<Scalars['Boolean']>;
    score?: Maybe<Scalars['Int']>;
    scoreInPoints?: Maybe<Scalars['Float']>;
};

export type OnlineStudentPerformanceOrderBy = {
    finishedAt?: Maybe<Sort>;
    score?: Maybe<Sort>;
    scoreInPoints?: Maybe<Sort>;
    startedAt?: Maybe<Sort>;
    subjectName?: Maybe<Sort>;
};

export type OnlineStudentPerformanceSubject = {
    __typename?: 'OnlineStudentPerformanceSubject';
    finishedAt?: Maybe<Scalars['Time']>;
    id?: Maybe<Scalars['ID']>;
    resultSuccess?: Maybe<Scalars['Boolean']>;
    score?: Maybe<Scalars['Int']>;
    scoreInPoints?: Maybe<Scalars['Float']>;
    startedAt?: Maybe<Scalars['Time']>;
    subjectId?: Maybe<Scalars['ID']>;
    subjectName?: Maybe<Scalars['String']>;
};

export type PageArgs = {
    /** Выбрать все элементы */
    all?: Maybe<Scalars['Boolean']>;
    /** Номер текущей страницы */
    page?: Maybe<Scalars['Int']>;
    /** Количество элементов на странице */
    size?: Maybe<Scalars['Int']>;
};

export type PageReply = {
    __typename?: 'PageReply';
    /** Номер текущей страницы */
    page?: Maybe<Scalars['Int']>;
    /** Количество элементов на странице */
    size?: Maybe<Scalars['Int']>;
    /** Общее количество элементов */
    total?: Maybe<Scalars['Int']>;
};

export enum PerformanceManagement {
    forAcademicPerformance = 'forAcademicPerformance',
    forActivity = 'forActivity',
    forPassingTests = 'forPassingTests',
}

export enum Platform {
    center = 'center',
    online = 'online',
    regionalCenter = 'regional_center',
}

export type PlatformAccessItem = {
    __typename?: 'PlatformAccessItem';
    isAvailable?: Maybe<Scalars['Boolean']>;
    lap?: Maybe<Lap>;
    platform?: Maybe<Platform>;
};

export enum PlatformFilter {
    center = 'center',
    noPlatform = 'no_platform',
    online = 'online',
}

export type Profile = {
    __typename?: 'Profile';
    /** user ID */
    id?: Maybe<Scalars['ID']>;
    photo?: Maybe<FileInfo>;
    photoId?: Maybe<Scalars['ID']>;
    sections?: Maybe<Array<Maybe<ProfileSection>>>;
};

export type ProfileItem = {
    __typename?: 'ProfileItem';
    label?: Maybe<Scalars['String']>;
    link?: Maybe<Scalars['String']>;
    text?: Maybe<Scalars['String']>;
};

export type ProfileSection = {
    __typename?: 'ProfileSection';
    items?: Maybe<Array<Maybe<ProfileItem>>>;
    name?: Maybe<Scalars['String']>;
};

export type Query = {
    __typename?: 'Query';
    /** Поиск категорий и статей по внутренней вики */
    findInternalWiki?: Maybe<FindInternalWikiReply>;
    /** Получить список доступных заездов (для смены заезда) */
    getAccessLaps?: Maybe<Array<Maybe<Lap>>>;
    /** Получить список доступных для выбора курсов (для смены заезда) */
    getAccessOLCourses?: Maybe<Array<Maybe<OlCourse>>>;
    /** Получить список журнала действий */
    getActionLogCollection?: Maybe<ActionLogCollection>;
    /**
     * Получить информацию об активном тесте,
     * который пользователь проходит в данный момент.
     */
    getActiveOLTest?: Maybe<OlTestInfo>;
    /** Получить инфомацию о корпусах и комнатах */
    getBuildings?: Maybe<Array<Maybe<Building>>>;
    /** Получить данные по курсантам, погоде и времени для центр дашборда (center dashboard) */
    getCDBCounts?: Maybe<CdbCounts>;
    /** Получить расписание занятий для центр дашборда */
    getCDBLessonsSchedule?: Maybe<Array<Maybe<CdbLessonSchedule>>>;
    /** Получить коллекцию рейтинга взводов для центр дашборда */
    getCDBPlatoonsRatingCollection?: Maybe<CdbPlatoonsRatingCollection>;
    /** Получить рейтинги для рот и взводов для центр дашборда */
    getCDBRatings?: Maybe<CdbRatings>;
    /** Получить коллекцию рейтинга курсантов для центр дашборда */
    getCDBStudentsRatingCollection?: Maybe<CdbStudentsRatingCollection>;
    /** Получить текущий заезд пользователя или который "идет" в данный момент */
    getCurrentLap?: Maybe<Lap>;
    /** Получить информацию о текущем региональном центре */
    getCurrentRegionalCenter?: Maybe<RegionalCenter>;
    /** Получить информацию по текущему пользователю */
    getCurrentUser?: Maybe<User>;
    /** Получить ссылку для скачивания всех сертификатов из текущего заезда */
    getEGCertificatesURL: Scalars['String'];
    /** Получить ссылку для скачивания сводной ведомости по школе */
    getEGExportSchoolsUrl: Scalars['String'];
    /** Получить ссылку для скачивания сводной ведомости по региону */
    getEGExportThroughoutLapUrl: Scalars['String'];
    /**
     * Получить ссылку для скачивания ведомости ЖУУ.
     * В аргументах указывать либо `company` и `platoon`, либо `platoonX`
     */
    getEGExportTimesheetUrl: Scalars['String'];
    /** Получить занятие */
    getEGLesson?: Maybe<EgLesson>;
    /** Получить оценку за дисциплину по занятию */
    getEGLessonDisciplineScore?: Maybe<EgLessonDisciplineScore>;
    /** Получить место проведения занятия */
    getEGLessonPlace?: Maybe<EgLessonPlace>;
    /** Получить коллекцию мест проведения занятий (для ролей с доступом) */
    getEGLessonPlaceCollection?: Maybe<EgLessonPlaceCollection>;
    /** Получить список мест проведения занятий (для всех авторизированных пользователей) */
    getEGLessonPlaces?: Maybe<Array<Maybe<EgLessonPlace>>>;
    /**
     * Получить список оценок курсантов по занятиям
     *
     * Roles: `admin`
     */
    getEGLessonScoreCollection?: Maybe<EgLessonScoreCollection>;
    /**
     * Получить список оценок по занятиям для школы
     *
     * Roles: `school`
     */
    getEGLessonScoreCollectionSchool?: Maybe<EgLessonScoreCollectionSchool>;
    /** Получить статистику по оценкам курсантов */
    getEGLessonScoresStatistic?: Maybe<EgLessonScoresStatistic>;
    /** Получить статистику по оценкам по конкретному курсанту */
    getEGLessonScoresStudent?: Maybe<EgLessonScoresStudent>;
    /**
     * Получить список времени занятий.
     * `lapId` можно не передавать, в этом случае будет подтягиваться текущий заезд
     */
    getEGLessonTimeList?: Maybe<Array<Maybe<EgLessonTime>>>;
    /**
     * Получить список занятий для отображения в расписании в виде списка.
     * `lapId` можно не передавать, в этом случае будет подтягиваться текущий заезд.
     * `date` - дата, для которой нужно получить занятия.
     * `onlyMyLessons` - отобразить только "мои" занятия в расписании
     */
    getEGLessonsList?: Maybe<EgLessonsList>;
    /** Получить список занятий для отображении в статистики. */
    getEGLessonsStatistic?: Maybe<EgLessonsStatistic>;
    /**
     * Получить список оценок по занятиям для курсанта
     *
     * Roles: `student`
     */
    getEGLessonsStudent?: Maybe<Array<Maybe<EgLessonsStudentItem>>>;
    /**
     * Получить список занятий для отображения в таблице расписания.
     * `lapId` можно не передавать, в этом случае будет подтягиваться текущий заезд.
     * `date` - дата, для которой нужно получить занятия
     */
    getEGLessonsTable?: Maybe<EgLessonsTable>;
    /** Получить таймлайн мероприятий */
    getEGLessonsTimeline?: Maybe<EgLessonsTimeline>;
    /** Получить информацию о модуле электронного журнала */
    getEGModule?: Maybe<EgModule>;
    /** Получить коллекцию модулей электронного журнала (для ролей с доступом) */
    getEGModuleCollection?: Maybe<EgModuleCollection>;
    /** Получить список модулей электронного журнала (для всех авторизированных пользователей) */
    getEGModules?: Maybe<Array<Maybe<EgModule>>>;
    /** Получить предмет по id */
    getEGSubject?: Maybe<EgSubject>;
    /** Получить коллекцию предметов (для ролей с доступом) */
    getEGSubjectCollection?: Maybe<EgSubjectCollection>;
    /** Получить список предметов (для всех авторизированных пользователей) */
    getEGSubjects?: Maybe<Array<Maybe<EgSubject>>>;
    /** Получить дисциплины, которые были использованы в расписании */
    getEGSubjectsInLap?: Maybe<Array<Maybe<EgSubject>>>;
    /** Получить ссылку на oauth авторизацию в ЭлЖур */
    getEljurOAuthLink: Scalars['String'];
    /**
     * Получить сотрудника
     *
     * Roles: `admin`
     */
    getEmployee?: Maybe<Employee>;
    /**
     * Получить список сотрудников
     *
     * Roles: `admin`
     */
    getEmployeeCollection?: Maybe<EmployeeCollection>;
    /**
     * Получить список ID дисциплин у сотрудника, которые он должен пройти.
     * Если не передать `employeeId`, то будет возвращен результат для текущего сотрудника
     */
    getEmployeeOLSubjectIds?: Maybe<Array<Scalars['ID']>>;
    /**
     * Получить список дисциплин у сотрудника, которые он должен пройти.
     * Если не передать `employeeId`, то будет возвращен результат для текущего сотрудника
     */
    getEmployeeOLSubjects?: Maybe<Array<OlSubject>>;
    /**
     * Получить оценивание
     *
     * Roles: `admin | mentor | leader`
     */
    getEvaluation?: Maybe<Evaluation>;
    /**
     * Получить список оцениваний
     *
     * Roles: `admin | mentor | leader`
     */
    getEvaluationCollection?: Maybe<EvaluationCollection>;
    /**
     * Получить критерию
     *
     * Roles: `admin`
     */
    getEvaluationCriteria?: Maybe<EvaluationCriteria>;
    /**
     * Получить критерии оценивания
     *
     * Roles: `admin`
     */
    getEvaluationCriteriaCollection?: Maybe<EvaluationCriteriaCollection>;
    /**
     * Получить список ответственных оценивания по взводам
     *
     * Roles: `admin`
     */
    getEvaluationResponsibleCollection?: Maybe<EvaluationResponsibleCollection>;
    /**
     * Получить оценки для оцениваний
     *
     * Roles: `admin | mentor | leader`
     */
    getEvaluationScoreCollection?: Maybe<EvaluationScoreCollection>;
    /** Получить общий список оценок для курсантов для раздела оценивание */
    getEvaluationStudentCollection?: Maybe<EvaluationStudentCollection>;
    /** Получить список оценок курсантов за критерии для раздела оценивание */
    getEvaluationStudentScoreCollection?: Maybe<EvaluationStudentScoreCollection>;
    /** Получить список оценок для конкретного курсанта */
    getEvaluationStudentScores?: Maybe<EvaluationStudentScores>;
    /** Получить событие */
    getEvent?: Maybe<Event>;
    /** Получить коллекцию событий */
    getEventCollection?: Maybe<EventCollection>;
    /** Получить информацию о файле */
    getFile?: Maybe<FileInfo>;
    /** Получить коллекцию студентов для справочника в центре */
    getHandbookCenterStudentCollection?: Maybe<HandbookCenterStudentCollection>;
    /** Получить коллекцию студентов для справочника в онлайне */
    getHandbookOnlineStudentCollection?: Maybe<HandbookOnlineStudentCollection>;
    /** Получить коллекцию образовательных организаций для справочника */
    getHandbookSchoolCollection?: Maybe<HandbookSchoolCollection>;
    /** Получить информацию по токену восстановления пароля */
    getInfoByResetPasswordToken?: Maybe<ResetPasswordTokenInfo>;
    /**
     * Получить информацию о распределении курсантов по взводам и ротам
     *
     * Roles: `admin`
     */
    getInfoDistributionOfStudents?: Maybe<InfoDistributionOfStudents>;
    /** Получить статью внутренней wiki */
    getInternalWikiArticle?: Maybe<InternalWikiArticle>;
    /** Получить список статей внутренней wiki */
    getInternalWikiArticles?: Maybe<Array<Maybe<InternalWikiArticle>>>;
    /** Получить список категорий внутренней wiki */
    getInternalWikiCategories?: Maybe<Array<Maybe<InternalWikiCategory>>>;
    /** Получить информацию о категории внутренней wiki */
    getInternalWikiCategory?: Maybe<InternalWikiCategory>;
    /** Получить данные для виджета Jivosite */
    getJivositeInfo?: Maybe<JivositeInfo>;
    /** Получить заезд по его id */
    getLap?: Maybe<Lap>;
    /** Получить доступ заезда. */
    getLapAccess?: Maybe<LapAccess>;
    /** Получить коллекцию заездов */
    getLapCollection?: Maybe<LapCollection>;
    /** Получить список заездов */
    getLaps?: Maybe<Array<Maybe<Lap>>>;
    /**
     * Получить список заездов, для которых есть возможность скачать сертификаты
     *
     * - `schoolId` - Нужно передать в случае вызова от имени администратора
     *
     * Roles: `school` | `admin`
     */
    getLapsWithCertificates?: Maybe<Array<LapWithCertificateUrl>>;
    /** Получить текущие данные о прямом эфире */
    getLiveBroadcast?: Maybe<LiveBroadcast>;
    /** Получить список населенных пунктов */
    getLocations?: Maybe<Array<Location>>;
    /** Получить цифры по курсантам для онлайн дашборда */
    getODBCounts?: Maybe<OdbCounts>;
    /** Получить данные для диаграммы "Прохождение обучения (тест по курсу)" */
    getODBCourseTestPassing?: Maybe<OdbCourseTestPassing>;
    /** Получить список населенных пунктов для онлайн дашборда */
    getODBLocations?: Maybe<OdbLocations>;
    /** Получить список регионов для онлайн дашборда */
    getODBRegions?: Maybe<OdbRegions>;
    /** Получить список образовательных организаций для онлайн дашборда */
    getODBSchools?: Maybe<OdbSchools>;
    /** Получить данные для диаграммы "Оценки за тест по курсу" */
    getODBScores?: Maybe<OdbScores>;
    /** Получить список дисциплин для онлайн дашборда */
    getODBSubjects?: Maybe<OdbSubjects>;
    /**
     * Получить ссылку на скачивание сертификатов
     *
     * Roles: `admin`
     */
    getOLCertificateURLByFullnames: Scalars['String'];
    /** Получить ссылку на скачивание сертификата */
    getOLCertificateURLForAnonStudent: Scalars['String'];
    /**
     * Получить ссылку для скачивания архива с сертификатами курсантов школы.
     * Необятельные параметры школа и онлайн курс
     * определяются на основе токена текущего пользователя
     */
    getOLCertificatesURLBySchool: Scalars['String'];
    /** Получить ссылку на скачивание сертификатов для ВПД 2024 */
    getOLCertificatesURLForDictation2024: Scalars['String'];
    /** Получить объект курса */
    getOLCourse?: Maybe<OlCourse>;
    /** Получить список курсов (для ролей с доступом) */
    getOLCourseCollection?: Maybe<OlCourseCollection>;
    /** Получить список курсов (для всех авторизированных пользователей, кроме студента) */
    getOLCourses?: Maybe<Array<Maybe<OlCourse>>>;
    /** Получить лекцию */
    getOLLecture?: Maybe<OlLecture>;
    /** Получить объект дисциплины */
    getOLSubject?: Maybe<OlSubject>;
    /** Получить коллекцию дисциплин (для ролей с доступом) */
    getOLSubjectCollection?: Maybe<OlSubjectCollection>;
    /** Получить список дисциплин (для всех авторизированных пользователей) */
    getOLSubjects?: Maybe<Array<Maybe<OlSubject>>>;
    /**
     * Получить объект теста.
     * Для теста по дисциплине нужно указывать `subjectId` и `courseId`.
     * Для теста по курсу нужно указывать только `courseId`.
     */
    getOLTest?: Maybe<OlTest>;
    /** Получить вопрос по id */
    getOLTestQuestion?: Maybe<OlTestQuestion>;
    /**
     * Получить список всех вопросов для теста дисциплины
     *
     * Roles: `admin`
     */
    getOLTestQuestionCollection?: Maybe<OlTestQuestionCollections>;
    /**
     * Получить статистику по тестам.
     *
     * Roles: `admin` | `school`
     */
    getOLTestStatisticCollection?: Maybe<OlTestStatisticCollection>;
    /**
     * Получить ссылку на скачивание ведомости по статистике по тестам.
     * `schoolId` можно не передовать, если запрос делается от пользователя с ролью `school`
     */
    getOLTestStatisticReportUrl: Scalars['String'];
    /** Получить статистику по успеваемости для студента */
    getOnlineStudentPerformance?: Maybe<OnlineStudentPerformance>;
    /**
     * Получить ссылку на скачивание акта по сборам.
     * `schoolId` можно не передовать, если запрос делается от пользователя с ролью `school`
     */
    getPaperForGathering: Scalars['String'];
    /** Получить список карточек доступов к платформам */
    getPlatformsAccessList?: Maybe<Array<Maybe<PlatformAccessItem>>>;
    /** Получить профиль текущего пользователя */
    getProfile?: Maybe<Profile>;
    /** Получить данные для дашборда региональных центров */
    getRCDCounts?: Maybe<RcdCounts>;
    /** Получить данные для дашборда региональных центров по платформе */
    getRCDCountsPlatform?: Maybe<RcdCountsPlatform>;
    /** Получить данные для дашборда региональных центров по возрастным ха-кам студентов */
    getRCDLapsGenderStudents?: Maybe<Array<Maybe<RcdLapsGenderStudents>>>;
    /** Получить рейтинг рот. Результат уже отсортирован по оценкам */
    getRatingsCompany?: Maybe<Array<Maybe<RatingsCompanyItem>>>;
    /** Получить рейтинг взводов. Результат уже отсортирован по оценкам */
    getRatingsPlatoon?: Maybe<Array<Maybe<RatingsPlatoonItem>>>;
    /** Получить таблицу рейтингов */
    getRatingsTable?: Maybe<RatingsTable>;
    /**
     * Получить региональный центр
     *
     * Roles: `super_admin`
     */
    getRegionalCenter?: Maybe<RegionalCenter>;
    /**
     * Получить список региональных центров
     *
     * Roles: `super_admin`
     */
    getRegionalCenterCollection?: Maybe<RegionalCenterCollection>;
    /** Получить список регионов населенных пунктов */
    getRegions?: Maybe<Array<Region>>;
    /** Получить данные для дашборда киоска (stand dashboard) */
    getSDBInformation?: Maybe<SdbInformation>;
    /**
     * Получить объект образовательного учреждения по id.
     *
     * Roles: `admin` | `school`
     */
    getSchool?: Maybe<School>;
    /**
     * Получить список образовательных учреждений.
     *
     * Roles: `admin`
     */
    getSchoolCollection?: Maybe<SchoolCollection>;
    /**
     * Получить список номеров образовательных организаций
     * находящихся в определенном населенном пункте
     * или регионе
     */
    getSchoolNumbers?: Maybe<Array<Maybe<Scalars['Int']>>>;
    /**
     * Получить сводную статистику для школы.
     * Если не передавать `lapId` то статистика будет за все заезды.
     */
    getSchoolSummaryStats?: Maybe<SchoolSummaryStats>;
    /**
     * Получить список типов образовательных учреждений,
     * находящихся в определенном населенном пункте.
     *
     * Roles: `admin`
     */
    getSchoolTypes?: Maybe<Array<SchoolType>>;
    /** Получить ссылку для скачивания школ (экспорт) */
    getSchoolsExportUrl: Scalars['String'];
    /** Получить детальный рейтинг по ротам для киоска */
    getStandCompanyRatings?: Maybe<Array<Maybe<StandCompanyRatingsItem>>>;
    /** Получить список преподавателей для киоска */
    getStandEmployees?: Maybe<Array<Maybe<StandEmployeesItem>>>;
    /** Получить общий рейтинг по ротам для киоска */
    getStandSummaryCompanyRatings?: Maybe<Array<Maybe<StandSummaryCompanyRatingsItem>>>;
    /** Получить рейтинг курсантов (топ3) для киоска */
    getStandTopStudentsRatings?: Maybe<Array<Maybe<StandRatingsStudentItem>>>;
    /**
     * Получить объкет курсанта по id.
     *
     * Roles: `admin` | `school` | `student`
     */
    getStudent?: Maybe<Student>;
    /**
     * Получить список курсантов.
     *
     * Roles: `admin` | `school`
     */
    getStudentCollection?: Maybe<StudentCollection>;
    /** Получить профиль курсанта */
    getStudentProfile?: Maybe<StudentProfile>;
    /** Получить числовую статистику по курсанту: баллы, место в рейтинге */
    getStudentProfileCounts?: Maybe<StudentProfileCounts>;
    /** Получить список классов курсантов */
    getStudentSchoolClasses?: Maybe<Array<Maybe<Scalars['String']>>>;
    /**
     * Получить доступные взводы и роты, в которых находятся курсанты школы
     *
     * Roles: `school`
     */
    getStudentTroopNumbers?: Maybe<StudentTroopNumbers>;
    /** Получить ссылку для выгрузки курсантов с ротами и взводами */
    getStudentTroopsExportUrl: Scalars['String'];
    /** Получить ссылку для скачивания шаблона импорта курсантов */
    getStudentsImportTemplateUrl: Scalars['String'];
    /** Получить общие результаты по реализованным курсам */
    getSummaryLapsCoursesResults?: Maybe<Array<Maybe<SummaryLapsCourseResult>>>;
    /** Получить общие результаты половозрастных характеристик */
    getSummaryLapsGenderStudentsByRegionResults?: Maybe<Array<Maybe<SummaryLapsGenderStudents>>>;
    /** Получить общие результаты по курсантам и образовательным организациям для онлайна */
    getSummaryLapsOnlineStudentsResults?: Maybe<SummaryLapsOnlineStudentsResults>;
    /** Получить общие результаты по реализованным программам */
    getSummaryLapsProgramsResults?: Maybe<Array<Maybe<SummaryLapsProgramResult>>>;
    /** Получить общие результаты по проведенным сборам */
    getSummaryLapsResults?: Maybe<SummaryLapsResults>;
    /** Получить общие результаты по образовательным организациям в виде коллекции */
    getSummaryLapsSchoolCollection?: Maybe<SchoolCollection>;
    /** Получить общие результаты по курсантам в виде коллекции */
    getSummaryLapsStudentCollection?: Maybe<StudentCollection>;
    /** Получить общие результаты по курсантам и образовательным организациям */
    getSummaryLapsStudentsResults?: Maybe<SummaryLapsStudentsResults>;
    /** Получить ссылку для выгрузки курсантов по общим результатам */
    getSummaryStudentsExportUrl: Scalars['String'];
    /** Получить возможности ролей в виде таблицы */
    getUserGrantsTable?: Maybe<UserGrantsTable>;
    /** Получить вики статью */
    getWikiArticle?: Maybe<WikiArticle>;
    /** Получить список вики статей */
    getWikiCollection?: Maybe<WikiCollection>;
    /** Имеет ли доступ текущий пользователь к платформе */
    hasAccessForPlatform?: Maybe<Scalars['Boolean']>;
};

export type QueryFindInternalWikiArgs = {
    filter?: Maybe<FindInternalWikiFilter>;
};

export type QueryGetAccessLapsArgs = {
    args?: Maybe<LapCollectionArgs>;
};

export type QueryGetActionLogCollectionArgs = {
    args?: Maybe<ActionLogCollectionArgs>;
};

export type QueryGetCdbLessonsScheduleArgs = {
    platoonX: Scalars['Int'];
};

export type QueryGetCdbStudentsRatingCollectionArgs = {
    args?: Maybe<CdbStudentsRatingCollectionArgs>;
};

export type QueryGetEgExportSchoolsUrlArgs = {
    locationId?: Maybe<Scalars['ID']>;
    modules: Array<EgExportSchoolsModule>;
    schoolId?: Maybe<Scalars['ID']>;
};

export type QueryGetEgExportThroughoutLapUrlArgs = {
    modules: Array<EgExportSchoolsModule>;
};

export type QueryGetEgExportTimesheetUrlArgs = {
    company?: Maybe<Scalars['Int']>;
    platoon?: Maybe<Scalars['Int']>;
    platoonX?: Maybe<Scalars['Int']>;
};

export type QueryGetEgLessonArgs = {
    id: Scalars['ID'];
};

export type QueryGetEgLessonDisciplineScoreArgs = {
    lessonId: Scalars['ID'];
};

export type QueryGetEgLessonPlaceArgs = {
    id: Scalars['ID'];
};

export type QueryGetEgLessonPlaceCollectionArgs = {
    args?: Maybe<EgLessonPlaceCollectionArgs>;
};

export type QueryGetEgLessonScoreCollectionArgs = {
    args?: Maybe<EgLessonScoreCollectionArgs>;
};

export type QueryGetEgLessonScoreCollectionSchoolArgs = {
    args?: Maybe<EgLessonScoreCollectionSchoolArgs>;
};

export type QueryGetEgLessonScoresStatisticArgs = {
    args?: Maybe<EgLessonScoresStatisticArgs>;
};

export type QueryGetEgLessonScoresStudentArgs = {
    studentId: Scalars['ID'];
    subjectId: Scalars['ID'];
};

export type QueryGetEgLessonTimeListArgs = {
    args: EgLessonTimeListArgs;
};

export type QueryGetEgLessonsListArgs = {
    args: EgLessonsListArgs;
};

export type QueryGetEgLessonsStatisticArgs = {
    args?: Maybe<EgLessonStatisticArgs>;
};

export type QueryGetEgLessonsStudentArgs = {
    args?: Maybe<EgLessonsStudentArgs>;
};

export type QueryGetEgLessonsTableArgs = {
    args: EgLessonsTableArgs;
};

export type QueryGetEgLessonsTimelineArgs = {
    args: EgLessonsTimelineArgs;
};

export type QueryGetEgModuleArgs = {
    id: Scalars['ID'];
};

export type QueryGetEgModuleCollectionArgs = {
    args?: Maybe<EgModuleCollectionArgs>;
};

export type QueryGetEgSubjectArgs = {
    id: Scalars['ID'];
};

export type QueryGetEgSubjectCollectionArgs = {
    args?: Maybe<EgSubjectCollectionArgs>;
};

export type QueryGetEljurOAuthLinkArgs = {
    authUrl: Scalars['String'];
    backUrl: Scalars['String'];
    regionalCenterId: Scalars['ID'];
};

export type QueryGetEmployeeArgs = {
    id: Scalars['ID'];
};

export type QueryGetEmployeeCollectionArgs = {
    args?: Maybe<EmployeeCollectionArgs>;
};

export type QueryGetEmployeeOlSubjectIdsArgs = {
    employeeId?: Maybe<Scalars['ID']>;
};

export type QueryGetEmployeeOlSubjectsArgs = {
    employeeId?: Maybe<Scalars['ID']>;
};

export type QueryGetEvaluationArgs = {
    id: Scalars['ID'];
};

export type QueryGetEvaluationCollectionArgs = {
    date: Scalars['String'];
};

export type QueryGetEvaluationCriteriaArgs = {
    id: Scalars['ID'];
};

export type QueryGetEvaluationCriteriaCollectionArgs = {
    forStudent?: Maybe<Scalars['Boolean']>;
};

export type QueryGetEvaluationScoreCollectionArgs = {
    args?: Maybe<EvaluationScoreCollectionArgs>;
};

export type QueryGetEvaluationStudentScoreCollectionArgs = {
    platoonX: Scalars['Int'];
};

export type QueryGetEvaluationStudentScoresArgs = {
    studentId: Scalars['ID'];
};

export type QueryGetEventArgs = {
    id: Scalars['ID'];
};

export type QueryGetEventCollectionArgs = {
    args?: Maybe<EventCollectionArgs>;
};

export type QueryGetFileArgs = {
    id: Scalars['ID'];
};

export type QueryGetHandbookCenterStudentCollectionArgs = {
    args?: Maybe<HandbookStudentCollectionArgs>;
};

export type QueryGetHandbookOnlineStudentCollectionArgs = {
    args?: Maybe<HandbookStudentCollectionArgs>;
};

export type QueryGetHandbookSchoolCollectionArgs = {
    args?: Maybe<HandbookSchoolCollectionArgs>;
};

export type QueryGetInfoByResetPasswordTokenArgs = {
    token: Scalars['String'];
};

export type QueryGetInfoDistributionOfStudentsArgs = {
    lapId?: Maybe<Scalars['ID']>;
};

export type QueryGetInternalWikiArticleArgs = {
    id: Scalars['ID'];
};

export type QueryGetInternalWikiArticlesArgs = {
    categoryId: Scalars['ID'];
};

export type QueryGetInternalWikiCategoryArgs = {
    id: Scalars['ID'];
};

export type QueryGetLapArgs = {
    id: Scalars['ID'];
};

export type QueryGetLapAccessArgs = {
    id: Scalars['ID'];
};

export type QueryGetLapCollectionArgs = {
    args?: Maybe<LapCollectionArgs>;
};

export type QueryGetLapsArgs = {
    courseId?: Maybe<Scalars['ID']>;
    platform?: Maybe<Platform>;
};

export type QueryGetLapsWithCertificatesArgs = {
    schoolId?: Maybe<Scalars['ID']>;
};

export type QueryGetLocationsArgs = {
    lapId?: Maybe<Scalars['ID']>;
    regionId?: Maybe<Scalars['ID']>;
    regionIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type QueryGetOdbCountsArgs = {
    args?: Maybe<OdbCountsArgs>;
};

export type QueryGetOdbCourseTestPassingArgs = {
    args?: Maybe<OdbCourseTestPassingArgs>;
};

export type QueryGetOdbLocationsArgs = {
    args?: Maybe<OdbLocationsArgs>;
};

export type QueryGetOdbRegionsArgs = {
    args?: Maybe<OdbRegionsArgs>;
};

export type QueryGetOdbSchoolsArgs = {
    args?: Maybe<OdbSchoolsArgs>;
};

export type QueryGetOdbScoresArgs = {
    args?: Maybe<OdbScoresArgs>;
};

export type QueryGetOdbSubjectsArgs = {
    args?: Maybe<OdbSubjectsArgs>;
};

export type QueryGetOlCertificateUrlByFullnamesArgs = {
    courseId: Scalars['ID'];
    fullNames: Array<Scalars['String']>;
};

export type QueryGetOlCertificateUrlForAnonStudentArgs = {
    fullName: Scalars['String'];
};

export type QueryGetOlCertificatesUrlBySchoolArgs = {
    courseId?: Maybe<Scalars['ID']>;
    schoolId?: Maybe<Scalars['ID']>;
};

export type QueryGetOlCertificatesUrlForDictation2024Args = {
    fullNames: Array<Scalars['String']>;
};

export type QueryGetOlCourseArgs = {
    id: Scalars['ID'];
};

export type QueryGetOlCourseCollectionArgs = {
    args?: Maybe<OlCourseCollectionArgs>;
};

export type QueryGetOlLectureArgs = {
    id: Scalars['ID'];
};

export type QueryGetOlSubjectArgs = {
    id: Scalars['ID'];
};

export type QueryGetOlSubjectCollectionArgs = {
    args?: Maybe<OlSubjectCollectionArgs>;
};

export type QueryGetOlTestArgs = {
    courseId: Scalars['ID'];
    subjectId?: Maybe<Scalars['ID']>;
};

export type QueryGetOlTestQuestionArgs = {
    id: Scalars['ID'];
};

export type QueryGetOlTestQuestionCollectionArgs = {
    subjectId: Scalars['ID'];
};

export type QueryGetOlTestStatisticCollectionArgs = {
    args?: Maybe<OlTestStatisticCollectionArgs>;
};

export type QueryGetOlTestStatisticReportUrlArgs = {
    courseId: Scalars['ID'];
    schoolId?: Maybe<Scalars['ID']>;
};

export type QueryGetOnlineStudentPerformanceArgs = {
    courseId: Scalars['ID'];
    orderBy?: Maybe<OnlineStudentPerformanceOrderBy>;
    userId: Scalars['ID'];
};

export type QueryGetPaperForGatheringArgs = {
    schoolId?: Maybe<Scalars['ID']>;
};

export type QueryGetRcdCountsPlatformArgs = {
    platform: Platform;
};

export type QueryGetRatingsTableArgs = {
    args?: Maybe<RatingsTableArgs>;
};

export type QueryGetRegionalCenterArgs = {
    id: Scalars['ID'];
};

export type QueryGetRegionalCenterCollectionArgs = {
    args?: Maybe<RegionalCenterCollectionArgs>;
};

export type QueryGetRegionsArgs = {
    args?: Maybe<RegionArgs>;
};

export type QueryGetSchoolArgs = {
    id: Scalars['ID'];
};

export type QueryGetSchoolCollectionArgs = {
    args?: Maybe<SchoolCollectionArgs>;
};

export type QueryGetSchoolNumbersArgs = {
    locationId?: Maybe<Scalars['ID']>;
    regionId?: Maybe<Scalars['ID']>;
};

export type QueryGetSchoolSummaryStatsArgs = {
    lapId?: Maybe<Scalars['ID']>;
    schoolId: Scalars['ID'];
};

export type QueryGetSchoolTypesArgs = {
    locationId: Scalars['ID'];
};

export type QueryGetStudentArgs = {
    id: Scalars['ID'];
};

export type QueryGetStudentCollectionArgs = {
    args?: Maybe<StudentCollectionArgs>;
};

export type QueryGetStudentProfileArgs = {
    id: Scalars['ID'];
};

export type QueryGetStudentProfileCountsArgs = {
    id: Scalars['ID'];
    lapByStudentId?: Maybe<Scalars['Boolean']>;
    lapFrom?: Maybe<Scalars['Date']>;
    lapTo?: Maybe<Scalars['Date']>;
};

export type QueryGetStudentSchoolClassesArgs = {
    regionalCenterId?: Maybe<Scalars['ID']>;
};

export type QueryGetStudentsImportTemplateUrlArgs = {
    schoolId?: Maybe<Scalars['ID']>;
};

export type QueryGetSummaryLapsCoursesResultsArgs = {
    filter?: Maybe<SummaryResultsFilter>;
};

export type QueryGetSummaryLapsGenderStudentsByRegionResultsArgs = {
    filter?: Maybe<SummaryResultsFilter>;
};

export type QueryGetSummaryLapsOnlineStudentsResultsArgs = {
    filter?: Maybe<SummaryResultsFilter>;
};

export type QueryGetSummaryLapsProgramsResultsArgs = {
    filter?: Maybe<SummaryResultsFilter>;
};

export type QueryGetSummaryLapsResultsArgs = {
    filter?: Maybe<SummaryResultsFilter>;
};

export type QueryGetSummaryLapsSchoolCollectionArgs = {
    args?: Maybe<SummaryLapsSchoolCollectionArgs>;
};

export type QueryGetSummaryLapsStudentCollectionArgs = {
    args?: Maybe<SummaryLapsStudentCollectionArgs>;
};

export type QueryGetSummaryLapsStudentsResultsArgs = {
    filter?: Maybe<SummaryResultsFilter>;
};

export type QueryGetSummaryStudentsExportUrlArgs = {
    filter?: Maybe<SummaryResultsFilter>;
};

export type QueryGetWikiArticleArgs = {
    id: Scalars['ID'];
};

export type QueryGetWikiCollectionArgs = {
    args: WikiCollectionArgs;
};

export type RcdCounts = {
    __typename?: 'RCDCounts';
    regionalCenters?: Maybe<Scalars['Int']>;
    schools?: Maybe<Scalars['Int']>;
    students?: Maybe<Scalars['Int']>;
};

export type RcdCountsPlatform = {
    __typename?: 'RCDCountsPlatform';
    allStudents?: Maybe<Scalars['Int']>;
    averageScore?: Maybe<Scalars['Float']>;
    finishedStudents?: Maybe<Scalars['Int']>;
    laps?: Maybe<Scalars['Int']>;
    notFinishedStudents?: Maybe<Scalars['Int']>;
};

export type RcdDate = {
    __typename?: 'RCDDate';
    date?: Maybe<Scalars['String']>;
    time?: Maybe<Scalars['String']>;
};

export type RcdLapsGenderStudents = {
    __typename?: 'RCDLapsGenderStudents';
    gender?: Maybe<Scalars['String']>;
    items?: Maybe<Array<Maybe<RcdLapsGenderStudentsItem>>>;
    summary?: Maybe<Scalars['Int']>;
};

export type RcdLapsGenderStudentsItem = {
    __typename?: 'RCDLapsGenderStudentsItem';
    age?: Maybe<Scalars['Int']>;
    count?: Maybe<Scalars['Int']>;
};

export type RatingsCompanyItem = {
    __typename?: 'RatingsCompanyItem';
    company?: Maybe<Scalars['Int']>;
    score?: Maybe<Scalars['Int']>;
};

export type RatingsPlatoonItem = {
    __typename?: 'RatingsPlatoonItem';
    platoonX?: Maybe<Scalars['Int']>;
    score?: Maybe<Scalars['Int']>;
};

export type RatingsTable = {
    __typename?: 'RatingsTable';
    items?: Maybe<Array<Maybe<RatingsTableItem>>>;
    modules?: Maybe<Array<Maybe<RatingsTableModule>>>;
};

export type RatingsTableArgs = {
    filter?: Maybe<RatingsTableFilter>;
    orderBy?: Maybe<RatingsTableOrderBy>;
};

export type RatingsTableAverageScoreOrderBy = {
    moduleId?: Maybe<Scalars['ID']>;
    score?: Maybe<Sort>;
};

export type RatingsTableFilter = {
    date?: Maybe<Scalars['Date']>;
};

export type RatingsTableItem = {
    __typename?: 'RatingsTableItem';
    company?: Maybe<Scalars['Int']>;
    date?: Maybe<Scalars['Date']>;
    finalAverageScore?: Maybe<Scalars['Float']>;
    finalScore?: Maybe<Scalars['Int']>;
    id?: Maybe<Scalars['ID']>;
    modulesAverageScores?: Maybe<Array<Maybe<RatingsTableModuleScore>>>;
    platoon?: Maybe<Scalars['Int']>;
    platoonX?: Maybe<Scalars['Int']>;
};

export type RatingsTableModule = {
    __typename?: 'RatingsTableModule';
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
};

export type RatingsTableModuleScore = {
    __typename?: 'RatingsTableModuleScore';
    moduleId?: Maybe<Scalars['ID']>;
    score?: Maybe<Scalars['Float']>;
};

export type RatingsTableOrderBy = {
    averageScore?: Maybe<RatingsTableAverageScoreOrderBy>;
    company?: Maybe<Sort>;
    date?: Maybe<Sort>;
    finalAverageScore?: Maybe<Sort>;
    finalScore?: Maybe<Sort>;
    platoon?: Maybe<Sort>;
    platoonX?: Maybe<Sort>;
};

export type Region = {
    __typename?: 'Region';
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    order?: Maybe<Scalars['Int']>;
};

export type RegionArgs = {
    filter?: Maybe<RegionFilter>;
    orderBy?: Maybe<RegionOrderBy>;
};

export type RegionFilter = {
    all?: Maybe<Scalars['Boolean']>;
    forAnonRegionalCenter?: Maybe<Scalars['Boolean']>;
    id?: Maybe<Scalars['ID']>;
    ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
    name?: Maybe<Scalars['String']>;
};

export type RegionOrderBy = {
    id?: Maybe<Sort>;
    name?: Maybe<Sort>;
};

export type RegionalCenter = {
    __typename?: 'RegionalCenter';
    canDelete?: Maybe<Scalars['Boolean']>;
    id?: Maybe<Scalars['ID']>;
    integration?: Maybe<RegionalCenterIntegration>;
    location?: Maybe<Location>;
    name?: Maybe<Scalars['String']>;
    platforms?: Maybe<Array<Maybe<Platform>>>;
    regions?: Maybe<Array<Maybe<Region>>>;
    representative?: Maybe<Employee>;
};

export type RegionalCenterCollection = {
    __typename?: 'RegionalCenterCollection';
    items?: Maybe<Array<Maybe<RegionalCenter>>>;
    page?: Maybe<PageReply>;
};

export type RegionalCenterCollectionArgs = {
    filter?: Maybe<RegionalCenterCollectionFilter>;
    orderBy?: Maybe<RegionalCenterCollectionOrderBy>;
    page?: Maybe<PageArgs>;
};

export type RegionalCenterCollectionFilter = {
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
};

export type RegionalCenterCollectionOrderBy = {
    id?: Maybe<Sort>;
    name?: Maybe<Sort>;
};

export type RegionalCenterCreateArgs = {
    integration?: Maybe<RegionalCenterIntegrationArgs>;
    locationId?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    platforms?: Maybe<Array<Maybe<Platform>>>;
    regions?: Maybe<Array<Maybe<RegionalCenterRegion>>>;
    representativeEmail?: Maybe<Scalars['String']>;
    representativeName?: Maybe<Scalars['String']>;
};

export type RegionalCenterEljur = {
    __typename?: 'RegionalCenterEljur';
    apiUrl?: Maybe<Scalars['String']>;
    devId?: Maybe<Scalars['String']>;
    devKey?: Maybe<Scalars['String']>;
};

export type RegionalCenterEljurArgs = {
    apiUrl?: Maybe<Scalars['String']>;
    devId?: Maybe<Scalars['String']>;
    devKey?: Maybe<Scalars['String']>;
};

export type RegionalCenterIntegration = {
    __typename?: 'RegionalCenterIntegration';
    eljur?: Maybe<RegionalCenterEljur>;
};

export type RegionalCenterIntegrationArgs = {
    eljur?: Maybe<RegionalCenterEljurArgs>;
};

export type RegionalCenterRegion = {
    id?: Maybe<Scalars['ID']>;
    order?: Maybe<Scalars['Int']>;
};

export type RegionalCenterUpdateArgs = {
    integration?: Maybe<RegionalCenterIntegrationArgs>;
    locationId?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    platforms?: Maybe<Array<Maybe<Platform>>>;
    regions?: Maybe<Array<Maybe<RegionalCenterRegion>>>;
    representativeEmail?: Maybe<Scalars['String']>;
    representativeName?: Maybe<Scalars['String']>;
    representativePhone?: Maybe<Scalars['String']>;
};

export type RegisterStudentAnonymouslyArgs = {
    captchaToken: Scalars['String'];
    locationId: Scalars['ID'];
    regionId: Scalars['ID'];
};

export type RegisterStudentArgs = {
    email: Scalars['String'];
    fullName: Scalars['String'];
    locationId: Scalars['ID'];
    password: Scalars['String'];
    phone: Scalars['String'];
    regionId: Scalars['ID'];
    regionalCenterId: Scalars['ID'];
};

export type ResetPasswordTokenInfo = {
    __typename?: 'ResetPasswordTokenInfo';
    /** ФИО пользователя */
    fullName?: Maybe<Scalars['String']>;
};

export type SdbEvent = {
    __typename?: 'SDBEvent';
    date?: Maybe<Scalars['Date']>;
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    photo?: Maybe<FileInfo>;
    photoId?: Maybe<Scalars['ID']>;
    placeName?: Maybe<Scalars['String']>;
    time?: Maybe<Scalars['String']>;
};

export type SdbGenderStudent = {
    __typename?: 'SDBGenderStudent';
    age?: Maybe<Scalars['Int']>;
    count?: Maybe<Scalars['Int']>;
    gender?: Maybe<Scalars['String']>;
};

export type SdbGenderStudents = {
    __typename?: 'SDBGenderStudents';
    gender?: Maybe<Scalars['String']>;
    items?: Maybe<Array<Maybe<SdbGenderStudent>>>;
    summary?: Maybe<Scalars['Int']>;
};

export type SdbInformation = {
    __typename?: 'SDBInformation';
    bestStudent?: Maybe<SdbInformationBestStudent>;
    companiesRating?: Maybe<Array<Maybe<SdbInformationCompaniesRating>>>;
    date?: Maybe<SdbInformationDate>;
    lap?: Maybe<Lap>;
    students?: Maybe<Scalars['Int']>;
    weather?: Maybe<SdbInformationWeather>;
};

export type SdbInformationBestStudent = {
    __typename?: 'SDBInformationBestStudent';
    company?: Maybe<Scalars['Int']>;
    fullName?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    photo?: Maybe<FileInfo>;
    platoon?: Maybe<Scalars['Int']>;
    platoonX?: Maybe<Scalars['Int']>;
    score?: Maybe<Scalars['Int']>;
};

export type SdbInformationBirthdateStudent = {
    __typename?: 'SDBInformationBirthdateStudent';
    age?: Maybe<Scalars['Int']>;
    birthdate?: Maybe<Scalars['String']>;
    company?: Maybe<Scalars['Int']>;
    fullName?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    platoon?: Maybe<Scalars['Int']>;
};

export type SdbInformationCompaniesRating = {
    __typename?: 'SDBInformationCompaniesRating';
    company: Scalars['Int'];
    score: Scalars['Int'];
};

export type SdbInformationData = {
    __typename?: 'SDBInformationData';
    bestStudent?: Maybe<SdbInformationBestStudent>;
    birthdateStudents?: Maybe<Array<Maybe<SdbInformationBirthdateStudent>>>;
    companiesRating?: Maybe<Array<Maybe<SdbInformationCompaniesRating>>>;
    events?: Maybe<Array<Maybe<SdbEvent>>>;
    genderStudents?: Maybe<Array<Maybe<SdbGenderStudents>>>;
    lap?: Maybe<Lap>;
    platoonsRating?: Maybe<Array<Maybe<SdbInformationPlatoonsRating>>>;
    students?: Maybe<Scalars['Int']>;
};

export type SdbInformationDate = {
    __typename?: 'SDBInformationDate';
    date?: Maybe<Scalars['String']>;
    time?: Maybe<Scalars['String']>;
};

export type SdbInformationPlatoonsRating = {
    __typename?: 'SDBInformationPlatoonsRating';
    platoonX: Scalars['Int'];
    score: Scalars['Int'];
};

export type SdbInformationWeather = {
    __typename?: 'SDBInformationWeather';
    condition?: Maybe<Scalars['String']>;
    conditionSlug?: Maybe<WeatherConditionTypes>;
    icon?: Maybe<Scalars['String']>;
    temp?: Maybe<Scalars['Int']>;
};

export type SdbSubscriptionInformationWeather = {
    __typename?: 'SDBSubscriptionInformationWeather';
    fact?: Maybe<SdbInformationWeather>;
    forecasts?: Maybe<Array<Maybe<SdbSubscriptionInformationWeatherForecast>>>;
};

export type SdbSubscriptionInformationWeatherForecast = {
    __typename?: 'SDBSubscriptionInformationWeatherForecast';
    date?: Maybe<Scalars['String']>;
    day?: Maybe<SdbInformationWeather>;
    night?: Maybe<SdbInformationWeather>;
};

export type School = {
    __typename?: 'School';
    accessSentAt?: Maybe<Scalars['Time']>;
    accessSentStatus?: Maybe<Scalars['String']>;
    accessSentStatusText?: Maybe<Scalars['String']>;
    attachedUser?: Maybe<User>;
    canDeleteStudent?: Maybe<Scalars['Boolean']>;
    comment?: Maybe<Scalars['String']>;
    createdAt?: Maybe<Scalars['Time']>;
    currentStudentsCount?: Maybe<Scalars['Int']>;
    email?: Maybe<Scalars['String']>;
    fullName?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    isAccessSent?: Maybe<Scalars['Boolean']>;
    lapAccessList?: Maybe<Array<Maybe<LapAccess>>>;
    location?: Maybe<Location>;
    maxStudentsCount?: Maybe<Scalars['Int']>;
    number?: Maybe<Scalars['Int']>;
    phone?: Maybe<Scalars['String']>;
    profile?: Maybe<Scalars['String']>;
    region?: Maybe<Region>;
    responsiblePerson?: Maybe<Scalars['String']>;
    shortName?: Maybe<Scalars['String']>;
    studentsAddMode?: Maybe<StudentsAddMode>;
    type?: Maybe<SchoolType>;
    updatedAt?: Maybe<Scalars['Time']>;
};

export type SchoolArgs = {
    comment?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    fullName?: Maybe<Scalars['String']>;
    locationId?: Maybe<Scalars['ID']>;
    maxStudentsCount?: Maybe<Scalars['Int']>;
    number?: Maybe<Scalars['Int']>;
    phone?: Maybe<Scalars['String']>;
    profile?: Maybe<Scalars['String']>;
    responsiblePerson?: Maybe<Scalars['String']>;
    shortName?: Maybe<Scalars['String']>;
    studentsAddMode?: Maybe<StudentsAddMode>;
    type?: Maybe<SchoolType>;
};

export type SchoolCollection = {
    __typename?: 'SchoolCollection';
    items?: Maybe<Array<Maybe<School>>>;
    page?: Maybe<PageReply>;
};

export type SchoolCollectionArgs = {
    filter?: Maybe<SchoolCollectionFilter>;
    orderBy?: Maybe<SchoolCollectionOrderBy>;
    page?: Maybe<PageArgs>;
};

export type SchoolCollectionFilter = {
    email?: Maybe<Scalars['String']>;
    fullName?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    inAllLaps?: Maybe<Scalars['Boolean']>;
    lapIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
    location?: Maybe<LocationCollectionFilter>;
    number?: Maybe<Scalars['Int']>;
    phone?: Maybe<Scalars['String']>;
    profile?: Maybe<Scalars['String']>;
    responsiblePerson?: Maybe<Scalars['String']>;
    shortName?: Maybe<Scalars['String']>;
    type?: Maybe<SchoolType>;
};

export type SchoolCollectionOrderBy = {
    createdAt?: Maybe<Sort>;
    currentStudentsCount?: Maybe<Sort>;
    email?: Maybe<Sort>;
    fullName?: Maybe<Sort>;
    location?: Maybe<LocationCollectionOrderBy>;
    maxStudentsCount?: Maybe<Sort>;
    number?: Maybe<Sort>;
    phone?: Maybe<Sort>;
    profile?: Maybe<Sort>;
    responsiblePerson?: Maybe<Sort>;
    shortName?: Maybe<Sort>;
    type?: Maybe<Sort>;
};

export type SchoolSummaryStats = {
    __typename?: 'SchoolSummaryStats';
    /** Средний балл */
    averageScore?: Maybe<Scalars['Float']>;
    /** Кол-во курсантов, которые завершили обучение */
    completed?: Maybe<Scalars['Int']>;
    /** Кол-во курсантов, которые досрочно выбыли (применимо к центру) */
    out?: Maybe<Scalars['Int']>;
    /** Общее кол-во курсантов */
    total?: Maybe<Scalars['Int']>;
};

export enum SchoolType {
    college = 'college',
    gymnasium = 'gymnasium',
    lyceum = 'lyceum',
    school = 'school',
    technical = 'technical',
}

export type SocialContacs = {
    __typename?: 'SocialContacs';
    vk?: Maybe<Scalars['String']>;
};

export type SocialContacsInput = {
    vk?: Maybe<Scalars['String']>;
};

export enum Sort {
    asc = 'asc',
    desc = 'desc',
}

export type StandCompanyRatingsItem = {
    __typename?: 'StandCompanyRatingsItem';
    company?: Maybe<Scalars['Int']>;
    students?: Maybe<Array<Maybe<StandRatingsStudentItem>>>;
};

export type StandEmployeesItem = {
    __typename?: 'StandEmployeesItem';
    biography?: Maybe<Scalars['String']>;
    biographyTranslations?: Maybe<Array<Maybe<Translation>>>;
    fullName?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    photo?: Maybe<FileInfo>;
    specialization?: Maybe<Scalars['String']>;
    specializationTranslations?: Maybe<Array<Maybe<Translation>>>;
};

export type StandLessonsSchedule = {
    __typename?: 'StandLessonsSchedule';
    items?: Maybe<Array<Maybe<StandLessonsScheduleItem>>>;
    platoon?: Maybe<Scalars['Int']>;
};

export type StandLessonsScheduleItem = {
    __typename?: 'StandLessonsScheduleItem';
    end?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    place?: Maybe<Scalars['String']>;
    start?: Maybe<Scalars['String']>;
    subject?: Maybe<Scalars['String']>;
};

export type StandRatingsStudentItem = {
    __typename?: 'StandRatingsStudentItem';
    company?: Maybe<Scalars['Int']>;
    fullName?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    photo?: Maybe<FileInfo>;
    platoon?: Maybe<Scalars['Int']>;
    platoonX?: Maybe<Scalars['Int']>;
    score?: Maybe<Scalars['Int']>;
};

export type StandSummaryCompanyRatingsItem = {
    __typename?: 'StandSummaryCompanyRatingsItem';
    company?: Maybe<Scalars['Int']>;
    score?: Maybe<Scalars['Int']>;
};

export type Student = {
    __typename?: 'Student';
    accessSentAt?: Maybe<Scalars['Time']>;
    accessSentStatus?: Maybe<Scalars['String']>;
    accessSentStatusText?: Maybe<Scalars['String']>;
    address?: Maybe<Scalars['String']>;
    attachedUser?: Maybe<User>;
    badgeChipId?: Maybe<Scalars['String']>;
    birthdate?: Maybe<Scalars['Date']>;
    building?: Maybe<Building>;
    clubs?: Maybe<Scalars['String']>;
    company?: Maybe<Scalars['Int']>;
    createdAt?: Maybe<Scalars['Time']>;
    creditOfPoints?: Maybe<Array<Maybe<CreditOfPoint>>>;
    departureDate?: Maybe<Scalars['Date']>;
    email?: Maybe<Scalars['String']>;
    facultative?: Maybe<Scalars['String']>;
    fullName?: Maybe<Scalars['String']>;
    gender?: Maybe<Gender>;
    id?: Maybe<Scalars['ID']>;
    integration?: Maybe<StudentInteration>;
    interest?: Maybe<Array<Maybe<Scalars['String']>>>;
    isAccessSent?: Maybe<Scalars['Boolean']>;
    isCertificate?: Maybe<Scalars['Boolean']>;
    isCommercial?: Maybe<Scalars['Boolean']>;
    isOnline?: Maybe<Scalars['Boolean']>;
    isOut?: Maybe<Scalars['Boolean']>;
    lapAccessList?: Maybe<Array<Maybe<LapAccess>>>;
    location?: Maybe<Location>;
    olCourseCertificateUrl?: Maybe<Scalars['String']>;
    performanceManagement?: Maybe<Array<Maybe<PerformanceManagement>>>;
    phone?: Maybe<Scalars['String']>;
    photo?: Maybe<FileInfo>;
    photoId?: Maybe<Scalars['ID']>;
    platoon?: Maybe<Scalars['Int']>;
    platoonX?: Maybe<Scalars['Int']>;
    qrCodeUrl?: Maybe<Scalars['String']>;
    receptionDate?: Maybe<Scalars['Date']>;
    region?: Maybe<Region>;
    room?: Maybe<BuildingRoom>;
    roomNumber?: Maybe<Scalars['String']>;
    school?: Maybe<School>;
    schoolClass?: Maybe<Scalars['String']>;
    schoolClassProfile?: Maybe<Scalars['String']>;
    schoolLapAccessList?: Maybe<Array<Maybe<LapAccess>>>;
    socials?: Maybe<SocialContacs>;
    sportSection?: Maybe<Scalars['String']>;
    sportsCategory?: Maybe<Scalars['String']>;
    updatedAt?: Maybe<Scalars['Time']>;
};

export type StudentArgs = {
    address?: Maybe<Scalars['String']>;
    badgeChipId?: Maybe<Scalars['String']>;
    birthdate?: Maybe<Scalars['Date']>;
    buildingId?: Maybe<Scalars['ID']>;
    clubs?: Maybe<Scalars['String']>;
    company?: Maybe<Scalars['Int']>;
    creditOfPoints?: Maybe<Array<Maybe<CreditOfPoint>>>;
    departureDate?: Maybe<Scalars['Date']>;
    email?: Maybe<Scalars['String']>;
    facultative?: Maybe<Scalars['String']>;
    fullName?: Maybe<Scalars['String']>;
    gender?: Maybe<Gender>;
    interest?: Maybe<Array<Maybe<Scalars['String']>>>;
    isCertificate?: Maybe<Scalars['Boolean']>;
    isCommercial?: Maybe<Scalars['Boolean']>;
    isOut?: Maybe<Scalars['Boolean']>;
    locationId?: Maybe<Scalars['ID']>;
    performanceManagement?: Maybe<Array<Maybe<PerformanceManagement>>>;
    phone?: Maybe<Scalars['String']>;
    photoId?: Maybe<Scalars['ID']>;
    platoon?: Maybe<Scalars['Int']>;
    receptionDate?: Maybe<Scalars['Date']>;
    regionId?: Maybe<Scalars['ID']>;
    roomId?: Maybe<Scalars['ID']>;
    roomNumber?: Maybe<Scalars['String']>;
    schoolClass?: Maybe<Scalars['String']>;
    schoolClassProfile?: Maybe<Scalars['String']>;
    schoolId?: Maybe<Scalars['ID']>;
    socials?: Maybe<SocialContacsInput>;
    sportSection?: Maybe<Scalars['String']>;
    sportsCategory?: Maybe<Scalars['String']>;
};

export type StudentCollection = {
    __typename?: 'StudentCollection';
    items?: Maybe<Array<Maybe<Student>>>;
    page?: Maybe<PageReply>;
};

export type StudentCollectionArgs = {
    filter?: Maybe<StudentCollectionFilter>;
    orderBy?: Maybe<StudentCollectionOrderBy>;
    page?: Maybe<PageArgs>;
};

export type StudentCollectionFilter = {
    address?: Maybe<Scalars['String']>;
    buildingId?: Maybe<Scalars['ID']>;
    companies?: Maybe<Array<Maybe<Scalars['Int']>>>;
    company?: Maybe<Scalars['Int']>;
    eljurRole?: Maybe<Scalars['String']>;
    email?: Maybe<Scalars['String']>;
    fullName?: Maybe<Scalars['String']>;
    gender?: Maybe<Gender>;
    id?: Maybe<Scalars['ID']>;
    isCertificate?: Maybe<Scalars['Boolean']>;
    phone?: Maybe<Scalars['String']>;
    platoon?: Maybe<Scalars['Int']>;
    platoonX?: Maybe<Scalars['Int']>;
    platoons?: Maybe<Array<Maybe<Scalars['Int']>>>;
    platoonsX?: Maybe<Array<Maybe<Scalars['Int']>>>;
    roomId?: Maybe<Scalars['ID']>;
    roomNumber?: Maybe<Scalars['String']>;
    school?: Maybe<SchoolCollectionFilter>;
    schoolClass?: Maybe<Scalars['String']>;
};

export type StudentCollectionOrderBy = {
    address?: Maybe<Sort>;
    birthdate?: Maybe<Sort>;
    company?: Maybe<Sort>;
    createdAt?: Maybe<Sort>;
    email?: Maybe<Sort>;
    fullName?: Maybe<Sort>;
    gender?: Maybe<Sort>;
    phone?: Maybe<Sort>;
    platoon?: Maybe<Sort>;
    platoonX?: Maybe<Sort>;
    school?: Maybe<SchoolCollectionOrderBy>;
};

export type StudentIntegrationEljur = {
    __typename?: 'StudentIntegrationEljur';
    roles?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type StudentInteration = {
    __typename?: 'StudentInteration';
    eljur?: Maybe<StudentIntegrationEljur>;
};

export type StudentMiniInfo = {
    __typename?: 'StudentMiniInfo';
    company?: Maybe<Scalars['Int']>;
    fullName?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    isCertificate?: Maybe<Scalars['Boolean']>;
    isCommercial?: Maybe<Scalars['Boolean']>;
    isOnline?: Maybe<Scalars['Boolean']>;
    isOut?: Maybe<Scalars['Boolean']>;
    photo?: Maybe<FileInfo>;
    platoon?: Maybe<Scalars['Int']>;
    platoonX?: Maybe<Scalars['Int']>;
};

export type StudentProfile = {
    __typename?: 'StudentProfile';
    fullName?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    photo?: Maybe<FileInfo>;
    qrCodeUrl?: Maybe<Scalars['String']>;
    sections?: Maybe<Array<Maybe<StudentProfileSection>>>;
};

export type StudentProfileCounts = {
    __typename?: 'StudentProfileCounts';
    /** Баллы за учебную деятельность */
    egScore?: Maybe<Scalars['Int']>;
    /** Баллы за критерии по оцениванию */
    evaluationCriteriaScore?: Maybe<Scalars['Int']>;
    fullName?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
    /** Место в общем рейтинге */
    placeInOverallRatings?: Maybe<Scalars['Int']>;
    /** Место в рейтинге взвода */
    placeInPlatoonRatings?: Maybe<Scalars['Int']>;
};

export type StudentProfileSection = {
    __typename?: 'StudentProfileSection';
    items?: Maybe<Array<Maybe<StudentProfileSectionItem>>>;
    name?: Maybe<Scalars['String']>;
};

export type StudentProfileSectionItem = {
    __typename?: 'StudentProfileSectionItem';
    label?: Maybe<Scalars['String']>;
    link?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['String']>;
};

export type StudentTroopNumbers = {
    __typename?: 'StudentTroopNumbers';
    companies?: Maybe<Array<Maybe<Scalars['Int']>>>;
    platoons?: Maybe<Array<Maybe<Scalars['Int']>>>;
    platoonsX?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export enum StudentsAddMode {
    fullLimited = 'fullLimited',
    limited = 'limited',
    unlimited = 'unlimited',
}

export type Subscription = {
    __typename?: 'Subscription';
    /** Получение новых элементов журнала действий */
    onActionLog?: Maybe<ActionLogCollectionSection>;
    /** Получение времени для дашборда региональных центров */
    onRCDDateUpdate?: Maybe<RcdDate>;
    /** Получение агрегационных данных о центре для киоскац */
    onSDBInformationDataUpdate?: Maybe<SdbInformationData>;
    /** Получение данных по подписке об времени для киоске */
    onSDBInformationDateUpdate?: Maybe<SdbInformationDate>;
    /** Получение данных по подписке о погоде для киоска */
    onSDBInformationWeatherUpdate?: Maybe<SdbSubscriptionInformationWeather>;
    /** Получение расписания для стенда по ротам */
    onStandLessonsScheduleUpdate?: Maybe<Array<Maybe<StandLessonsSchedule>>>;
    /** Получение рейтинга курсантов (топ3) для киоска */
    onStandTopStudentsRatingsUpdate?: Maybe<Array<Maybe<StandRatingsStudentItem>>>;
};

export type SubscriptionOnActionLogArgs = {
    filter?: Maybe<ActionLogFilter>;
};

export type SubscriptionOnStandLessonsScheduleUpdateArgs = {
    company: Scalars['Int'];
};

export type SummaryLapRegionCount = {
    __typename?: 'SummaryLapRegionCount';
    count?: Maybe<Scalars['Int']>;
    regionName?: Maybe<Scalars['String']>;
};

export type SummaryLapsCourseResult = {
    __typename?: 'SummaryLapsCourseResult';
    count?: Maybe<Scalars['Int']>;
    courseName?: Maybe<Scalars['String']>;
};

export type SummaryLapsGenderStudents = {
    __typename?: 'SummaryLapsGenderStudents';
    regionName?: Maybe<Scalars['String']>;
    sections?: Maybe<Array<Maybe<SummaryLapsGenderStudentsSection>>>;
    summary?: Maybe<Scalars['Int']>;
};

export type SummaryLapsGenderStudentsItem = {
    __typename?: 'SummaryLapsGenderStudentsItem';
    age?: Maybe<Scalars['Int']>;
    count?: Maybe<Scalars['Int']>;
};

export type SummaryLapsGenderStudentsSection = {
    __typename?: 'SummaryLapsGenderStudentsSection';
    gender?: Maybe<Scalars['String']>;
    items?: Maybe<Array<Maybe<SummaryLapsGenderStudentsItem>>>;
    summary?: Maybe<Scalars['Int']>;
};

export type SummaryLapsOnlineStudentsResults = {
    __typename?: 'SummaryLapsOnlineStudentsResults';
    completedStudentsCount?: Maybe<Scalars['Int']>;
    incompletedStudentsCount?: Maybe<Scalars['Int']>;
    schoolsCount?: Maybe<Scalars['Int']>;
};

export type SummaryLapsProgramResult = {
    __typename?: 'SummaryLapsProgramResult';
    count?: Maybe<Scalars['Int']>;
    type?: Maybe<LapProgramType>;
};

export type SummaryLapsResults = {
    __typename?: 'SummaryLapsResults';
    allCount?: Maybe<Scalars['Int']>;
    regionCounts?: Maybe<Array<Maybe<SummaryLapRegionCount>>>;
};

export type SummaryLapsSchoolCollectionArgs = {
    filter?: Maybe<SummaryLapsSchoolCollectionFilter>;
    orderBy?: Maybe<SchoolCollectionOrderBy>;
    page?: Maybe<PageArgs>;
};

export type SummaryLapsSchoolCollectionFilter = {
    shortName?: Maybe<Scalars['String']>;
    summaryResults?: Maybe<SummaryResultsFilter>;
};

export type SummaryLapsStudentCollectionArgs = {
    filter?: Maybe<SummaryLapsStudentCollectionFilter>;
    orderBy?: Maybe<StudentCollectionOrderBy>;
    page?: Maybe<PageArgs>;
};

export type SummaryLapsStudentCollectionFilter = {
    fullName?: Maybe<Scalars['String']>;
    isCommercial?: Maybe<Scalars['Boolean']>;
    isCompleted?: Maybe<Scalars['Boolean']>;
    isOut?: Maybe<Scalars['Boolean']>;
    summaryResults?: Maybe<SummaryResultsFilter>;
};

export type SummaryLapsStudentsResults = {
    __typename?: 'SummaryLapsStudentsResults';
    commercialStudentsCount?: Maybe<Scalars['Int']>;
    outStudentsCount?: Maybe<Scalars['Int']>;
    passedStudentsCount?: Maybe<Scalars['Int']>;
    schoolsCount?: Maybe<Scalars['Int']>;
};

export type SummaryResultsFilter = {
    from?: Maybe<Scalars['Date']>;
    lapIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
    locationId?: Maybe<Scalars['ID']>;
    regionId?: Maybe<Scalars['ID']>;
    to?: Maybe<Scalars['Date']>;
};

export type Translation = {
    __typename?: 'Translation';
    locale?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['String']>;
};

export type TranslationInput = {
    locale?: Maybe<Scalars['String']>;
    value?: Maybe<Scalars['String']>;
};

export type UpdateFileCropReply = {
    __typename?: 'UpdateFileCropReply';
    croppedThumbnail?: Maybe<Scalars['String']>;
};

export type User = {
    __typename?: 'User';
    createdAt?: Maybe<Scalars['Time']>;
    email?: Maybe<Scalars['String']>;
    fullName?: Maybe<Scalars['String']>;
    grants?: Maybe<Array<Maybe<UserGrantItem>>>;
    id?: Maybe<Scalars['ID']>;
    photo?: Maybe<FileInfo>;
    photoId?: Maybe<Scalars['ID']>;
    roles?: Maybe<Array<Maybe<UserRole>>>;
    school?: Maybe<School>;
    student?: Maybe<Student>;
    updatedAt?: Maybe<Scalars['Time']>;
    username?: Maybe<Scalars['String']>;
};

export enum UserGrantActionToken {
    /** Общие действия */
    all = 'all',
    block = 'block',
    changeView = 'change_view',
    create = 'create',
    createAccess = 'create_access',
    createTime = 'create_time',
    daCopyDailyEvent = 'da_copy_daily_event',
    daDailyEventCud = 'da_daily_event_cud',
    daDailyTimeCud = 'da_daily_time_cud',
    /** Распорядок дня */
    daGrid = 'da_grid',
    daTimeline = 'da_timeline',
    delete = 'delete',
    deleteAccess = 'delete_access',
    deleteTime = 'delete_time',
    distribute = 'distribute',
    /** Курсанты */
    downloadTemplate = 'download_template',
    editAccess = 'edit_access',
    egCopyLesson = 'eg_copy_lesson',
    egCopyLessons = 'eg_copy_lessons',
    egCreateDisciplineScore = 'eg_create_discipline_score',
    /** Электронный журнал */
    egCreateLesson = 'eg_create_lesson',
    egCreateLessonAttendance = 'eg_create_lesson_attendance',
    egCreateLessonScore = 'eg_create_lesson_score',
    egCreateLessonScores = 'eg_create_lesson_scores',
    egCreateLessons = 'eg_create_lessons',
    egDeleteDisciplineScore = 'eg_delete_discipline_score',
    egDeleteLesson = 'eg_delete_lesson',
    egDeleteLessonScoreAndAttendance = 'eg_delete_lesson_score_and_attendance',
    egDeleteLessons = 'eg_delete_lessons',
    egExportConsolidatedSchools = 'eg_export_consolidated_schools',
    egExportSchools = 'eg_export_schools',
    egExportTimesheet = 'eg_export_timesheet',
    egMoveLesson = 'eg_move_lesson',
    egSetSettings = 'eg_set_settings',
    egUpdateDisciplineScore = 'eg_update_discipline_score',
    egUpdateLesson = 'eg_update_lesson',
    egUpdateLessonScoreAndAttendance = 'eg_update_lesson_score_and_attendance',
    egUpdateLessonScores = 'eg_update_lesson_scores',
    /** Оценивание за критерии */
    evaluationCopyResponsible = 'evaluation_copy_responsible',
    export = 'export',
    filter = 'filter',
    filterSingle = 'filter_single',
    get = 'get',
    import = 'import',
    list = 'list',
    olCreateQuestion = 'ol_create_question',
    olDeleteQuestion = 'ol_delete_question',
    olGetAnswerCorrect = 'ol_get_answer_correct',
    olGetQuestion = 'ol_get_question',
    olListQuestion = 'ol_list_question',
    /** Онлайн обучение */
    olSetCourseTestSettings = 'ol_set_course_test_settings',
    olTestProcess = 'ol_test_process',
    olUpdateQuestion = 'ol_update_question',
    pickDate = 'pick_date',
    restore = 'restore',
    search = 'search',
    sendAccess = 'send_access',
    set = 'set',
    start = 'start',
    stop = 'stop',
    update = 'update',
    updateTime = 'update_time',
    view = 'view',
    viewAccess = 'view_access',
    wikiArticleCud = 'wiki_article_cud',
    /** База знаний */
    wikiArticlesList = 'wiki_articles_list',
    wikiCategoryCud = 'wiki_category_cud',
}

export enum UserGrantCategoryToken {
    /** Категория журнал действий */
    actionLog = 'action_log',
    /** Категория дашборд центр */
    cdb = 'cdb',
    /** Категория изменения заезда */
    changeLap = 'change_lap',
    /** Категория распорядок дня */
    dailySchedule = 'daily_schedule',
    /** Категория электронный журнал */
    eg = 'eg',
    egLessonPlace = 'eg_lesson_place',
    egModule = 'eg_module',
    egSchedule = 'eg_schedule',
    egSubject = 'eg_subject',
    /** Категория сотрудники */
    employee = 'employee',
    employeeArchive = 'employee_archive',
    employeeMain = 'employee_main',
    /** Категория оценивание по критериям */
    evaluation = 'evaluation',
    evaluationCriteriaPlatoon = 'evaluation_criteria_platoon',
    evaluationCriteriaStudent = 'evaluation_criteria_student',
    evaluationPlatoon = 'evaluation_platoon',
    evaluationResponsible = 'evaluation_responsible',
    evaluationStudent = 'evaluation_student',
    /** Категория событии */
    event = 'event',
    /** Категория справочник */
    handbook = 'handbook',
    handbookSchool = 'handbook_school',
    handbookStudent = 'handbook_student',
    /** Категория заездов */
    lap = 'lap',
    /** Категория прямая трансляция */
    liveBroadcast = 'live_broadcast',
    /** Категория дашборд онлайн */
    odb = 'odb',
    /** Категория онлайн обучение */
    ol = 'ol',
    olCourse = 'ol_course',
    olCourseTesting = 'ol_course_testing',
    olSubject = 'ol_subject',
    olTesting = 'ol_testing',
    /** Общие категории */
    profile = 'profile',
    /** Категория рейтинги */
    rating = 'rating',
    /** Категория образовательные организации */
    school = 'school',
    /** Категория статистика */
    stat = 'stat',
    statLesson = 'stat_lesson',
    statStudent = 'stat_student',
    /** Категория курсанты */
    student = 'student',
    /** Категория общие результаты */
    summaryResult = 'summary_result',
    summaryResultMain = 'summary_result_main',
    summaryResultSchool = 'summary_result_school',
    summaryResultStudent = 'summary_result_student',
    summaryResultStudentCommercial = 'summary_result_student_commercial',
    summaryResultStudentComplete = 'summary_result_student_complete',
    summaryResultStudentOut = 'summary_result_student_out',
    /** Категория база знаний */
    wiki = 'wiki',
}

export type UserGrantItem = {
    __typename?: 'UserGrantItem';
    actions?: Maybe<Array<Maybe<UserGrantActionToken>>>;
    category?: Maybe<UserGrantCategoryToken>;
};

export type UserGrantsTable = {
    __typename?: 'UserGrantsTable';
    roles?: Maybe<Array<Maybe<UserRole>>>;
    rows?: Maybe<Array<Maybe<UserGrantsTableRow>>>;
};

export type UserGrantsTableItem = {
    __typename?: 'UserGrantsTableItem';
    action?: Maybe<UserGrantActionToken>;
    category?: Maybe<UserGrantCategoryToken>;
    granted?: Maybe<Scalars['Boolean']>;
    id?: Maybe<Scalars['ID']>;
};

export type UserGrantsTableItemArgs = {
    action: UserGrantActionToken;
    category: UserGrantCategoryToken;
    granted: Scalars['Boolean'];
    id: Scalars['ID'];
};

export type UserGrantsTableRow = {
    __typename?: 'UserGrantsTableRow';
    categoryId?: Maybe<Scalars['ID']>;
    grants?: Maybe<Array<Maybe<UserGrantsTableItem>>>;
    isAction?: Maybe<Scalars['Boolean']>;
    isCategory?: Maybe<Scalars['Boolean']>;
    key?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    parentCategoryId?: Maybe<Scalars['ID']>;
};

export enum UserRole {
    /** Роль администратора */
    admin = 'admin',
    /** Роль инструктора */
    instructor = 'instructor',
    /** Роль вожатого */
    juniorLeader = 'junior_leader',
    /** Роль старшего вожатого */
    leader = 'leader',
    /** Роль воспитателя */
    mentor = 'mentor',
    /** Роль методиста */
    methodist = 'methodist',
    /** Роль "Педагог-организатор" */
    organizingTeacher = 'organizing_teacher',
    /** Роль психолога */
    psychologist = 'psychologist',
    /** Роль регистратора */
    registrar = 'registrar',
    /** Роль образовательного учреждения */
    school = 'school',
    /** Роль старшего инструктора */
    seniorInstructor = 'senior_instructor',
    /** Роль курсанта в системе */
    student = 'student',
    /** Администратор региональных центров */
    superAdmin = 'super_admin',
}

/**
 * Название видео материала с превью
 * и ID загруженного файла
 *
 * Файл загружается POST запросом по `{api_url}/files`
 */
export type VideoFileDoc = {
    __typename?: 'VideoFileDoc';
    file?: Maybe<FileInfo>;
    fileId?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    previewFile?: Maybe<FileInfo>;
    previewFileId?: Maybe<Scalars['ID']>;
    previewName?: Maybe<Scalars['String']>;
};

/**
 * Название видео материала с превью
 * и ID загруженного файла
 *
 * Файл загружается POST запросом по `{api_url}/files`
 */
export type VideoFileDocArg = {
    fileId?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    previewFileId?: Maybe<Scalars['ID']>;
    previewName?: Maybe<Scalars['String']>;
};

export enum WeatherConditionTypes {
    /** Ясно */
    clear = 'clear',
    /** Облачно с прояснениями */
    cloudy = 'cloudy',
    /** Длительный сильный дождь */
    continuousHeavyRain = 'continuous_heavy_rain',
    /** Морось */
    drizzle = 'drizzle',
    /** Град */
    hail = 'hail',
    /** Сильный дождь */
    heavyRain = 'heavy_rain',
    /** Небольшой дождь */
    lightRain = 'light_rain',
    /** Небольшой снег */
    lightSnow = 'light_snow',
    /** Умеренно сильный дождь */
    moderateRain = 'moderate_rain',
    /** Пасмурно */
    overcast = 'overcast',
    /** Малооблачно */
    partlyCloudy = 'partly_cloudy',
    /** Малооблачно */
    partyCloudy = 'party_cloudy',
    /** Дождь */
    rain = 'rain',
    /** Ливень */
    showers = 'showers',
    /** Cнег */
    snow = 'snow',
    /** Cнегопад */
    snowShowers = 'snow_showers',
    /** Гроза */
    thunderstorm = 'thunderstorm',
    /** Гроза с градом */
    thunderstormWithHail = 'thunderstorm_with_hail',
    /** Дождь с грозой */
    thunderstormWithRain = 'thunderstorm_with_rain',
    /** Дождь со снегом */
    wetSnow = 'wet_snow',
}

export type WikiArticle = {
    __typename?: 'WikiArticle';
    id?: Maybe<Scalars['ID']>;
    mainImageUrl?: Maybe<Scalars['String']>;
    text?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
};

export type WikiCollection = {
    __typename?: 'WikiCollection';
    items?: Maybe<Array<Maybe<WikiCollectionItem>>>;
    page?: Maybe<PageReply>;
};

export type WikiCollectionArgs = {
    page?: Maybe<PageArgs>;
    search: Scalars['String'];
};

export type WikiCollectionItem = {
    __typename?: 'WikiCollectionItem';
    id?: Maybe<Scalars['ID']>;
    snippet?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
    titleSnippet?: Maybe<Scalars['String']>;
};

export type _Service = {
    __typename?: '_Service';
    sdl: Scalars['String'];
};

export type ActionLogFragment = { __typename?: 'ActionLog' } & Pick<
    ActionLog,
    'id' | 'at' | 'lapId' | 'userId' | 'platform' | 'description'
> & {
        lap?: Maybe<{ __typename?: 'Lap' } & LapFragment>;
        user?: Maybe<
            { __typename?: 'User' } & Pick<
                User,
                | 'id'
                | 'photoId'
                | 'fullName'
                | 'email'
                | 'username'
                | 'roles'
                | 'createdAt'
                | 'updatedAt'
            > & {
                    photo?: Maybe<{ __typename?: 'FileInfo' } & FileInfoFragment>;
                    student?: Maybe<{ __typename?: 'Student' } & StudentSingleFragment>;
                    school?: Maybe<{ __typename?: 'School' } & SchoolSingleFragment>;
                }
        >;
        values?: Maybe<
            Array<
                Maybe<
                    { __typename?: 'ActionLogItemValue' } & Pick<
                        ActionLogItemValue,
                        'label' | 'oldValue' | 'newValue'
                    >
                >
            >
        >;
    };

export type GetActionLogCollectionQueryVariables = Exact<{
    args?: Maybe<ActionLogCollectionArgs>;
}>;

export type GetActionLogCollectionQueryType = { __typename?: 'Query' } & {
    getActionLogCollection?: Maybe<
        { __typename?: 'ActionLogCollection' } & {
            sections?: Maybe<
                Array<
                    Maybe<
                        { __typename?: 'ActionLogCollectionSection' } & Pick<
                            ActionLogCollectionSection,
                            'date'
                        > & {
                                items?: Maybe<
                                    Array<Maybe<{ __typename?: 'ActionLog' } & ActionLogFragment>>
                                >;
                            }
                    >
                >
            >;
            page?: Maybe<
                { __typename?: 'ActionLogCollectionPage' } & Pick<
                    ActionLogCollectionPage,
                    'hasNext' | 'nextPage' | 'hasPrev' | 'prevPage'
                >
            >;
        }
    >;
};

export type OnActionLogSubscriptionVariables = Exact<{
    filter?: Maybe<ActionLogFilter>;
}>;

export type OnActionLogSubscriptionType = { __typename?: 'Subscription' } & {
    onActionLog?: Maybe<
        { __typename?: 'ActionLogCollectionSection' } & Pick<ActionLogCollectionSection, 'date'> & {
                items?: Maybe<Array<Maybe<{ __typename?: 'ActionLog' } & ActionLogFragment>>>;
            }
    >;
};

export type LoginReplyFragment = { __typename?: 'LoginReply' } & Pick<
    LoginReply,
    'accessToken' | 'refreshToken'
>;

export type ChangePasswordMutationVariables = Exact<{
    old: Scalars['String'];
    new: Scalars['String'];
}>;

export type ChangePasswordMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'changePassword'
>;

export type RefreshTokenMutationVariables = Exact<{
    refreshToken: Scalars['String'];
}>;

export type RefreshTokenMutationType = { __typename?: 'Mutation' } & {
    refreshToken?: Maybe<{ __typename?: 'LoginReply' } & LoginReplyFragment>;
};

export type RevokeTokenMutationVariables = Exact<{
    refreshToken: Scalars['String'];
}>;

export type RevokeTokenMutationType = { __typename?: 'Mutation' } & Pick<Mutation, 'revokeToken'>;

export type GetJivositeInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetJivositeInfoQueryType = { __typename?: 'Query' } & {
    getJivositeInfo?: Maybe<
        { __typename?: 'JivositeInfo' } & Pick<JivositeInfo, 'userToken'> & {
                contactInfo: { __typename?: 'JivositeInfoContact' } & Pick<
                    JivositeInfoContact,
                    'name' | 'phone' | 'email'
                >;
                customData: Array<
                    { __typename?: 'JivositeInfoCustom' } & Pick<
                        JivositeInfoCustom,
                        'title' | 'content'
                    >
                >;
            }
    >;
};

export type HasAccessForPlatformQueryVariables = Exact<{ [key: string]: never }>;

export type HasAccessForPlatformQueryType = { __typename?: 'Query' } & Pick<
    Query,
    'hasAccessForPlatform'
>;

export type CourseCollectionFragment = { __typename?: 'OLCourse' } & Pick<
    OlCourse,
    'id' | 'name' | 'testsCount' | 'subjectsCount' | 'isAvailable' | 'certificateUrl'
>;

export type GetCourseCollectionQueryVariables = Exact<{
    args?: Maybe<OlCourseCollectionArgs>;
}>;

export type GetCourseCollectionQueryType = { __typename?: 'Query' } & {
    getOLCourseCollection?: Maybe<
        { __typename?: 'OLCourseCollection' } & {
            items?: Maybe<Array<Maybe<{ __typename?: 'OLCourse' } & CourseCollectionFragment>>>;
            page?: Maybe<{ __typename?: 'PageReply' } & PageReplyFragment>;
        }
    >;
};

export type GetCourseQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetCourseQueryType = { __typename?: 'Query' } & {
    getOLCourse?: Maybe<
        { __typename?: 'OLCourse' } & Pick<OlCourse, 'id' | 'name' | 'certificateUrl'> & {
                test?: Maybe<
                    { __typename?: 'OLCourseTest' } & Pick<
                        OlCourseTest,
                        'questionsCount' | 'duration'
                    >
                >;
            }
    >;
};

export type GetCoursesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCoursesQueryType = { __typename?: 'Query' } & {
    getOLCourses?: Maybe<Array<Maybe<{ __typename?: 'OLCourse' } & CourseCollectionFragment>>>;
};

export type GetEgLessonsTimelineQueryVariables = Exact<{
    args: EgLessonsTimelineArgs;
}>;

export type GetEgLessonsTimelineQueryType = { __typename?: 'Query' } & {
    getEGLessonsTimeline?: Maybe<
        { __typename?: 'EGLessonsTimeline' } & {
            items?: Maybe<
                Array<
                    Maybe<
                        { __typename?: 'EGLessonsTimelineItem' } & Pick<
                            EgLessonsTimelineItem,
                            | 'id'
                            | 'timeRange'
                            | 'duration'
                            | 'type'
                            | 'name'
                            | 'instructors'
                            | 'place'
                            | 'status'
                        >
                    >
                >
            >;
        }
    >;
};

export type GetCdbCountsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCdbCountsQueryType = { __typename?: 'Query' } & {
    getCDBCounts?: Maybe<
        { __typename?: 'CDBCounts' } & Pick<
            CdbCounts,
            'students' | 'onlineStudents' | 'schools'
        > & {
                date?: Maybe<
                    { __typename?: 'CDBCountsDate' } & Pick<CdbCountsDate, 'date' | 'time'>
                >;
                weather?: Maybe<
                    { __typename?: 'CDBCountsWeather' } & Pick<
                        CdbCountsWeather,
                        'temp' | 'icon' | 'condition'
                    >
                >;
            }
    >;
};

export type GetCdbLessonsScheduleQueryVariables = Exact<{
    platoonX: Scalars['Int'];
}>;

export type GetCdbLessonsScheduleQueryType = { __typename?: 'Query' } & {
    getCDBLessonsSchedule?: Maybe<
        Array<
            Maybe<
                { __typename?: 'CDBLessonSchedule' } & Pick<
                    CdbLessonSchedule,
                    | 'id'
                    | 'subject'
                    | 'subjectColor'
                    | 'place'
                    | 'instructors'
                    | 'current'
                    | 'future'
                    | 'start'
                    | 'end'
                >
            >
        >
    >;
};

export type GetCdbPlatoonsRatingCollectionQueryVariables = Exact<{ [key: string]: never }>;

export type GetCdbPlatoonsRatingCollectionQueryType = { __typename?: 'Query' } & {
    getCDBPlatoonsRatingCollection?: Maybe<
        { __typename?: 'CDBPlatoonsRatingCollection' } & Pick<
            CdbPlatoonsRatingCollection,
            'count'
        > & {
                items?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'CDBPlatoonsRatingCollectionItem' } & Pick<
                                CdbPlatoonsRatingCollectionItem,
                                'company' | 'platoon' | 'platoonX' | 'score'
                            >
                        >
                    >
                >;
            }
    >;
};

export type GetCdbRatingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCdbRatingsQueryType = { __typename?: 'Query' } & {
    getCDBRatings?: Maybe<
        { __typename?: 'CDBRatings' } & {
            companies?: Maybe<
                Array<
                    Maybe<
                        { __typename?: 'CDBCompanyRating' } & Pick<
                            CdbCompanyRating,
                            'company' | 'score'
                        >
                    >
                >
            >;
            platoons?: Maybe<
                Array<
                    Maybe<
                        { __typename?: 'CDBPlatoonRating' } & Pick<
                            CdbPlatoonRating,
                            'company' | 'platoonX' | 'score'
                        >
                    >
                >
            >;
        }
    >;
};

export type GetCdbStudentsRatingCollectionQueryVariables = Exact<{
    args?: Maybe<CdbStudentsRatingCollectionArgs>;
}>;

export type GetCdbStudentsRatingCollectionQueryType = { __typename?: 'Query' } & {
    getCDBStudentsRatingCollection?: Maybe<
        { __typename?: 'CDBStudentsRatingCollection' } & {
            items?: Maybe<
                Array<
                    Maybe<
                        { __typename?: 'CDBStudentsRatingCollectionItem' } & Pick<
                            CdbStudentsRatingCollectionItem,
                            | 'id'
                            | 'place'
                            | 'fullName'
                            | 'company'
                            | 'platoon'
                            | 'platoonX'
                            | 'location'
                            | 'shortName'
                            | 'isCommercial'
                            | 'isOut'
                            | 'isOnline'
                            | 'score'
                            | 'egScore'
                            | 'evaluationScore'
                        > & { photo?: Maybe<{ __typename?: 'FileInfo' } & FileInfoFragment> }
                    >
                >
            >;
            page?: Maybe<{ __typename?: 'PageReply' } & PageReplyFragment>;
        }
    >;
};

export type LessonPlaceCollectionFragment = { __typename?: 'EGLessonPlaceCollection' } & {
    items?: Maybe<Array<Maybe<{ __typename?: 'EGLessonPlace' } & LessonPlaceSingleFragment>>>;
    page?: Maybe<{ __typename?: 'PageReply' } & PageReplyFragment>;
};

export type LessonPlaceSingleFragment = { __typename?: 'EGLessonPlace' } & Pick<
    EgLessonPlace,
    'id' | 'name' | 'shortName'
>;

export type CreateLessonPlaceMutationVariables = Exact<{
    item: EgLessonPlaceArgs;
}>;

export type CreateLessonPlaceMutationType = { __typename?: 'Mutation' } & {
    createEGLessonPlace?: Maybe<{ __typename?: 'EGLessonPlace' } & LessonPlaceSingleFragment>;
};

export type RemoveLessonPlaceMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type RemoveLessonPlaceMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'removeEGLessonPlace'
>;

export type UpdateLessonPlaceMutationVariables = Exact<{
    id: Scalars['ID'];
    item: EgLessonPlaceArgs;
}>;

export type UpdateLessonPlaceMutationType = { __typename?: 'Mutation' } & {
    updateEGLessonPlace?: Maybe<{ __typename?: 'EGLessonPlace' } & LessonPlaceSingleFragment>;
};

export type GetEgLessonPlaceCollectionQueryVariables = Exact<{
    args?: Maybe<EgLessonPlaceCollectionArgs>;
}>;

export type GetEgLessonPlaceCollectionQueryType = { __typename?: 'Query' } & {
    getEGLessonPlaceCollection?: Maybe<
        { __typename?: 'EGLessonPlaceCollection' } & LessonPlaceCollectionFragment
    >;
};

export type GetEgLessonPlaceQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetEgLessonPlaceQueryType = { __typename?: 'Query' } & {
    getEGLessonPlace?: Maybe<{ __typename?: 'EGLessonPlace' } & LessonPlaceSingleFragment>;
};

export type GetEgLessonPlacesQueryVariables = Exact<{ [key: string]: never }>;

export type GetEgLessonPlacesQueryType = { __typename?: 'Query' } & {
    getEGLessonPlaces?: Maybe<
        Array<Maybe<{ __typename?: 'EGLessonPlace' } & LessonPlaceSingleFragment>>
    >;
};

export type EgModuleCollectionFragment = { __typename?: 'EGModuleCollection' } & {
    items?: Maybe<Array<Maybe<{ __typename?: 'EGModule' } & EgModuleSingleFragment>>>;
    page?: Maybe<{ __typename?: 'PageReply' } & PageReplyFragment>;
};

export type EgModuleSingleFragment = { __typename?: 'EGModule' } & Pick<EgModule, 'id' | 'name'> & {
        subjects?: Maybe<Array<Maybe<{ __typename?: 'EGSubject' } & SubjectSingleFragment>>>;
    };

export type CreateEgModuleMutationVariables = Exact<{
    item: EgModuleCreateArgs;
}>;

export type CreateEgModuleMutationType = { __typename?: 'Mutation' } & {
    createEGModule?: Maybe<{ __typename?: 'EGModule' } & EgModuleSingleFragment>;
};

export type RemoveEgModuleMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type RemoveEgModuleMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'removeEGModule'
>;

export type UpdateEgModuleMutationVariables = Exact<{
    id: Scalars['ID'];
    item: EgModuleUpdateArgs;
}>;

export type UpdateEgModuleMutationType = { __typename?: 'Mutation' } & {
    updateEGModule?: Maybe<{ __typename?: 'EGModule' } & EgModuleSingleFragment>;
};

export type GetEgModuleCollectionQueryVariables = Exact<{
    args?: Maybe<EgModuleCollectionArgs>;
}>;

export type GetEgModuleCollectionQueryType = { __typename?: 'Query' } & {
    getEGModuleCollection?: Maybe<
        { __typename?: 'EGModuleCollection' } & EgModuleCollectionFragment
    >;
};

export type GetEgModuleQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetEgModuleQueryType = { __typename?: 'Query' } & {
    getEGModule?: Maybe<{ __typename?: 'EGModule' } & EgModuleSingleFragment>;
};

export type GetEgModulesQueryVariables = Exact<{ [key: string]: never }>;

export type GetEgModulesQueryType = { __typename?: 'Query' } & {
    getEGModules?: Maybe<Array<Maybe<{ __typename?: 'EGModule' } & EgModuleSingleFragment>>>;
};

export type EgLessonsListItemFragment = { __typename?: 'EGLessonsListItem' } & Pick<
    EgLessonsListItem,
    | 'timeRange'
    | 'company'
    | 'platoon'
    | 'platoonX'
    | 'id'
    | 'name'
    | 'color'
    | 'instructors'
    | 'place'
    | 'scoresCount'
    | 'studentsCount'
    | 'averageScore'
>;

export type LessonDisciplineScoreFragment = { __typename?: 'EGLessonDisciplineScore' } & Pick<
    EgLessonDisciplineScore,
    'id' | 'lessonId' | 'score' | 'createdAt' | 'updatedAt'
>;

export type LessonScoreCollectionItemFragment = {
    __typename?: 'EGLessonScoreCollectionItem';
} & Pick<
    EgLessonScoreCollectionItem,
    'locationName' | 'schoolType' | 'schoolName' | 'score' | 'attendance'
> & { student?: Maybe<{ __typename?: 'StudentMiniInfo' } & StudentMiniInfoSingleFragment> };

export type LessonScoreCollectionSchoolItemFragment = {
    __typename?: 'EGLessonScoreCollectionSchoolItem';
} & Pick<
    EgLessonScoreCollectionSchoolItem,
    | 'fullName'
    | 'company'
    | 'platoon'
    | 'platoonX'
    | 'time'
    | 'subject'
    | 'subjectColor'
    | 'score'
    | 'attendance'
> & {
        instructors?: Maybe<
            Array<Maybe<{ __typename?: 'Employee' } & Pick<Employee, 'id' | 'fullName'>>>
        >;
        topic?: Maybe<{ __typename?: 'EGTopic' } & Pick<EgTopic, 'id' | 'name'>>;
    };

export type LessonScoreFragment = { __typename?: 'EGLessonScore' } & Pick<
    EgLessonScore,
    'id' | 'studentId' | 'score' | 'attendance'
>;

export type LessonTimeFragment = { __typename?: 'EGLessonTime' } & Pick<
    EgLessonTime,
    'id' | 'start' | 'end' | 'date' | 'type'
>;

export type LessonFragment = { __typename?: 'EGLesson' } & Pick<
    EgLesson,
    'id' | 'company' | 'platoon' | 'name' | 'placeName'
> & {
        subject?: Maybe<{ __typename?: 'EGSubject' } & SubjectSingleFragment>;
        topic?: Maybe<{ __typename?: 'EGTopic' } & TopicSingleFragment>;
        instructor?: Maybe<{ __typename?: 'Employee' } & EmployeeSingleFragment>;
        instructors?: Maybe<Array<Maybe<{ __typename?: 'Employee' } & EmployeeSingleFragment>>>;
        place?: Maybe<{ __typename?: 'EGLessonPlace' } & LessonPlaceSingleFragment>;
        time?: Maybe<{ __typename?: 'EGLessonTime' } & LessonTimeFragment>;
    };

export type LessonsStudentItemFragment = { __typename?: 'EGLessonsStudentItem' } & Pick<
    EgLessonsStudentItem,
    'time' | 'subject' | 'subjectColor' | 'place' | 'score' | 'attendance'
> & {
        instructors?: Maybe<
            Array<Maybe<{ __typename?: 'Employee' } & Pick<Employee, 'id' | 'fullName'>>>
        >;
        topic?: Maybe<{ __typename?: 'EGTopic' } & Pick<EgTopic, 'id' | 'name'>>;
    };

export type LessonsTableColumnFragment = { __typename?: 'EGLessonsTableCol' } & Pick<
    EgLessonsTableCol,
    | 'id'
    | 'type'
    | 'index'
    | 'name'
    | 'color'
    | 'instructors'
    | 'instructor'
    | 'instructorId'
    | 'place'
    | 'timeRange'
    | 'studentsCount'
    | 'scoresCount'
    | 'averageScore'
>;

export type LessonsTableRowFragment = { __typename?: 'EGLessonsTableRow' } & Pick<
    EgLessonsTableRow,
    'company' | 'platoon' | 'platoonX'
> & {
        cols?: Maybe<
            Array<Maybe<{ __typename?: 'EGLessonsTableCol' } & LessonsTableColumnFragment>>
        >;
    };

export type CopyEgLessonMutationVariables = Exact<{
    id: Scalars['ID'];
    params: Array<EgLessonCopyParam> | EgLessonCopyParam;
}>;

export type CopyEgLessonMutationType = { __typename?: 'Mutation' } & Pick<Mutation, 'copyEGLesson'>;

export type CopyEgLessonsMutationVariables = Exact<{
    params: EgLessonsCopyParams;
}>;

export type CopyEgLessonsMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'copyEGLessons'
>;

export type CreateLessonTimeMutationVariables = Exact<{
    item: EgLessonTimeArgs;
}>;

export type CreateLessonTimeMutationType = { __typename?: 'Mutation' } & {
    createEGLessonTime?: Maybe<{ __typename?: 'EGLessonTime' } & LessonTimeFragment>;
};

export type CreateLessonMutationVariables = Exact<{
    item: EgLessonArgs;
}>;

export type CreateLessonMutationType = { __typename?: 'Mutation' } & {
    createEGLesson?: Maybe<{ __typename?: 'EGLesson' } & LessonFragment>;
};

export type CreateMoreLessonsMutationVariables = Exact<{
    items: Array<EgLessonArgs> | EgLessonArgs;
}>;

export type CreateMoreLessonsMutationType = { __typename?: 'Mutation' } & {
    createMoreEGLessons?: Maybe<Array<Maybe<{ __typename?: 'EGLesson' } & Pick<EgLesson, 'id'>>>>;
};

export type MoveEgLessonMutationVariables = Exact<{
    id: Scalars['ID'];
    params: EgLessonMoveParams;
}>;

export type MoveEgLessonMutationType = { __typename?: 'Mutation' } & Pick<Mutation, 'moveEGLesson'>;

export type RemoveLessonTimeMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type RemoveLessonTimeMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'removeEGLessonTime'
>;

export type RemoveLessonMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type RemoveLessonMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'removeEGLesson'
>;

export type RemoveMoreEgLessonsMutationVariables = Exact<{
    ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type RemoveMoreEgLessonsMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'removeMoreEGLessons'
>;

export type SetEgLessonScoreAllMutationVariables = Exact<{
    lessonId: Scalars['ID'];
    score: Scalars['Int'];
    attendance?: Maybe<EgAttendance>;
}>;

export type SetEgLessonScoreAllMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'setEGLessonScoreAll'
>;

export type SetEgLessonDisciplineScoreMutationVariables = Exact<{
    lessonId: Scalars['ID'];
    score: Scalars['Int'];
}>;

export type SetEgLessonDisciplineScoreMutationType = { __typename?: 'Mutation' } & {
    setEGLessonDisciplineScore?: Maybe<
        { __typename?: 'EGLessonDisciplineScore' } & LessonDisciplineScoreFragment
    >;
};

export type SetLessonScoreMutationVariables = Exact<{
    lessonId: Scalars['ID'];
    item: EgLessonScoreArgs;
}>;

export type SetLessonScoreMutationType = { __typename?: 'Mutation' } & {
    setEGLessonScore?: Maybe<{ __typename?: 'EGLessonScore' } & LessonScoreFragment>;
};

export type SetMoreEgLessonScoresMutationVariables = Exact<{
    lessonId: Scalars['ID'];
    items: Array<EgLessonScoreArgs> | EgLessonScoreArgs;
}>;

export type SetMoreEgLessonScoresMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'setMoreEGLessonScores'
>;

export type UpdateLessonTimeMutationVariables = Exact<{
    id: Scalars['ID'];
    item: EgLessonTimeArgs;
}>;

export type UpdateLessonTimeMutationType = { __typename?: 'Mutation' } & {
    updateEGLessonTime?: Maybe<{ __typename?: 'EGLessonTime' } & LessonTimeFragment>;
};

export type UpdateLessonMutationVariables = Exact<{
    id: Scalars['ID'];
    item: EgLessonArgs;
}>;

export type UpdateLessonMutationType = { __typename?: 'Mutation' } & {
    updateEGLesson?: Maybe<{ __typename?: 'EGLesson' } & LessonFragment>;
};

export type GetEgExportSchoolsUrlQueryVariables = Exact<{
    locationId: Scalars['ID'];
    modules: Array<EgExportSchoolsModule> | EgExportSchoolsModule;
    schoolId?: Maybe<Scalars['ID']>;
}>;

export type GetEgExportSchoolsUrlQueryType = { __typename?: 'Query' } & Pick<
    Query,
    'getEGExportSchoolsUrl'
>;

export type GetEgExportThroughoutLapUrlQueryVariables = Exact<{
    modules: Array<EgExportSchoolsModule> | EgExportSchoolsModule;
}>;

export type GetEgExportThroughoutLapUrlQueryType = { __typename?: 'Query' } & Pick<
    Query,
    'getEGExportThroughoutLapUrl'
>;

export type GetEgExportTimesheetUrlQueryVariables = Exact<{
    company?: Maybe<Scalars['Int']>;
    platoon?: Maybe<Scalars['Int']>;
    platoonX?: Maybe<Scalars['Int']>;
}>;

export type GetEgExportTimesheetUrlQueryType = { __typename?: 'Query' } & Pick<
    Query,
    'getEGExportTimesheetUrl'
>;

export type GetEgLessonsListQueryVariables = Exact<{
    args: EgLessonsListArgs;
}>;

export type GetEgLessonsListQueryType = { __typename?: 'Query' } & {
    getEGLessonsList?: Maybe<
        { __typename?: 'EGLessonsList' } & {
            timeList?: Maybe<Array<Maybe<{ __typename?: 'EGLessonTime' } & LessonTimeFragment>>>;
            items?: Maybe<
                Array<Maybe<{ __typename?: 'EGLessonsListItem' } & EgLessonsListItemFragment>>
            >;
            page?: Maybe<{ __typename?: 'PageReply' } & PageReplyFragment>;
        }
    >;
};

export type GetEgLessonDisciplineScoreQueryVariables = Exact<{
    lessonId: Scalars['ID'];
}>;

export type GetEgLessonDisciplineScoreQueryType = { __typename?: 'Query' } & {
    getEGLessonDisciplineScore?: Maybe<
        { __typename?: 'EGLessonDisciplineScore' } & LessonDisciplineScoreFragment
    >;
};

export type GetLessonScoreCollectionSchoolQueryVariables = Exact<{
    args?: Maybe<EgLessonScoreCollectionSchoolArgs>;
}>;

export type GetLessonScoreCollectionSchoolQueryType = { __typename?: 'Query' } & {
    getEGLessonScoreCollectionSchool?: Maybe<
        { __typename?: 'EGLessonScoreCollectionSchool' } & {
            items?: Maybe<
                Array<
                    Maybe<
                        {
                            __typename?: 'EGLessonScoreCollectionSchoolItem';
                        } & LessonScoreCollectionSchoolItemFragment
                    >
                >
            >;
            page?: Maybe<{ __typename?: 'PageReply' } & PageReplyFragment>;
        }
    >;
};

export type GetLessonScoreCollectionQueryVariables = Exact<{
    args?: Maybe<EgLessonScoreCollectionArgs>;
}>;

export type GetLessonScoreCollectionQueryType = { __typename?: 'Query' } & {
    getEGLessonScoreCollection?: Maybe<
        { __typename?: 'EGLessonScoreCollection' } & Pick<
            EgLessonScoreCollection,
            | 'company'
            | 'platoon'
            | 'platoonX'
            | 'lessonDate'
            | 'lessonTime'
            | 'lessonIndex'
            | 'lessonPlace'
        > & {
                instructors?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'Employee' } & Pick<Employee, 'id' | 'fullName'> & {
                                    attachedUser?: Maybe<
                                        { __typename?: 'User' } & Pick<User, 'id' | 'username'>
                                    >;
                                }
                        >
                    >
                >;
                items?: Maybe<
                    Array<
                        Maybe<
                            {
                                __typename?: 'EGLessonScoreCollectionItem';
                            } & LessonScoreCollectionItemFragment
                        >
                    >
                >;
                page?: Maybe<{ __typename?: 'PageReply' } & PageReplyFragment>;
            }
    >;
};

export type GetLessonTimeListQueryVariables = Exact<{
    args: EgLessonTimeListArgs;
}>;

export type GetLessonTimeListQueryType = { __typename?: 'Query' } & {
    getEGLessonTimeList?: Maybe<Array<Maybe<{ __typename?: 'EGLessonTime' } & LessonTimeFragment>>>;
};

export type GetLessonQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetLessonQueryType = { __typename?: 'Query' } & {
    getEGLesson?: Maybe<{ __typename?: 'EGLesson' } & LessonFragment>;
};

export type GetLessonsStudentQueryVariables = Exact<{
    args?: Maybe<EgLessonsStudentArgs>;
}>;

export type GetLessonsStudentQueryType = { __typename?: 'Query' } & {
    getEGLessonsStudent?: Maybe<
        Array<Maybe<{ __typename?: 'EGLessonsStudentItem' } & LessonsStudentItemFragment>>
    >;
};

export type GetLessonsTableQueryVariables = Exact<{
    args: EgLessonsTableArgs;
}>;

export type GetLessonsTableQueryType = { __typename?: 'Query' } & {
    getEGLessonsTable?: Maybe<
        { __typename?: 'EGLessonsTable' } & {
            timeList?: Maybe<Array<Maybe<{ __typename?: 'EGLessonTime' } & LessonTimeFragment>>>;
            rows?: Maybe<
                Array<Maybe<{ __typename?: 'EGLessonsTableRow' } & LessonsTableRowFragment>>
            >;
        }
    >;
};

export type SubjectCollectionFragment = { __typename?: 'EGSubjectCollection' } & {
    items?: Maybe<Array<Maybe<{ __typename?: 'EGSubject' } & SubjectSingleFragment>>>;
    page?: Maybe<{ __typename?: 'PageReply' } & PageReplyFragment>;
};

export type SubjectSingleFragment = { __typename?: 'EGSubject' } & Pick<
    EgSubject,
    'id' | 'name' | 'shortName' | 'description' | 'createdAt' | 'moduleId'
> & {
        color?: Maybe<{ __typename?: 'EGSubjectColor' } & Pick<EgSubjectColor, 'name' | 'value'>>;
        topics?: Maybe<Array<Maybe<{ __typename?: 'EGTopic' } & Pick<EgTopic, 'id' | 'name'>>>>;
    };

export type TopicSingleFragment = { __typename?: 'EGTopic' } & Pick<EgTopic, 'id' | 'name'>;

export type CreateSubjectMutationVariables = Exact<{
    item: EgSubjectCreateArgs;
}>;

export type CreateSubjectMutationType = { __typename?: 'Mutation' } & {
    createEGSubject?: Maybe<{ __typename?: 'EGSubject' } & SubjectSingleFragment>;
};

export type RemoveSubjectMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type RemoveSubjectMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'removeEGSubject'
>;

export type UpdateSubjectMutationVariables = Exact<{
    id: Scalars['ID'];
    item: EgSubjectUpdateArgs;
}>;

export type UpdateSubjectMutationType = { __typename?: 'Mutation' } & {
    updateEGSubject?: Maybe<{ __typename?: 'EGSubject' } & SubjectSingleFragment>;
};

export type GetEgSubjectCollectionQueryVariables = Exact<{
    args?: Maybe<EgSubjectCollectionArgs>;
}>;

export type GetEgSubjectCollectionQueryType = { __typename?: 'Query' } & {
    getEGSubjectCollection?: Maybe<
        { __typename?: 'EGSubjectCollection' } & SubjectCollectionFragment
    >;
};

export type GetEgSubjectsQueryVariables = Exact<{ [key: string]: never }>;

export type GetEgSubjectsQueryType = { __typename?: 'Query' } & {
    getEGSubjects?: Maybe<Array<Maybe<{ __typename?: 'EGSubject' } & SubjectSingleFragment>>>;
};

export type GetSubjectQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetSubjectQueryType = { __typename?: 'Query' } & {
    getEGSubject?: Maybe<{ __typename?: 'EGSubject' } & SubjectSingleFragment>;
};

export type EmployeeCollectionFragment = { __typename?: 'Employee' } & Pick<
    Employee,
    | 'id'
    | 'fullName'
    | 'role'
    | 'phone'
    | 'email'
    | 'birthdate'
    | 'accessSentStatusText'
    | 'accessSentStatus'
    | 'isArchived'
> & { photo?: Maybe<{ __typename?: 'FileInfo' } & Pick<FileInfo, 'croppedThumbnail'>> };

export type EmployeeSingleFragment = { __typename?: 'Employee' } & Pick<
    Employee,
    | 'accessSentAt'
    | 'isAccessSent'
    | 'isBlocked'
    | 'qrCodeUrl'
    | 'displayInStand'
    | 'photoId'
    | 'specialization'
    | 'biography'
    | 'createdAt'
> & {
        specializationTranslations?: Maybe<
            Array<Maybe<{ __typename?: 'Translation' } & TranslationFragment>>
        >;
        biographyTranslations?: Maybe<
            Array<Maybe<{ __typename?: 'Translation' } & TranslationFragment>>
        >;
        photo?: Maybe<{ __typename?: 'FileInfo' } & FileInfoFragment>;
        attachedUser?: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'username'>>;
    } & EmployeeCollectionFragment;

export type ArchiveTheEmployeeMutationVariables = Exact<{
    id: Scalars['ID'];
    archive: Scalars['Boolean'];
}>;

export type ArchiveTheEmployeeMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'archiveTheEmployee'
>;

export type CreateEmployeeMutationVariables = Exact<{
    item: EmployeeArgs;
}>;

export type CreateEmployeeMutationType = { __typename?: 'Mutation' } & {
    createEmployee?: Maybe<{ __typename?: 'Employee' } & EmployeeSingleFragment>;
};

export type SendEmployeeAccessMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type SendEmployeeAccessMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'sendEmployeeAccess'
>;

export type UpdateEmployeeMutationVariables = Exact<{
    id: Scalars['ID'];
    item: EmployeeArgs;
}>;

export type UpdateEmployeeMutationType = { __typename?: 'Mutation' } & {
    updateEmployee?: Maybe<{ __typename?: 'Employee' } & EmployeeSingleFragment>;
};

export type GetEmployeeCollectionQueryVariables = Exact<{
    args?: Maybe<EmployeeCollectionArgs>;
}>;

export type GetEmployeeCollectionQueryType = { __typename?: 'Query' } & {
    getEmployeeCollection?: Maybe<
        { __typename?: 'EmployeeCollection' } & {
            items?: Maybe<Array<Maybe<{ __typename?: 'Employee' } & EmployeeCollectionFragment>>>;
            page?: Maybe<{ __typename?: 'PageReply' } & PageReplyFragment>;
        }
    >;
};

export type GetEmployeeQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetEmployeeQueryType = { __typename?: 'Query' } & {
    getEmployee?: Maybe<{ __typename?: 'Employee' } & EmployeeSingleFragment>;
};

export type GetEmployeesQueryVariables = Exact<{
    args?: Maybe<EmployeeCollectionArgs>;
}>;

export type GetEmployeesQueryType = { __typename?: 'Query' } & {
    getEmployeeCollection?: Maybe<
        { __typename?: 'EmployeeCollection' } & {
            items?: Maybe<Array<Maybe<{ __typename?: 'Employee' } & EmployeeCollectionFragment>>>;
            page?: Maybe<{ __typename?: 'PageReply' } & PageReplyFragment>;
        }
    >;
};

export type EvaluationCriteriaCollectionFragment = {
    __typename?: 'EvaluationCriteriaCollection';
} & {
    items?: Maybe<
        Array<Maybe<{ __typename?: 'EvaluationCriteria' } & EvaluationCriteriaSingleFragment>>
    >;
};

export type EvaluationCriteriaSingleFragment = { __typename?: 'EvaluationCriteria' } & Pick<
    EvaluationCriteria,
    'id' | 'name' | 'forStudent'
>;

export type CreateEvaluationCriteriaMutationVariables = Exact<{
    item: EvaluationCriteriaArgs;
}>;

export type CreateEvaluationCriteriaMutationType = { __typename?: 'Mutation' } & {
    createEvaluationCriteria?: Maybe<
        { __typename?: 'EvaluationCriteria' } & EvaluationCriteriaSingleFragment
    >;
};

export type RemoveEvaluationCriteriaMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type RemoveEvaluationCriteriaMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'removeEvaluationCriteria'
>;

export type UpdateEvaluationCriteriaMutationVariables = Exact<{
    id: Scalars['ID'];
    item: EvaluationCriteriaArgs;
}>;

export type UpdateEvaluationCriteriaMutationType = { __typename?: 'Mutation' } & {
    updateEvaluationCriteria?: Maybe<
        { __typename?: 'EvaluationCriteria' } & EvaluationCriteriaSingleFragment
    >;
};

export type GetEvaluationCriteriaCollectionQueryVariables = Exact<{
    forStudent?: Maybe<Scalars['Boolean']>;
}>;

export type GetEvaluationCriteriaCollectionQueryType = { __typename?: 'Query' } & {
    getEvaluationCriteriaCollection?: Maybe<
        { __typename?: 'EvaluationCriteriaCollection' } & EvaluationCriteriaCollectionFragment
    >;
};

export type GetEvaluationCriteriaQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetEvaluationCriteriaQueryType = { __typename?: 'Query' } & {
    getEvaluationCriteria?: Maybe<
        { __typename?: 'EvaluationCriteria' } & EvaluationCriteriaSingleFragment
    >;
};

export type EvaluationSingleFragment = { __typename?: 'Evaluation' } & Pick<
    Evaluation,
    | 'id'
    | 'company'
    | 'platoon'
    | 'platoonX'
    | 'date'
    | 'settledScore'
    | 'summaryScore'
    | 'avgScore'
> & {
        leader?: Maybe<{ __typename?: 'Employee' } & EmployeeSingleFragment>;
        mentor?: Maybe<{ __typename?: 'Employee' } & EmployeeSingleFragment>;
    };

export type SetEvaluationScoreMutationVariables = Exact<{
    evaluationId: Scalars['ID'];
    criteriaId: Scalars['ID'];
    score: Scalars['Int'];
}>;

export type SetEvaluationScoreMutationType = { __typename?: 'Mutation' } & {
    setEvaluationScore?: Maybe<
        { __typename?: 'EvaluationScore' } & Pick<
            EvaluationScore,
            'id' | 'score' | 'createdAt' | 'updatedAt'
        > & {
                evaluation?: Maybe<{ __typename?: 'Evaluation' } & EvaluationSingleFragment>;
                criteria?: Maybe<
                    { __typename?: 'EvaluationCriteria' } & EvaluationCriteriaSingleFragment
                >;
            }
    >;
};

export type GetEvaluationCollectionQueryVariables = Exact<{
    date: Scalars['String'];
}>;

export type GetEvaluationCollectionQueryType = { __typename?: 'Query' } & {
    getEvaluationCollection?: Maybe<
        { __typename?: 'EvaluationCollection' } & {
            items?: Maybe<Array<Maybe<{ __typename?: 'Evaluation' } & EvaluationSingleFragment>>>;
        }
    >;
};

export type GetEvaluationScoreCollectionQueryVariables = Exact<{
    args?: Maybe<EvaluationScoreCollectionArgs>;
}>;

export type GetEvaluationScoreCollectionQueryType = { __typename?: 'Query' } & {
    getEvaluationScoreCollection?: Maybe<
        { __typename?: 'EvaluationScoreCollection' } & {
            items?: Maybe<
                Array<
                    Maybe<
                        { __typename?: 'EvaluationScoreCollectionItem' } & Pick<
                            EvaluationScoreCollectionItem,
                            'score' | 'criteriaName' | 'criteriaId' | 'createdAt' | 'updatedAt'
                        >
                    >
                >
            >;
        }
    >;
};

export type GetEvaluationQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetEvaluationQueryType = { __typename?: 'Query' } & {
    getEvaluation?: Maybe<{ __typename?: 'Evaluation' } & EvaluationSingleFragment>;
};

export type EvaluationResponsibleSingleFragment = { __typename?: 'EvaluationResponsible' } & Pick<
    EvaluationResponsible,
    'company' | 'platoon' | 'platoonX' | 'lapId'
> & {
        leader?: Maybe<{ __typename?: 'Employee' } & EmployeeSingleFragment>;
        mentor?: Maybe<{ __typename?: 'Employee' } & EmployeeSingleFragment>;
    };

export type CopyEvaluationResponsibleMutationVariables = Exact<{
    fromLapId: Scalars['ID'];
}>;

export type CopyEvaluationResponsibleMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'copyEvaluationResponsible'
>;

export type SetEvaluationResponsibleMutationVariables = Exact<{
    platoonX: Scalars['Int'];
    item: EvaluationResponsibleArgs;
}>;

export type SetEvaluationResponsibleMutationType = { __typename?: 'Mutation' } & {
    setEvaluationResponsible?: Maybe<
        { __typename?: 'EvaluationResponsible' } & EvaluationResponsibleSingleFragment
    >;
};

export type SetMoreEvaluationResponsiblesMutationVariables = Exact<{
    items: Array<Maybe<MoreEvaluationResponsibleArgs>> | Maybe<MoreEvaluationResponsibleArgs>;
}>;

export type SetMoreEvaluationResponsiblesMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'setMoreEvaluationResponsibles'
>;

export type GetEvaluationResponsibleCollectionQueryVariables = Exact<{ [key: string]: never }>;

export type GetEvaluationResponsibleCollectionQueryType = { __typename?: 'Query' } & {
    getEvaluationResponsibleCollection?: Maybe<
        { __typename?: 'EvaluationResponsibleCollection' } & {
            items?: Maybe<
                Array<
                    Maybe<
                        {
                            __typename?: 'EvaluationResponsible';
                        } & EvaluationResponsibleSingleFragment
                    >
                >
            >;
        }
    >;
};

export type EvaluationStudentCollectionFragment = {
    __typename?: 'EvaluationStudentCollectionItem';
} & Pick<
    EvaluationStudentCollectionItem,
    | 'id'
    | 'evaluationId'
    | 'company'
    | 'platoon'
    | 'platoonX'
    | 'settledScore'
    | 'summaryScore'
    | 'avgScore'
> & {
        mentor?: Maybe<{ __typename?: 'Employee' } & EmployeeSingleFragment>;
        leader?: Maybe<{ __typename?: 'Employee' } & EmployeeSingleFragment>;
    };

export type EvaluationStudentScoreCollectionFragment = {
    __typename?: 'EvaluationStudentScoreCollectionItem';
} & {
    student?: Maybe<{ __typename?: 'StudentMiniInfo' } & StudentMiniInfoSingleFragment>;
    scores?: Maybe<
        Array<
            Maybe<
                { __typename?: 'EvaluationStudentScoreCollectionItemScore' } & Pick<
                    EvaluationStudentScoreCollectionItemScore,
                    'criteriaId' | 'score'
                >
            >
        >
    >;
};

export type EvaluationStudentScoresFragment = { __typename?: 'EvaluationStudentScores' } & {
    student?: Maybe<{ __typename?: 'StudentMiniInfo' } & StudentMiniInfoSingleFragment>;
    scores?: Maybe<
        Array<
            Maybe<
                { __typename?: 'EvaluationStudentScoresItem' } & Pick<
                    EvaluationStudentScoresItem,
                    'criteriaId' | 'criteriaName' | 'score'
                >
            >
        >
    >;
};

export type SetEvaluationStudentScoresMutationVariables = Exact<{
    args: EvaluationStudentScoresArgs;
}>;

export type SetEvaluationStudentScoresMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'setEvaluationStudentScores'
>;

export type GetEvaluationStudentCollectionQueryVariables = Exact<{ [key: string]: never }>;

export type GetEvaluationStudentCollectionQueryType = { __typename?: 'Query' } & {
    getEvaluationStudentCollection?: Maybe<
        { __typename?: 'EvaluationStudentCollection' } & {
            items?: Maybe<
                Array<
                    Maybe<
                        {
                            __typename?: 'EvaluationStudentCollectionItem';
                        } & EvaluationStudentCollectionFragment
                    >
                >
            >;
        }
    >;
};

export type GetEvaluationStudentScoreCollectionQueryVariables = Exact<{
    platoonX: Scalars['Int'];
}>;

export type GetEvaluationStudentScoreCollectionQueryType = { __typename?: 'Query' } & {
    getEvaluationStudentScoreCollection?: Maybe<
        { __typename?: 'EvaluationStudentScoreCollection' } & {
            criteriaList?: Maybe<
                Array<
                    Maybe<
                        { __typename?: 'EvaluationCriteria' } & Pick<
                            EvaluationCriteria,
                            'id' | 'name'
                        >
                    >
                >
            >;
            items?: Maybe<
                Array<
                    Maybe<
                        {
                            __typename?: 'EvaluationStudentScoreCollectionItem';
                        } & EvaluationStudentScoreCollectionFragment
                    >
                >
            >;
        }
    >;
};

export type GetEvaluationStudentScoresQueryVariables = Exact<{
    studentId: Scalars['ID'];
}>;

export type GetEvaluationStudentScoresQueryType = { __typename?: 'Query' } & {
    getEvaluationStudentScores?: Maybe<
        { __typename?: 'EvaluationStudentScores' } & EvaluationStudentScoresFragment
    >;
};

export type EventFragment = { __typename?: 'Event' } & Pick<
    Event,
    'id' | 'name' | 'placeName' | 'date' | 'time'
> & { photo?: Maybe<{ __typename?: 'FileInfo' } & FileInfoFragment> };

export type CreateEventMutationVariables = Exact<{
    item: EventCreateArgs;
}>;

export type CreateEventMutationType = { __typename?: 'Mutation' } & {
    createEvent?: Maybe<{ __typename?: 'Event' } & EventFragment>;
};

export type RemoveEventMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type RemoveEventMutationType = { __typename?: 'Mutation' } & Pick<Mutation, 'removeEvent'>;

export type UpdateEventMutationVariables = Exact<{
    id: Scalars['ID'];
    item: EventUpdateArgs;
}>;

export type UpdateEventMutationType = { __typename?: 'Mutation' } & {
    updateEvent?: Maybe<{ __typename?: 'Event' } & EventFragment>;
};

export type GetEventCollectionQueryVariables = Exact<{
    args?: Maybe<EventCollectionArgs>;
}>;

export type GetEventCollectionQueryType = { __typename?: 'Query' } & {
    getEventCollection?: Maybe<
        { __typename?: 'EventCollection' } & {
            items?: Maybe<Array<Maybe<{ __typename?: 'Event' } & EventFragment>>>;
            page?: Maybe<{ __typename?: 'PageReply' } & PageReplyFragment>;
        }
    >;
};

export type GetEventQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetEventQueryType = { __typename?: 'Query' } & {
    getEvent?: Maybe<{ __typename?: 'Event' } & EventFragment>;
};

export type HandbookLapFragment = { __typename?: 'Lap' } & Pick<
    Lap,
    'id' | 'from' | 'to' | 'platform' | 'programType'
> & { course?: Maybe<{ __typename?: 'OLCourse' } & Pick<OlCourse, 'id' | 'name'>> };

export type GetHandbookCenterStudentCollectionQueryVariables = Exact<{
    args?: Maybe<HandbookStudentCollectionArgs>;
}>;

export type GetHandbookCenterStudentCollectionQueryType = { __typename?: 'Query' } & {
    getHandbookCenterStudentCollection?: Maybe<
        { __typename?: 'HandbookCenterStudentCollection' } & {
            items?: Maybe<
                Array<
                    Maybe<
                        { __typename?: 'HandbookCenterStudent' } & Pick<
                            HandbookCenterStudent,
                            'id' | 'fullName' | 'score' | 'isCommercial' | 'isOut' | 'isOnline'
                        > & {
                                school?: Maybe<
                                    { __typename?: 'School' } & Pick<School, 'id' | 'shortName'>
                                >;
                                location?: Maybe<
                                    { __typename?: 'Location' } & Pick<Location, 'id' | 'name'>
                                >;
                                lapAccessList?: Maybe<
                                    Array<
                                        Maybe<
                                            { __typename?: 'LapAccess' } & Pick<LapAccess, 'id'> & {
                                                    lap?: Maybe<
                                                        { __typename?: 'Lap' } & HandbookLapFragment
                                                    >;
                                                }
                                        >
                                    >
                                >;
                                photo?: Maybe<{ __typename?: 'FileInfo' } & FileInfoFragment>;
                            }
                    >
                >
            >;
            page?: Maybe<{ __typename?: 'PageReply' } & PageReplyFragment>;
        }
    >;
};

export type GetHandbookSchoolCollectionQueryVariables = Exact<{
    args?: Maybe<HandbookSchoolCollectionArgs>;
}>;

export type GetHandbookSchoolCollectionQueryType = { __typename?: 'Query' } & {
    getHandbookSchoolCollection?: Maybe<
        { __typename?: 'HandbookSchoolCollection' } & {
            items?: Maybe<
                Array<
                    Maybe<
                        { __typename?: 'HandbookSchool' } & Pick<
                            HandbookSchool,
                            'id' | 'shortName' | 'type' | 'number' | 'phone' | 'email'
                        > & {
                                location?: Maybe<
                                    { __typename?: 'Location' } & Pick<Location, 'id' | 'name'>
                                >;
                                lapAccessList?: Maybe<
                                    Array<
                                        Maybe<
                                            { __typename?: 'LapAccess' } & Pick<LapAccess, 'id'> & {
                                                    lap?: Maybe<
                                                        { __typename?: 'Lap' } & HandbookLapFragment
                                                    >;
                                                }
                                        >
                                    >
                                >;
                            }
                    >
                >
            >;
            page?: Maybe<{ __typename?: 'PageReply' } & PageReplyFragment>;
        }
    >;
};

export type LapAccessFragment = { __typename?: 'LapAccess' } & Pick<
    LapAccess,
    'id' | 'blocked' | 'target' | 'targetId' | 'startAt' | 'finishAt' | 'studentCount'
> & { lap?: Maybe<{ __typename?: 'Lap' } & LapFragment> };

export type LapFragment = { __typename?: 'Lap' } & Pick<
    Lap,
    | 'id'
    | 'name'
    | 'from'
    | 'to'
    | 'platform'
    | 'isCurrent'
    | 'programType'
    | 'companyCount'
    | 'platoonCount'
> & { course?: Maybe<{ __typename?: 'OLCourse' } & Pick<OlCourse, 'id' | 'name'>> };

export type CreateLapAccessMutationVariables = Exact<{
    item: LapAccessCreateArgs;
}>;

export type CreateLapAccessMutationType = { __typename?: 'Mutation' } & {
    createLapAccess?: Maybe<{ __typename?: 'LapAccess' } & LapAccessFragment>;
};

export type CreateLapMutationVariables = Exact<{
    item: LapCreateArgs;
}>;

export type CreateLapMutationType = { __typename?: 'Mutation' } & {
    createLap?: Maybe<{ __typename?: 'Lap' } & LapFragment>;
};

export type RemoveLapAccessMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type RemoveLapAccessMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'removeLapAccess'
>;

export type RemoveLapMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type RemoveLapMutationType = { __typename?: 'Mutation' } & Pick<Mutation, 'removeLap'>;

export type SetCurrentLapMutationVariables = Exact<{
    id: Scalars['ID'];
    platform?: Maybe<Platform>;
}>;

export type SetCurrentLapMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'setCurrentLap'
>;

export type UpdateLapAccessMutationVariables = Exact<{
    id: Scalars['ID'];
    item: LapAccessUpdateArgs;
}>;

export type UpdateLapAccessMutationType = { __typename?: 'Mutation' } & {
    updateLapAccess?: Maybe<{ __typename?: 'LapAccess' } & LapAccessFragment>;
};

export type UpdateLapMutationVariables = Exact<{
    id: Scalars['ID'];
    item: LapUpdateArgs;
}>;

export type UpdateLapMutationType = { __typename?: 'Mutation' } & {
    updateLap?: Maybe<{ __typename?: 'Lap' } & LapFragment>;
};

export type GetAccessLapsQueryVariables = Exact<{
    args?: Maybe<LapCollectionArgs>;
}>;

export type GetAccessLapsQueryType = { __typename?: 'Query' } & {
    getAccessLaps?: Maybe<Array<Maybe<{ __typename?: 'Lap' } & LapFragment>>>;
};

export type GetCurrentLapQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentLapQueryType = { __typename?: 'Query' } & {
    getCurrentLap?: Maybe<{ __typename?: 'Lap' } & LapFragment>;
};

export type GetLapCollectionQueryVariables = Exact<{
    args?: Maybe<LapCollectionArgs>;
}>;

export type GetLapCollectionQueryType = { __typename?: 'Query' } & {
    getLapCollection?: Maybe<
        { __typename?: 'LapCollection' } & {
            items?: Maybe<Array<Maybe<{ __typename?: 'Lap' } & LapFragment>>>;
            page?: Maybe<{ __typename?: 'PageReply' } & PageReplyFragment>;
        }
    >;
};

export type GetLapsWithCertificatesQueryVariables = Exact<{
    schoolId?: Maybe<Scalars['ID']>;
}>;

export type GetLapsWithCertificatesQueryType = { __typename?: 'Query' } & {
    getLapsWithCertificates?: Maybe<
        Array<
            { __typename?: 'LapWithCertificateUrl' } & Pick<
                LapWithCertificateUrl,
                'certificatesUrl'
            > & {
                    lap?: Maybe<
                        { __typename?: 'Lap' } & Pick<Lap, 'id' | 'name' | 'from' | 'to'> & {
                                course?: Maybe<
                                    { __typename?: 'OLCourse' } & Pick<OlCourse, 'id' | 'name'>
                                >;
                            }
                    >;
                }
        >
    >;
};

export type GetLapsQueryVariables = Exact<{
    platform?: Maybe<Platform>;
    courseId?: Maybe<Scalars['ID']>;
}>;

export type GetLapsQueryType = { __typename?: 'Query' } & {
    getLaps?: Maybe<Array<Maybe<{ __typename?: 'Lap' } & LapFragment>>>;
};

export type PageReplyFragment = { __typename?: 'PageReply' } & Pick<
    PageReply,
    'page' | 'size' | 'total'
>;

export type ProfileItemFragment = { __typename?: 'ProfileItem' } & Pick<
    ProfileItem,
    'label' | 'text' | 'link'
>;

export type ProfileSectionFragment = { __typename?: 'ProfileSection' } & Pick<
    ProfileSection,
    'name'
> & { items?: Maybe<Array<Maybe<{ __typename?: 'ProfileItem' } & ProfileItemFragment>>> };

export type GetProfileQueryVariables = Exact<{ [key: string]: never }>;

export type GetProfileQueryType = { __typename?: 'Query' } & {
    getProfile?: Maybe<
        { __typename?: 'Profile' } & Pick<Profile, 'id'> & {
                sections?: Maybe<
                    Array<Maybe<{ __typename?: 'ProfileSection' } & ProfileSectionFragment>>
                >;
            }
    >;
};

export type GetRatingsCompanyQueryVariables = Exact<{ [key: string]: never }>;

export type GetRatingsCompanyQueryType = { __typename?: 'Query' } & {
    getRatingsCompany?: Maybe<
        Array<
            Maybe<
                { __typename?: 'RatingsCompanyItem' } & Pick<
                    RatingsCompanyItem,
                    'company' | 'score'
                >
            >
        >
    >;
};

export type GetRatingsPlatoonQueryVariables = Exact<{ [key: string]: never }>;

export type GetRatingsPlatoonQueryType = { __typename?: 'Query' } & {
    getRatingsPlatoon?: Maybe<
        Array<
            Maybe<
                { __typename?: 'RatingsPlatoonItem' } & Pick<
                    RatingsPlatoonItem,
                    'platoonX' | 'score'
                >
            >
        >
    >;
};

export type GetRatingsTableQueryVariables = Exact<{
    args?: Maybe<RatingsTableArgs>;
}>;

export type GetRatingsTableQueryType = { __typename?: 'Query' } & {
    getRatingsTable?: Maybe<
        { __typename?: 'RatingsTable' } & {
            modules?: Maybe<
                Array<
                    Maybe<
                        { __typename?: 'RatingsTableModule' } & Pick<
                            RatingsTableModule,
                            'id' | 'name'
                        >
                    >
                >
            >;
            items?: Maybe<
                Array<
                    Maybe<
                        { __typename?: 'RatingsTableItem' } & Pick<
                            RatingsTableItem,
                            | 'id'
                            | 'company'
                            | 'platoon'
                            | 'platoonX'
                            | 'date'
                            | 'finalAverageScore'
                            | 'finalScore'
                        > & {
                                modulesAverageScores?: Maybe<
                                    Array<
                                        Maybe<
                                            { __typename?: 'RatingsTableModuleScore' } & Pick<
                                                RatingsTableModuleScore,
                                                'moduleId' | 'score'
                                            >
                                        >
                                    >
                                >;
                            }
                    >
                >
            >;
        }
    >;
};

export type GetCurrentRegionalCenterQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentRegionalCenterQueryType = { __typename?: 'Query' } & {
    getCurrentRegionalCenter?: Maybe<
        { __typename?: 'RegionalCenter' } & Pick<RegionalCenter, 'id' | 'name'> & {
                regions?: Maybe<
                    Array<Maybe<{ __typename?: 'Region' } & Pick<Region, 'id' | 'name'>>>
                >;
                location?: Maybe<{ __typename?: 'Location' } & Pick<Location, 'id' | 'name'>>;
            }
    >;
};

export type SchoolCollectionFragment = { __typename?: 'School' } & Pick<
    School,
    | 'id'
    | 'type'
    | 'number'
    | 'shortName'
    | 'phone'
    | 'email'
    | 'currentStudentsCount'
    | 'maxStudentsCount'
    | 'studentsAddMode'
    | 'accessSentStatusText'
    | 'accessSentStatus'
> & {
        region?: Maybe<{ __typename?: 'Region' } & Pick<Region, 'id' | 'name'>>;
        location?: Maybe<{ __typename?: 'Location' } & Pick<Location, 'id' | 'name'>>;
        lapAccessList?: Maybe<Array<Maybe<{ __typename?: 'LapAccess' } & LapAccessFragment>>>;
    };

export type SchoolSingleFragment = { __typename?: 'School' } & Pick<
    School,
    'fullName' | 'profile' | 'responsiblePerson' | 'accessSentAt' | 'isAccessSent'
> & {
        attachedUser?: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'username'>>;
        lapAccessList?: Maybe<Array<Maybe<{ __typename?: 'LapAccess' } & LapAccessFragment>>>;
    } & SchoolCollectionFragment;

export type CreateSchoolMutationVariables = Exact<{
    item: SchoolArgs;
}>;

export type CreateSchoolMutationType = { __typename?: 'Mutation' } & {
    createSchool?: Maybe<{ __typename?: 'School' } & SchoolCollectionFragment>;
};

export type RemoveSchoolMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type RemoveSchoolMutationType = { __typename?: 'Mutation' } & Pick<Mutation, 'removeSchool'>;

export type SendSchoolAccessMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type SendSchoolAccessMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'sendSchoolAccess'
>;

export type UpdateSchoolMutationVariables = Exact<{
    id: Scalars['ID'];
    item: SchoolArgs;
}>;

export type UpdateSchoolMutationType = { __typename?: 'Mutation' } & {
    updateSchool?: Maybe<{ __typename?: 'School' } & SchoolSingleFragment>;
};

export type GetSchoolCollectionLazyQueryVariables = Exact<{
    args?: Maybe<SchoolCollectionArgs>;
}>;

export type GetSchoolCollectionLazyQueryType = { __typename?: 'Query' } & {
    getSchoolCollection?: Maybe<
        { __typename?: 'SchoolCollection' } & {
            items?: Maybe<
                Array<
                    Maybe<
                        { __typename?: 'School' } & Pick<School, 'id' | 'shortName'> & {
                                lapAccessList?: Maybe<
                                    Array<Maybe<{ __typename?: 'LapAccess' } & LapAccessFragment>>
                                >;
                            }
                    >
                >
            >;
            page?: Maybe<{ __typename?: 'PageReply' } & PageReplyFragment>;
        }
    >;
};

export type GetSchoolCollectionQueryVariables = Exact<{
    args?: Maybe<SchoolCollectionArgs>;
}>;

export type GetSchoolCollectionQueryType = { __typename?: 'Query' } & {
    getSchoolCollection?: Maybe<
        { __typename?: 'SchoolCollection' } & {
            items?: Maybe<Array<Maybe<{ __typename?: 'School' } & SchoolCollectionFragment>>>;
            page?: Maybe<{ __typename?: 'PageReply' } & PageReplyFragment>;
        }
    >;
};

export type GetSchoolSummaryStatsQueryVariables = Exact<{
    schoolId: Scalars['ID'];
    lapId?: Maybe<Scalars['ID']>;
}>;

export type GetSchoolSummaryStatsQueryType = { __typename?: 'Query' } & {
    getSchoolSummaryStats?: Maybe<
        { __typename?: 'SchoolSummaryStats' } & Pick<
            SchoolSummaryStats,
            'total' | 'completed' | 'out' | 'averageScore'
        >
    >;
};

export type GetSchoolQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetSchoolQueryType = { __typename?: 'Query' } & {
    getSchool?: Maybe<{ __typename?: 'School' } & SchoolSingleFragment>;
};

export type GetSchoolsExportUrlQueryVariables = Exact<{ [key: string]: never }>;

export type GetSchoolsExportUrlQueryType = { __typename?: 'Query' } & Pick<
    Query,
    'getSchoolsExportUrl'
>;

export type UpdateUserGrantsTableMutationVariables = Exact<{
    items: Array<UserGrantsTableItemArgs> | UserGrantsTableItemArgs;
}>;

export type UpdateUserGrantsTableMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'updateUserGrantsTable'
>;

export type GetUserGrantsTableQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserGrantsTableQueryType = { __typename?: 'Query' } & {
    getUserGrantsTable?: Maybe<
        { __typename?: 'UserGrantsTable' } & Pick<UserGrantsTable, 'roles'> & {
                rows?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'UserGrantsTableRow' } & Pick<
                                UserGrantsTableRow,
                                | 'key'
                                | 'name'
                                | 'isCategory'
                                | 'isAction'
                                | 'categoryId'
                                | 'parentCategoryId'
                            > & {
                                    grants?: Maybe<
                                        Array<
                                            Maybe<
                                                { __typename?: 'UserGrantsTableItem' } & Pick<
                                                    UserGrantsTableItem,
                                                    'id' | 'category' | 'action' | 'granted'
                                                >
                                            >
                                        >
                                    >;
                                }
                        >
                    >
                >;
            }
    >;
};

export type FileDocFragment = { __typename?: 'FileDoc' } & Pick<FileDoc, 'name' | 'fileId'> & {
        file?: Maybe<{ __typename?: 'FileInfo' } & FileInfoFragment>;
    };

export type FileInfoFragment = { __typename?: 'FileInfo' } & Pick<
    FileInfo,
    'id' | 'name' | 'mime' | 'origin' | 'thumbnail' | 'croppedThumbnail' | 'createdAt'
> & {
        cropInfo?: Maybe<
            { __typename?: 'ImageCropInfo' } & Pick<
                ImageCropInfo,
                'x' | 'y' | 'width' | 'height' | 'rotateAngle' | 'scale'
            >
        >;
    };

export type TranslationFragment = { __typename?: 'Translation' } & Pick<
    Translation,
    'value' | 'locale'
>;

export type VideoFileDocFragment = { __typename?: 'VideoFileDoc' } & Pick<
    VideoFileDoc,
    'name' | 'fileId' | 'previewName' | 'previewFileId'
> & {
        file?: Maybe<{ __typename?: 'FileInfo' } & FileInfoFragment>;
        previewFile?: Maybe<{ __typename?: 'FileInfo' } & FileInfoFragment>;
    };

export type UpdateFileCropMutationVariables = Exact<{
    id: Scalars['ID'];
    cropInfo?: Maybe<ImageCropInfoArg>;
}>;

export type UpdateFileCropMutationType = { __typename?: 'Mutation' } & {
    updateFileCrop?: Maybe<
        { __typename?: 'UpdateFileCropReply' } & Pick<UpdateFileCropReply, 'croppedThumbnail'>
    >;
};

export type GetLocationsQueryVariables = Exact<{
    regionId?: Maybe<Scalars['ID']>;
    lapId?: Maybe<Scalars['ID']>;
}>;

export type GetLocationsQueryType = { __typename?: 'Query' } & {
    getLocations?: Maybe<Array<{ __typename?: 'Location' } & Pick<Location, 'id' | 'name'>>>;
};

export type GetRegionsQueryVariables = Exact<{
    args?: Maybe<RegionArgs>;
}>;

export type GetRegionsQueryType = { __typename?: 'Query' } & {
    getRegions?: Maybe<Array<{ __typename?: 'Region' } & Pick<Region, 'id' | 'name'>>>;
};

export type GetSchoolNumbersQueryVariables = Exact<{
    locationId?: Maybe<Scalars['ID']>;
    regionId?: Maybe<Scalars['ID']>;
}>;

export type GetSchoolNumbersQueryType = { __typename?: 'Query' } & Pick<Query, 'getSchoolNumbers'>;

export type GetSchoolTypesQueryVariables = Exact<{
    locationId: Scalars['ID'];
}>;

export type GetSchoolTypesQueryType = { __typename?: 'Query' } & Pick<Query, 'getSchoolTypes'>;

export type EgLessonsStatisticItemSingleFragment = { __typename?: 'EGLessonsStatisticItem' } & Pick<
    EgLessonsStatisticItem,
    | 'id'
    | 'subject'
    | 'subjectColor'
    | 'instructors'
    | 'company'
    | 'platoon'
    | 'platoonX'
    | 'date'
    | 'timeRange'
    | 'place'
    | 'scoresCount'
    | 'studentsCount'
    | 'averageScore'
>;

export type GetEgLessonsStatisticQueryVariables = Exact<{
    args?: Maybe<EgLessonStatisticArgs>;
}>;

export type GetEgLessonsStatisticQueryType = { __typename?: 'Query' } & {
    getEGLessonsStatistic?: Maybe<
        { __typename?: 'EGLessonsStatistic' } & {
            items?: Maybe<
                Array<
                    Maybe<
                        {
                            __typename?: 'EGLessonsStatisticItem';
                        } & EgLessonsStatisticItemSingleFragment
                    >
                >
            >;
            page?: Maybe<{ __typename?: 'PageReply' } & PageReplyFragment>;
        }
    >;
};

export type EgLessonScoresStatisticItemSingleFragment = {
    __typename?: 'EGLessonScoresStatisticItem';
} & Pick<EgLessonScoresStatisticItem, 'scores' | 'totalScore'> & {
        student?: Maybe<{ __typename?: 'StudentMiniInfo' } & StudentMiniInfoSingleFragment>;
    };

export type EgLessonScoresStudentItemSingleFragment = {
    __typename?: 'EGLessonScoresStudentItem';
} & Pick<
    EgLessonScoresStudentItem,
    'subject' | 'subjectColor' | 'date' | 'timeRange' | 'instructors' | 'attendance' | 'score'
>;

export type GetEgLessonScoresStatisticQueryVariables = Exact<{
    args?: Maybe<EgLessonScoresStatisticArgs>;
}>;

export type GetEgLessonScoresStatisticQueryType = { __typename?: 'Query' } & {
    getEGLessonScoresStatistic?: Maybe<
        { __typename?: 'EGLessonScoresStatistic' } & Pick<EgLessonScoresStatistic, 'dates'> & {
                items?: Maybe<
                    Array<
                        Maybe<
                            {
                                __typename?: 'EGLessonScoresStatisticItem';
                            } & EgLessonScoresStatisticItemSingleFragment
                        >
                    >
                >;
            }
    >;
};

export type GetEgLessonScoresStudentQueryVariables = Exact<{
    studentId: Scalars['ID'];
    subjectId: Scalars['ID'];
}>;

export type GetEgLessonScoresStudentQueryType = { __typename?: 'Query' } & {
    getEGLessonScoresStudent?: Maybe<
        { __typename?: 'EGLessonScoresStudent' } & {
            student?: Maybe<{ __typename?: 'StudentMiniInfo' } & StudentMiniInfoSingleFragment>;
            items?: Maybe<
                Array<
                    Maybe<
                        {
                            __typename?: 'EGLessonScoresStudentItem';
                        } & EgLessonScoresStudentItemSingleFragment
                    >
                >
            >;
        }
    >;
};

export type GetEgSubjectsInLapQueryVariables = Exact<{ [key: string]: never }>;

export type GetEgSubjectsInLapQueryType = { __typename?: 'Query' } & {
    getEGSubjectsInLap?: Maybe<Array<Maybe<{ __typename?: 'EGSubject' } & SubjectSingleFragment>>>;
};

export type StudentCollectionFragment = { __typename?: 'Student' } & Pick<
    Student,
    | 'id'
    | 'fullName'
    | 'birthdate'
    | 'address'
    | 'phone'
    | 'company'
    | 'platoon'
    | 'platoonX'
    | 'badgeChipId'
    | 'isOnline'
    | 'isAccessSent'
    | 'isCommercial'
    | 'isOut'
    | 'isCertificate'
> & {
        school?: Maybe<
            { __typename?: 'School' } & Pick<School, 'id' | 'type' | 'shortName' | 'number'>
        >;
    };

export type StudentMiniInfoSingleFragment = { __typename?: 'StudentMiniInfo' } & Pick<
    StudentMiniInfo,
    | 'id'
    | 'fullName'
    | 'company'
    | 'platoon'
    | 'platoonX'
    | 'isOnline'
    | 'isCommercial'
    | 'isOut'
    | 'isCertificate'
> & { photo?: Maybe<{ __typename?: 'FileInfo' } & FileInfoFragment> };

export type StudentSingleFragment = { __typename?: 'Student' } & Pick<
    Student,
    | 'gender'
    | 'email'
    | 'isAccessSent'
    | 'accessSentAt'
    | 'schoolClass'
    | 'schoolClassProfile'
    | 'performanceManagement'
    | 'creditOfPoints'
    | 'interest'
    | 'facultative'
    | 'clubs'
    | 'sportSection'
    | 'sportsCategory'
    | 'qrCodeUrl'
    | 'roomNumber'
    | 'receptionDate'
    | 'departureDate'
    | 'photoId'
> & {
        attachedUser?: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'username'>>;
        school?: Maybe<
            { __typename?: 'School' } & Pick<
                School,
                'id' | 'type' | 'shortName' | 'fullName' | 'number' | 'profile'
            > & {
                    region?: Maybe<{ __typename?: 'Region' } & Pick<Region, 'id' | 'name'>>;
                    location?: Maybe<{ __typename?: 'Location' } & Pick<Location, 'id' | 'name'>>;
                }
        >;
        socials?: Maybe<{ __typename?: 'SocialContacs' } & Pick<SocialContacs, 'vk'>>;
        building?: Maybe<
            { __typename?: 'Building' } & Pick<Building, 'id' | 'name'> & {
                    rooms?: Maybe<
                        Array<
                            Maybe<
                                { __typename?: 'BuildingRoom' } & Pick<BuildingRoom, 'id' | 'name'>
                            >
                        >
                    >;
                }
        >;
        room?: Maybe<{ __typename?: 'BuildingRoom' } & Pick<BuildingRoom, 'id' | 'name'>>;
        lapAccessList?: Maybe<Array<Maybe<{ __typename?: 'LapAccess' } & LapAccessFragment>>>;
        schoolLapAccessList?: Maybe<Array<Maybe<{ __typename?: 'LapAccess' } & LapAccessFragment>>>;
        photo?: Maybe<{ __typename?: 'FileInfo' } & FileInfoFragment>;
        region?: Maybe<{ __typename?: 'Region' } & Pick<Region, 'id' | 'name'>>;
        location?: Maybe<{ __typename?: 'Location' } & Pick<Location, 'id' | 'name'>>;
    } & StudentCollectionFragment;

export type CreateStudentMutationVariables = Exact<{
    item: StudentArgs;
}>;

export type CreateStudentMutationType = { __typename?: 'Mutation' } & {
    createStudent?: Maybe<{ __typename?: 'Student' } & StudentSingleFragment>;
};

export type DistributeStudentsMutationVariables = Exact<{
    lapId: Scalars['ID'];
}>;

export type DistributeStudentsMutationType = { __typename?: 'Mutation' } & {
    distributeStudents?: Maybe<
        { __typename?: 'InfoDistributionOfStudents' } & Pick<
            InfoDistributionOfStudents,
            'distributed' | 'total'
        >
    >;
};

export type ImportStudentsMutationVariables = Exact<{
    fileId: Scalars['ID'];
}>;

export type ImportStudentsMutationType = { __typename?: 'Mutation' } & {
    importStudents?: Maybe<
        { __typename?: 'ImportStudentsResult' } & Pick<
            ImportStudentsResult,
            'createdCount' | 'updatedCount' | 'totalCount'
        > & {
                errors?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'ImportStudentsResultError' } & Pick<
                                ImportStudentsResultError,
                                'line' | 'texts'
                            >
                        >
                    >
                >;
            }
    >;
};

export type RemoveStudentMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type RemoveStudentMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'removeStudent'
>;

export type SendStudentAccessMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type SendStudentAccessMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'sendStudentAccess'
>;

export type UpdateStudentMutationVariables = Exact<{
    id: Scalars['ID'];
    item: StudentArgs;
}>;

export type UpdateStudentMutationType = { __typename?: 'Mutation' } & {
    updateStudent?: Maybe<{ __typename?: 'Student' } & StudentSingleFragment>;
};

export type GetBuildingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetBuildingsQueryType = { __typename?: 'Query' } & {
    getBuildings?: Maybe<
        Array<
            Maybe<
                { __typename?: 'Building' } & Pick<Building, 'id' | 'name'> & {
                        rooms?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'BuildingRoom' } & Pick<
                                        BuildingRoom,
                                        'id' | 'name'
                                    >
                                >
                            >
                        >;
                    }
            >
        >
    >;
};

export type GetStudentCollectionQueryVariables = Exact<{
    args?: Maybe<StudentCollectionArgs>;
}>;

export type GetStudentCollectionQueryType = { __typename?: 'Query' } & {
    getStudentCollection?: Maybe<
        { __typename?: 'StudentCollection' } & {
            items?: Maybe<
                Array<
                    Maybe<
                        { __typename?: 'Student' } & Pick<
                            Student,
                            | 'id'
                            | 'fullName'
                            | 'birthdate'
                            | 'phone'
                            | 'company'
                            | 'platoon'
                            | 'platoonX'
                            | 'isOnline'
                            | 'accessSentStatusText'
                            | 'accessSentStatus'
                            | 'isCommercial'
                            | 'isOut'
                        > & {
                                school?: Maybe<
                                    { __typename?: 'School' } & Pick<
                                        School,
                                        'id' | 'type' | 'shortName' | 'number'
                                    > & {
                                            location?: Maybe<
                                                { __typename?: 'Location' } & Pick<
                                                    Location,
                                                    'id' | 'name'
                                                >
                                            >;
                                        }
                                >;
                                photo?: Maybe<{ __typename?: 'FileInfo' } & FileInfoFragment>;
                            }
                    >
                >
            >;
            page?: Maybe<{ __typename?: 'PageReply' } & PageReplyFragment>;
        }
    >;
};

export type GetStudentProfileCountsQueryVariables = Exact<{
    id: Scalars['ID'];
    lapByStudentId?: Maybe<Scalars['Boolean']>;
    lapFrom?: Maybe<Scalars['Date']>;
    lapTo?: Maybe<Scalars['Date']>;
}>;

export type GetStudentProfileCountsQueryType = { __typename?: 'Query' } & {
    getStudentProfileCounts?: Maybe<
        { __typename?: 'StudentProfileCounts' } & Pick<
            StudentProfileCounts,
            | 'fullName'
            | 'egScore'
            | 'evaluationCriteriaScore'
            | 'placeInOverallRatings'
            | 'placeInPlatoonRatings'
        >
    >;
};

export type GetStudentProfileQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetStudentProfileQueryType = { __typename?: 'Query' } & {
    getStudentProfile?: Maybe<
        { __typename?: 'StudentProfile' } & Pick<
            StudentProfile,
            'id' | 'fullName' | 'qrCodeUrl'
        > & {
                photo?: Maybe<{ __typename?: 'FileInfo' } & FileInfoFragment>;
                sections?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'StudentProfileSection' } & Pick<
                                StudentProfileSection,
                                'name'
                            > & {
                                    items?: Maybe<
                                        Array<
                                            Maybe<
                                                { __typename?: 'StudentProfileSectionItem' } & Pick<
                                                    StudentProfileSectionItem,
                                                    'label' | 'value' | 'link'
                                                >
                                            >
                                        >
                                    >;
                                }
                        >
                    >
                >;
            }
    >;
};

export type GetStudentTroopNumbersQueryVariables = Exact<{ [key: string]: never }>;

export type GetStudentTroopNumbersQueryType = { __typename?: 'Query' } & {
    getStudentTroopNumbers?: Maybe<
        { __typename?: 'StudentTroopNumbers' } & Pick<
            StudentTroopNumbers,
            'companies' | 'platoons' | 'platoonsX'
        >
    >;
};

export type GetStudentTroopsExportUrlQueryVariables = Exact<{ [key: string]: never }>;

export type GetStudentTroopsExportUrlQueryType = { __typename?: 'Query' } & Pick<
    Query,
    'getStudentTroopsExportUrl'
>;

export type GetStudentQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetStudentQueryType = { __typename?: 'Query' } & {
    getStudent?: Maybe<{ __typename?: 'Student' } & StudentSingleFragment>;
};

export type GetStudentsImportTemplateUrlQueryVariables = Exact<{
    schoolId?: Maybe<Scalars['ID']>;
}>;

export type GetStudentsImportTemplateUrlQueryType = { __typename?: 'Query' } & Pick<
    Query,
    'getStudentsImportTemplateUrl'
>;

export type LectureSingleFragment = { __typename?: 'OLLecture' } & Pick<
    OlLecture,
    | 'id'
    | 'userId'
    | 'sort'
    | 'status'
    | 'name'
    | 'author'
    | 'type'
    | 'text'
    | 'textType'
    | 'createdAt'
    | 'updatedAt'
> & {
        videos?: Maybe<Array<Maybe<{ __typename?: 'FileDoc' } & FileDocFragment>>>;
        docs?: Maybe<Array<Maybe<{ __typename?: 'FileDoc' } & FileDocFragment>>>;
    };

export type RemoveOlLectureMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type RemoveOlLectureMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'removeOLLecture'
>;

export type UpdateOlLectureMutationVariables = Exact<{
    id: Scalars['ID'];
    item: OlLectureArgs;
}>;

export type UpdateOlLectureMutationType = { __typename?: 'Mutation' } & {
    updateOLLecture?: Maybe<{ __typename?: 'OLLecture' } & LectureSingleFragment>;
};

export type GetOlLectureQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetOlLectureQueryType = { __typename?: 'Query' } & {
    getOLLecture?: Maybe<{ __typename?: 'OLLecture' } & LectureSingleFragment>;
};

export type OlSubjectCollectionFragment = { __typename?: 'OLSubjectCollection' } & {
    items?: Maybe<Array<Maybe<{ __typename?: 'OLSubject' } & OlSubjectSingleFragment>>>;
    page?: Maybe<{ __typename?: 'PageReply' } & PageReplyFragment>;
};

export type OlSubjectSingleFragment = { __typename?: 'OLSubject' } & Pick<
    OlSubject,
    'id' | 'name' | 'text' | 'courseId' | 'createdAt' | 'testEnabled'
> & {
        test?: Maybe<
            { __typename?: 'OLSubjectTest' } & Pick<OlSubjectTest, 'questionsCount' | 'duration'>
        >;
        lectures?: Maybe<
            Array<
                Maybe<
                    { __typename?: 'OLLecture' } & Pick<
                        OlLecture,
                        'id' | 'name' | 'text' | 'createdAt' | 'type'
                    > & { docs?: Maybe<Array<Maybe<{ __typename?: 'FileDoc' } & FileDocFragment>>> }
                >
            >
        >;
        videos?: Maybe<Array<Maybe<{ __typename?: 'VideoFileDoc' } & VideoFileDocFragment>>>;
        userTest?: Maybe<
            { __typename?: 'OLTest' } & Pick<
                OlTest,
                'id' | 'resultSuccess' | 'resultScore' | 'finished' | 'finishedAt'
            >
        >;
        testQuestions?: Maybe<
            Array<Maybe<{ __typename?: 'OLTestQuestion' } & Pick<OlTestQuestion, 'id'>>>
        >;
    };

export type CreateOlSubjectMutationVariables = Exact<{
    item: OlSubjectCreateArgs;
}>;

export type CreateOlSubjectMutationType = { __typename?: 'Mutation' } & {
    createOLSubject?: Maybe<{ __typename?: 'OLSubject' } & OlSubjectSingleFragment>;
};

export type RemoveOlSubjectMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type RemoveOlSubjectMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'removeOLSubject'
>;

export type SetOlSubjectsToEmployeeMutationVariables = Exact<{
    employeeId: Scalars['ID'];
    olSubjectIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type SetOlSubjectsToEmployeeMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'setOLSubjectsToEmployee'
>;

export type UpdateOlSubjectMutationVariables = Exact<{
    id: Scalars['ID'];
    item: OlSubjectUpdateArgs;
}>;

export type UpdateOlSubjectMutationType = { __typename?: 'Mutation' } & {
    updateOLSubject?: Maybe<{ __typename?: 'OLSubject' } & OlSubjectSingleFragment>;
};

export type GetEmployeeOlSubjectCollectionQueryVariables = Exact<{
    employeeId?: Maybe<Scalars['ID']>;
}>;

export type GetEmployeeOlSubjectCollectionQueryType = { __typename?: 'Query' } & {
    getEmployeeOLSubjects?: Maybe<Array<{ __typename?: 'OLSubject' } & OlSubjectSingleFragment>>;
};

export type GetOlSubjectCollectionQueryVariables = Exact<{
    args?: Maybe<OlSubjectCollectionArgs>;
}>;

export type GetOlSubjectCollectionQueryType = { __typename?: 'Query' } & {
    getOLSubjectCollection?: Maybe<
        { __typename?: 'OLSubjectCollection' } & OlSubjectCollectionFragment
    >;
};

export type GetOlSubjectQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetOlSubjectQueryType = { __typename?: 'Query' } & {
    getOLSubject?: Maybe<{ __typename?: 'OLSubject' } & OlSubjectSingleFragment>;
};

export type GetOlSubjectsQueryVariables = Exact<{ [key: string]: never }>;

export type GetOlSubjectsQueryType = { __typename?: 'Query' } & {
    getOLSubjects?: Maybe<Array<Maybe<{ __typename?: 'OLSubject' } & OlSubjectSingleFragment>>>;
};

export type TestAnswerFragment = { __typename?: 'OLTestAnswer' } & Pick<
    OlTestAnswer,
    'id' | 'text' | 'photoId' | 'isCorrect'
> & { photo?: Maybe<{ __typename?: 'FileInfo' } & FileInfoFragment> };

export type TestQuestionSingleFragment = { __typename?: 'OLTestQuestion' } & Pick<
    OlTestQuestion,
    'id' | 'subjectId' | 'text' | 'photoIds'
> & {
        answers?: Maybe<Array<Maybe<{ __typename?: 'OLTestAnswer' } & TestAnswerFragment>>>;
        photos?: Maybe<Array<Maybe<{ __typename?: 'FileInfo' } & FileInfoFragment>>>;
    };

export type CreateTestQuestionMutationVariables = Exact<{
    item: OlTestQuestionArgs;
}>;

export type CreateTestQuestionMutationType = { __typename?: 'Mutation' } & {
    createOLTestQuestion?: Maybe<{ __typename?: 'OLTestQuestion' } & TestQuestionSingleFragment>;
};

export type RemoveTestQuestionMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type RemoveTestQuestionMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'removeOLTestQuestion'
>;

export type UpdateTestQuestionMutationVariables = Exact<{
    id: Scalars['ID'];
    item: OlTestQuestionArgs;
}>;

export type UpdateTestQuestionMutationType = { __typename?: 'Mutation' } & {
    updateOLTestQuestion?: Maybe<{ __typename?: 'OLTestQuestion' } & TestQuestionSingleFragment>;
};

export type GetTestQuestionCollectionQueryVariables = Exact<{
    subjectId: Scalars['ID'];
}>;

export type GetTestQuestionCollectionQueryType = { __typename?: 'Query' } & {
    getOLTestQuestionCollection?: Maybe<
        { __typename?: 'OLTestQuestionCollections' } & {
            items?: Maybe<
                Array<Maybe<{ __typename?: 'OLTestQuestion' } & TestQuestionSingleFragment>>
            >;
        }
    >;
};

export type GetSummaryLapsGenderStudentsByRegionResultsQueryVariables = Exact<{
    filter?: Maybe<SummaryResultsFilter>;
}>;

export type GetSummaryLapsGenderStudentsByRegionResultsQueryType = { __typename?: 'Query' } & {
    getSummaryLapsGenderStudentsByRegionResults?: Maybe<
        Array<
            Maybe<
                { __typename?: 'SummaryLapsGenderStudents' } & Pick<
                    SummaryLapsGenderStudents,
                    'regionName' | 'summary'
                > & {
                        sections?: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'SummaryLapsGenderStudentsSection' } & Pick<
                                        SummaryLapsGenderStudentsSection,
                                        'gender' | 'summary'
                                    > & {
                                            items?: Maybe<
                                                Array<
                                                    Maybe<
                                                        {
                                                            __typename?: 'SummaryLapsGenderStudentsItem';
                                                        } & Pick<
                                                            SummaryLapsGenderStudentsItem,
                                                            'age' | 'count'
                                                        >
                                                    >
                                                >
                                            >;
                                        }
                                >
                            >
                        >;
                    }
            >
        >
    >;
};

export type GetSummaryLapsProgramsResultsQueryVariables = Exact<{
    filter?: Maybe<SummaryResultsFilter>;
}>;

export type GetSummaryLapsProgramsResultsQueryType = { __typename?: 'Query' } & {
    getSummaryLapsProgramsResults?: Maybe<
        Array<
            Maybe<
                { __typename?: 'SummaryLapsProgramResult' } & Pick<
                    SummaryLapsProgramResult,
                    'type' | 'count'
                >
            >
        >
    >;
};

export type GetSummaryLapsResultsQueryVariables = Exact<{
    filter?: Maybe<SummaryResultsFilter>;
}>;

export type GetSummaryLapsResultsQueryType = { __typename?: 'Query' } & {
    getSummaryLapsResults?: Maybe<
        { __typename?: 'SummaryLapsResults' } & Pick<SummaryLapsResults, 'allCount'> & {
                regionCounts?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'SummaryLapRegionCount' } & Pick<
                                SummaryLapRegionCount,
                                'regionName' | 'count'
                            >
                        >
                    >
                >;
            }
    >;
};

export type GetSummaryLapsSchoolCollectionQueryVariables = Exact<{
    args?: Maybe<SummaryLapsSchoolCollectionArgs>;
}>;

export type GetSummaryLapsSchoolCollectionQueryType = { __typename?: 'Query' } & {
    getSummaryLapsSchoolCollection?: Maybe<
        { __typename?: 'SchoolCollection' } & {
            items?: Maybe<Array<Maybe<{ __typename?: 'School' } & SchoolCollectionFragment>>>;
            page?: Maybe<{ __typename?: 'PageReply' } & PageReplyFragment>;
        }
    >;
};

export type GetSummaryLapsStudentCollectionQueryVariables = Exact<{
    args?: Maybe<SummaryLapsStudentCollectionArgs>;
}>;

export type GetSummaryLapsStudentCollectionQueryType = { __typename?: 'Query' } & {
    getSummaryLapsStudentCollection?: Maybe<
        { __typename?: 'StudentCollection' } & {
            items?: Maybe<
                Array<
                    Maybe<
                        { __typename?: 'Student' } & Pick<
                            Student,
                            | 'id'
                            | 'fullName'
                            | 'birthdate'
                            | 'phone'
                            | 'company'
                            | 'platoon'
                            | 'platoonX'
                            | 'isOnline'
                            | 'accessSentStatusText'
                            | 'accessSentStatus'
                            | 'isCommercial'
                            | 'isOut'
                        > & {
                                school?: Maybe<
                                    { __typename?: 'School' } & Pick<
                                        School,
                                        'id' | 'type' | 'shortName' | 'number'
                                    > & {
                                            location?: Maybe<
                                                { __typename?: 'Location' } & Pick<
                                                    Location,
                                                    'id' | 'name'
                                                >
                                            >;
                                        }
                                >;
                                photo?: Maybe<{ __typename?: 'FileInfo' } & FileInfoFragment>;
                            }
                    >
                >
            >;
            page?: Maybe<{ __typename?: 'PageReply' } & PageReplyFragment>;
        }
    >;
};

export type GetSummaryLapsStudentsResultsQueryVariables = Exact<{
    filter?: Maybe<SummaryResultsFilter>;
}>;

export type GetSummaryLapsStudentsResultsQueryType = { __typename?: 'Query' } & {
    getSummaryLapsStudentsResults?: Maybe<
        { __typename?: 'SummaryLapsStudentsResults' } & Pick<
            SummaryLapsStudentsResults,
            'schoolsCount' | 'passedStudentsCount' | 'commercialStudentsCount' | 'outStudentsCount'
        >
    >;
};

export type GetSummaryStudentsExportUrlQueryVariables = Exact<{
    filter?: Maybe<SummaryResultsFilter>;
}>;

export type GetSummaryStudentsExportUrlQueryType = { __typename?: 'Query' } & Pick<
    Query,
    'getSummaryStudentsExportUrl'
>;

export type TestFragment = { __typename?: 'OLTest' } & Pick<
    OlTest,
    | 'id'
    | 'userId'
    | 'subjectId'
    | 'courseId'
    | 'questionIds'
    | 'maxScore'
    | 'resultScore'
    | 'resultSuccess'
    | 'startedAt'
    | 'endTime'
    | 'leftTime'
    | 'finished'
    | 'finishedAt'
> & {
        answers?: Maybe<
            Array<
                Maybe<
                    { __typename?: 'OLTestUserAnswer' } & Pick<
                        OlTestUserAnswer,
                        'questionId' | 'answerIds'
                    >
                >
            >
        >;
    };

export type AnswerToQuestionMutationVariables = Exact<{
    subjectId?: Maybe<Scalars['ID']>;
    courseId: Scalars['ID'];
    answer: OlTestUserAnswerArgs;
}>;

export type AnswerToQuestionMutationType = { __typename?: 'Mutation' } & {
    answerTheQuestion?: Maybe<{ __typename?: 'OLTest' } & TestFragment>;
};

export type StartTestMutationVariables = Exact<{
    subjectId?: Maybe<Scalars['ID']>;
    courseId: Scalars['ID'];
}>;

export type StartTestMutationType = { __typename?: 'Mutation' } & {
    startOLTest?: Maybe<{ __typename?: 'OLTest' } & TestFragment>;
};

export type StopTestMutationVariables = Exact<{
    subjectId?: Maybe<Scalars['ID']>;
    courseId: Scalars['ID'];
}>;

export type StopTestMutationType = { __typename?: 'Mutation' } & {
    stopOLTest?: Maybe<{ __typename?: 'OLTest' } & TestFragment>;
};

export type GetActiveTestQueryVariables = Exact<{ [key: string]: never }>;

export type GetActiveTestQueryType = { __typename?: 'Query' } & {
    getActiveOLTest?: Maybe<
        { __typename?: 'OLTestInfo' } & Pick<
            OlTestInfo,
            'name' | 'exists' | 'subjectId' | 'isSubject'
        >
    >;
};

export type GetTestQueryVariables = Exact<{
    subjectId?: Maybe<Scalars['ID']>;
    courseId: Scalars['ID'];
}>;

export type GetTestQueryType = { __typename?: 'Query' } & {
    getOLTest?: Maybe<{ __typename?: 'OLTest' } & TestFragment>;
};

export type GetTestingQuestionQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetTestingQuestionQueryType = { __typename?: 'Query' } & {
    getOLTestQuestion?: Maybe<
        { __typename?: 'OLTestQuestion' } & Pick<
            OlTestQuestion,
            'id' | 'subjectId' | 'text' | 'photoIds' | 'correctAnswersCount'
        > & {
                answers?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'OLTestAnswer' } & Pick<OlTestAnswer, 'id' | 'text'> & {
                                    photo?: Maybe<{ __typename?: 'FileInfo' } & FileInfoFragment>;
                                }
                        >
                    >
                >;
                photos?: Maybe<Array<Maybe<{ __typename?: 'FileInfo' } & FileInfoFragment>>>;
            }
    >;
};

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserQueryType = { __typename?: 'Query' } & {
    getCurrentUser?: Maybe<
        { __typename?: 'User' } & Pick<
            User,
            'id' | 'username' | 'fullName' | 'email' | 'roles' | 'createdAt' | 'updatedAt'
        > & {
                student?: Maybe<{ __typename?: 'Student' } & Pick<Student, 'id' | 'fullName'>>;
                school?: Maybe<
                    { __typename?: 'School' } & Pick<
                        School,
                        | 'id'
                        | 'shortName'
                        | 'currentStudentsCount'
                        | 'maxStudentsCount'
                        | 'studentsAddMode'
                        | 'canDeleteStudent'
                    >
                >;
                grants?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'UserGrantItem' } & Pick<
                                UserGrantItem,
                                'category' | 'actions'
                            >
                        >
                    >
                >;
            }
    >;
};

export type WikiArticleSingleFragment = { __typename?: 'InternalWikiArticle' } & Pick<
    InternalWikiArticle,
    'id' | 'categoryId' | 'name' | 'author' | 'text'
> & { files?: Maybe<Array<Maybe<{ __typename?: 'FileDoc' } & FileDocFragment>>> };

export type CreateWikiArticleMutationVariables = Exact<{
    item: InternalWikiArticleCreateArgs;
}>;

export type CreateWikiArticleMutationType = { __typename?: 'Mutation' } & {
    createInternalWikiArticle?: Maybe<
        { __typename?: 'InternalWikiArticle' } & WikiArticleSingleFragment
    >;
};

export type RemoveWikiArticleMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type RemoveWikiArticleMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'removeInternalWikiArticle'
>;

export type UpdateWikiArticleMutationVariables = Exact<{
    id: Scalars['ID'];
    item: InternalWikiArticleUpdateArgs;
}>;

export type UpdateWikiArticleMutationType = { __typename?: 'Mutation' } & {
    updateInternalWikiArticle?: Maybe<
        { __typename?: 'InternalWikiArticle' } & WikiArticleSingleFragment
    >;
};

export type GetWikiArticleQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetWikiArticleQueryType = { __typename?: 'Query' } & {
    getInternalWikiArticle?: Maybe<
        { __typename?: 'InternalWikiArticle' } & WikiArticleSingleFragment
    >;
};

export type GetWikiArticlesQueryVariables = Exact<{
    categoryId: Scalars['ID'];
}>;

export type GetWikiArticlesQueryType = { __typename?: 'Query' } & {
    getInternalWikiArticles?: Maybe<
        Array<Maybe<{ __typename?: 'InternalWikiArticle' } & WikiArticleSingleFragment>>
    >;
};

export type WikiCategorySingleFragment = { __typename?: 'InternalWikiCategory' } & Pick<
    InternalWikiCategory,
    'id' | 'name' | 'articlesCount'
>;

export type CreateWikiCategoryMutationVariables = Exact<{
    item: InternalWikiCategoryArgs;
}>;

export type CreateWikiCategoryMutationType = { __typename?: 'Mutation' } & {
    createInternalWikiCategory?: Maybe<
        { __typename?: 'InternalWikiCategory' } & WikiCategorySingleFragment
    >;
};

export type RemoveWikiCategoryMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type RemoveWikiCategoryMutationType = { __typename?: 'Mutation' } & Pick<
    Mutation,
    'removeInternalWikiCategory'
>;

export type UpdateWikiCategoryMutationVariables = Exact<{
    id: Scalars['ID'];
    item: InternalWikiCategoryArgs;
}>;

export type UpdateWikiCategoryMutationType = { __typename?: 'Mutation' } & {
    updateInternalWikiCategory?: Maybe<
        { __typename?: 'InternalWikiCategory' } & WikiCategorySingleFragment
    >;
};

export type FindWikiQueryVariables = Exact<{
    filter?: Maybe<FindInternalWikiFilter>;
}>;

export type FindWikiQueryType = { __typename?: 'Query' } & {
    findInternalWiki?: Maybe<
        { __typename?: 'FindInternalWikiReply' } & Pick<FindInternalWikiReply, 'itemsCount'> & {
                categories?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'FindInternalWikiCategory' } & Pick<
                                FindInternalWikiCategory,
                                'id' | 'name' | 'articlesCount'
                            >
                        >
                    >
                >;
                articles?: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'FindInternalWikiArticle' } & Pick<
                                FindInternalWikiArticle,
                                'id' | 'categoryId' | 'name'
                            >
                        >
                    >
                >;
            }
    >;
};

export type GetWikiCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetWikiCategoriesQueryType = { __typename?: 'Query' } & {
    getInternalWikiCategories?: Maybe<
        Array<Maybe<{ __typename?: 'InternalWikiCategory' } & WikiCategorySingleFragment>>
    >;
};

export type GetWikiCategoryQueryVariables = Exact<{
    id: Scalars['ID'];
}>;

export type GetWikiCategoryQueryType = { __typename?: 'Query' } & {
    getInternalWikiCategory?: Maybe<
        { __typename?: 'InternalWikiCategory' } & WikiCategorySingleFragment
    >;
};

export const LapFragmentDoc = gql`
    fragment Lap on Lap {
        id
        name
        from
        to
        platform
        isCurrent
        course {
            id
            name
        }
        programType
        companyCount
        platoonCount
    }
`;
export const FileInfoFragmentDoc = gql`
    fragment FileInfo on FileInfo {
        id
        name
        mime
        origin
        thumbnail
        croppedThumbnail
        cropInfo {
            x
            y
            width
            height
            rotateAngle
            scale
        }
        createdAt
    }
`;
export const StudentCollectionFragmentDoc = gql`
    fragment StudentCollection on Student {
        id
        fullName
        birthdate
        address
        phone
        company
        platoon
        platoonX
        badgeChipId
        isOnline
        isAccessSent
        isCommercial
        isOut
        isCertificate
        school {
            id
            type
            shortName
            number
        }
    }
`;
export const LapAccessFragmentDoc = gql`
    fragment LapAccess on LapAccess {
        id
        blocked
        target
        targetId
        lap {
            ...Lap
        }
        startAt
        finishAt
        studentCount
    }
    ${LapFragmentDoc}
`;
export const StudentSingleFragmentDoc = gql`
    fragment StudentSingle on Student {
        ...StudentCollection
        gender
        email
        isAccessSent
        accessSentAt
        schoolClass
        schoolClassProfile
        performanceManagement
        creditOfPoints
        interest
        facultative
        clubs
        sportSection
        sportsCategory
        qrCodeUrl
        roomNumber
        receptionDate
        departureDate
        attachedUser {
            id
            username
        }
        school {
            id
            type
            shortName
            fullName
            number
            profile
            region {
                id
                name
            }
            location {
                id
                name
            }
        }
        socials {
            vk
        }
        building {
            id
            name
            rooms {
                id
                name
            }
        }
        room {
            id
            name
        }
        lapAccessList {
            ...LapAccess
        }
        schoolLapAccessList {
            ...LapAccess
        }
        photoId
        photo {
            ...FileInfo
        }
        region {
            id
            name
        }
        location {
            id
            name
        }
    }
    ${StudentCollectionFragmentDoc}
    ${LapAccessFragmentDoc}
    ${FileInfoFragmentDoc}
`;
export const SchoolCollectionFragmentDoc = gql`
    fragment SchoolCollection on School {
        id
        type
        number
        shortName
        phone
        email
        currentStudentsCount
        maxStudentsCount
        studentsAddMode
        accessSentStatusText
        accessSentStatus
        region {
            id
            name
        }
        location {
            id
            name
        }
        lapAccessList {
            ...LapAccess
        }
    }
    ${LapAccessFragmentDoc}
`;
export const SchoolSingleFragmentDoc = gql`
    fragment SchoolSingle on School {
        ...SchoolCollection
        fullName
        profile
        responsiblePerson
        accessSentAt
        isAccessSent
        attachedUser {
            id
            username
        }
        lapAccessList {
            ...LapAccess
        }
    }
    ${SchoolCollectionFragmentDoc}
    ${LapAccessFragmentDoc}
`;
export const ActionLogFragmentDoc = gql`
    fragment ActionLog on ActionLog {
        id
        at
        lapId
        lap {
            ...Lap
        }
        userId
        user {
            id
            photoId
            photo {
                ...FileInfo
            }
            fullName
            email
            username
            roles
            createdAt
            updatedAt
            student {
                ...StudentSingle
            }
            school {
                ...SchoolSingle
            }
        }
        platform
        description
        values {
            label
            oldValue
            newValue
        }
    }
    ${LapFragmentDoc}
    ${FileInfoFragmentDoc}
    ${StudentSingleFragmentDoc}
    ${SchoolSingleFragmentDoc}
`;
export const LoginReplyFragmentDoc = gql`
    fragment LoginReply on LoginReply {
        accessToken
        refreshToken
    }
`;
export const CourseCollectionFragmentDoc = gql`
    fragment CourseCollection on OLCourse {
        id
        name
        testsCount
        subjectsCount
        isAvailable
        certificateUrl
    }
`;
export const LessonPlaceSingleFragmentDoc = gql`
    fragment LessonPlaceSingle on EGLessonPlace {
        id
        name
        shortName
    }
`;
export const PageReplyFragmentDoc = gql`
    fragment PageReply on PageReply {
        page
        size
        total
    }
`;
export const LessonPlaceCollectionFragmentDoc = gql`
    fragment LessonPlaceCollection on EGLessonPlaceCollection {
        items {
            ...LessonPlaceSingle
        }
        page {
            ...PageReply
        }
    }
    ${LessonPlaceSingleFragmentDoc}
    ${PageReplyFragmentDoc}
`;
export const SubjectSingleFragmentDoc = gql`
    fragment SubjectSingle on EGSubject {
        id
        name
        shortName
        description
        createdAt
        moduleId
        color {
            name
            value
        }
        topics {
            id
            name
        }
    }
`;
export const EgModuleSingleFragmentDoc = gql`
    fragment EGModuleSingle on EGModule {
        id
        name
        subjects {
            ...SubjectSingle
        }
    }
    ${SubjectSingleFragmentDoc}
`;
export const EgModuleCollectionFragmentDoc = gql`
    fragment EGModuleCollection on EGModuleCollection {
        items {
            ...EGModuleSingle
        }
        page {
            ...PageReply
        }
    }
    ${EgModuleSingleFragmentDoc}
    ${PageReplyFragmentDoc}
`;
export const EgLessonsListItemFragmentDoc = gql`
    fragment EgLessonsListItem on EGLessonsListItem {
        timeRange
        company
        platoon
        platoonX
        id
        name
        color
        instructors
        place
        scoresCount
        studentsCount
        averageScore
    }
`;
export const LessonDisciplineScoreFragmentDoc = gql`
    fragment LessonDisciplineScore on EGLessonDisciplineScore {
        id
        lessonId
        score
        createdAt
        updatedAt
    }
`;
export const StudentMiniInfoSingleFragmentDoc = gql`
    fragment StudentMiniInfoSingle on StudentMiniInfo {
        id
        fullName
        photo {
            ...FileInfo
        }
        company
        platoon
        platoonX
        isOnline
        isCommercial
        isOut
        isCertificate
    }
    ${FileInfoFragmentDoc}
`;
export const LessonScoreCollectionItemFragmentDoc = gql`
    fragment LessonScoreCollectionItem on EGLessonScoreCollectionItem {
        student {
            ...StudentMiniInfoSingle
        }
        locationName
        schoolType
        schoolName
        score
        attendance
    }
    ${StudentMiniInfoSingleFragmentDoc}
`;
export const LessonScoreCollectionSchoolItemFragmentDoc = gql`
    fragment LessonScoreCollectionSchoolItem on EGLessonScoreCollectionSchoolItem {
        fullName
        company
        platoon
        platoonX
        time
        subject
        subjectColor
        score
        attendance
        instructors {
            id
            fullName
        }
        topic {
            id
            name
        }
    }
`;
export const LessonScoreFragmentDoc = gql`
    fragment LessonScore on EGLessonScore {
        id
        studentId
        score
        attendance
    }
`;
export const TopicSingleFragmentDoc = gql`
    fragment TopicSingle on EGTopic {
        id
        name
    }
`;
export const EmployeeCollectionFragmentDoc = gql`
    fragment EmployeeCollection on Employee {
        id
        fullName
        photo {
            croppedThumbnail
        }
        role
        phone
        email
        birthdate
        accessSentStatusText
        accessSentStatus
        isArchived
    }
`;
export const TranslationFragmentDoc = gql`
    fragment Translation on Translation {
        value
        locale
    }
`;
export const EmployeeSingleFragmentDoc = gql`
    fragment EmployeeSingle on Employee {
        ...EmployeeCollection
        accessSentAt
        isAccessSent
        isBlocked
        qrCodeUrl
        displayInStand
        photoId
        specialization
        specializationTranslations {
            ...Translation
        }
        biography
        biographyTranslations {
            ...Translation
        }
        photo {
            ...FileInfo
        }
        attachedUser {
            id
            username
        }
        createdAt
    }
    ${EmployeeCollectionFragmentDoc}
    ${TranslationFragmentDoc}
    ${FileInfoFragmentDoc}
`;
export const LessonTimeFragmentDoc = gql`
    fragment LessonTime on EGLessonTime {
        id
        start
        end
        date
        type
    }
`;
export const LessonFragmentDoc = gql`
    fragment Lesson on EGLesson {
        id
        company
        platoon
        name
        placeName
        subject {
            ...SubjectSingle
        }
        topic {
            ...TopicSingle
        }
        instructor {
            ...EmployeeSingle
        }
        instructors {
            ...EmployeeSingle
        }
        place {
            ...LessonPlaceSingle
        }
        time {
            ...LessonTime
        }
    }
    ${SubjectSingleFragmentDoc}
    ${TopicSingleFragmentDoc}
    ${EmployeeSingleFragmentDoc}
    ${LessonPlaceSingleFragmentDoc}
    ${LessonTimeFragmentDoc}
`;
export const LessonsStudentItemFragmentDoc = gql`
    fragment LessonsStudentItem on EGLessonsStudentItem {
        time
        subject
        subjectColor
        place
        score
        attendance
        instructors {
            id
            fullName
        }
        topic {
            id
            name
        }
    }
`;
export const LessonsTableColumnFragmentDoc = gql`
    fragment LessonsTableColumn on EGLessonsTableCol {
        id
        type
        index
        name
        color
        instructors
        instructor
        instructorId
        place
        timeRange
        studentsCount
        scoresCount
        averageScore
    }
`;
export const LessonsTableRowFragmentDoc = gql`
    fragment LessonsTableRow on EGLessonsTableRow {
        company
        platoon
        platoonX
        cols {
            ...LessonsTableColumn
        }
    }
    ${LessonsTableColumnFragmentDoc}
`;
export const SubjectCollectionFragmentDoc = gql`
    fragment SubjectCollection on EGSubjectCollection {
        items {
            ...SubjectSingle
        }
        page {
            ...PageReply
        }
    }
    ${SubjectSingleFragmentDoc}
    ${PageReplyFragmentDoc}
`;
export const EvaluationCriteriaSingleFragmentDoc = gql`
    fragment EvaluationCriteriaSingle on EvaluationCriteria {
        id
        name
        forStudent
    }
`;
export const EvaluationCriteriaCollectionFragmentDoc = gql`
    fragment EvaluationCriteriaCollection on EvaluationCriteriaCollection {
        items {
            ...EvaluationCriteriaSingle
        }
    }
    ${EvaluationCriteriaSingleFragmentDoc}
`;
export const EvaluationSingleFragmentDoc = gql`
    fragment EvaluationSingle on Evaluation {
        id
        company
        platoon
        platoonX
        date
        settledScore
        summaryScore
        avgScore
        leader {
            ...EmployeeSingle
        }
        mentor {
            ...EmployeeSingle
        }
    }
    ${EmployeeSingleFragmentDoc}
`;
export const EvaluationResponsibleSingleFragmentDoc = gql`
    fragment EvaluationResponsibleSingle on EvaluationResponsible {
        company
        platoon
        platoonX
        lapId
        leader {
            ...EmployeeSingle
        }
        mentor {
            ...EmployeeSingle
        }
    }
    ${EmployeeSingleFragmentDoc}
`;
export const EvaluationStudentCollectionFragmentDoc = gql`
    fragment EvaluationStudentCollection on EvaluationStudentCollectionItem {
        id
        evaluationId
        company
        platoon
        platoonX
        settledScore
        summaryScore
        avgScore
        mentor {
            ...EmployeeSingle
        }
        leader {
            ...EmployeeSingle
        }
    }
    ${EmployeeSingleFragmentDoc}
`;
export const EvaluationStudentScoreCollectionFragmentDoc = gql`
    fragment EvaluationStudentScoreCollection on EvaluationStudentScoreCollectionItem {
        student {
            ...StudentMiniInfoSingle
        }
        scores {
            criteriaId
            score
        }
    }
    ${StudentMiniInfoSingleFragmentDoc}
`;
export const EvaluationStudentScoresFragmentDoc = gql`
    fragment EvaluationStudentScores on EvaluationStudentScores {
        student {
            ...StudentMiniInfoSingle
        }
        scores {
            criteriaId
            criteriaName
            score
        }
    }
    ${StudentMiniInfoSingleFragmentDoc}
`;
export const EventFragmentDoc = gql`
    fragment Event on Event {
        id
        name
        placeName
        date
        time
        photo {
            ...FileInfo
        }
    }
    ${FileInfoFragmentDoc}
`;
export const HandbookLapFragmentDoc = gql`
    fragment HandbookLap on Lap {
        id
        from
        to
        platform
        programType
        course {
            id
            name
        }
    }
`;
export const ProfileItemFragmentDoc = gql`
    fragment ProfileItem on ProfileItem {
        label
        text
        link
    }
`;
export const ProfileSectionFragmentDoc = gql`
    fragment ProfileSection on ProfileSection {
        name
        items {
            ...ProfileItem
        }
    }
    ${ProfileItemFragmentDoc}
`;
export const EgLessonsStatisticItemSingleFragmentDoc = gql`
    fragment EGLessonsStatisticItemSingle on EGLessonsStatisticItem {
        id
        subject
        subjectColor
        instructors
        company
        platoon
        platoonX
        date
        timeRange
        place
        scoresCount
        studentsCount
        averageScore
    }
`;
export const EgLessonScoresStatisticItemSingleFragmentDoc = gql`
    fragment EGLessonScoresStatisticItemSingle on EGLessonScoresStatisticItem {
        student {
            ...StudentMiniInfoSingle
        }
        scores
        totalScore
    }
    ${StudentMiniInfoSingleFragmentDoc}
`;
export const EgLessonScoresStudentItemSingleFragmentDoc = gql`
    fragment EGLessonScoresStudentItemSingle on EGLessonScoresStudentItem {
        subject
        subjectColor
        date
        timeRange
        instructors
        attendance
        score
    }
`;
export const FileDocFragmentDoc = gql`
    fragment FileDoc on FileDoc {
        name
        fileId
        file {
            ...FileInfo
        }
    }
    ${FileInfoFragmentDoc}
`;
export const LectureSingleFragmentDoc = gql`
    fragment LectureSingle on OLLecture {
        id
        userId
        sort
        status
        name
        author
        type
        text
        textType
        createdAt
        updatedAt
        videos {
            ...FileDoc
        }
        docs {
            ...FileDoc
        }
    }
    ${FileDocFragmentDoc}
`;
export const VideoFileDocFragmentDoc = gql`
    fragment VideoFileDoc on VideoFileDoc {
        name
        fileId
        file {
            ...FileInfo
        }
        previewName
        previewFileId
        previewFile {
            ...FileInfo
        }
    }
    ${FileInfoFragmentDoc}
`;
export const OlSubjectSingleFragmentDoc = gql`
    fragment OLSubjectSingle on OLSubject {
        id
        name
        text
        courseId
        createdAt
        test {
            questionsCount
            duration
        }
        testEnabled
        lectures {
            id
            name
            text
            createdAt
            type
            docs {
                ...FileDoc
            }
        }
        videos {
            ...VideoFileDoc
        }
        userTest {
            id
            resultSuccess
            resultScore
            finished
            finishedAt
        }
        testQuestions {
            id
        }
    }
    ${FileDocFragmentDoc}
    ${VideoFileDocFragmentDoc}
`;
export const OlSubjectCollectionFragmentDoc = gql`
    fragment OLSubjectCollection on OLSubjectCollection {
        items {
            ...OLSubjectSingle
        }
        page {
            ...PageReply
        }
    }
    ${OlSubjectSingleFragmentDoc}
    ${PageReplyFragmentDoc}
`;
export const TestAnswerFragmentDoc = gql`
    fragment TestAnswer on OLTestAnswer {
        id
        text
        photoId
        isCorrect
        photo {
            ...FileInfo
        }
    }
    ${FileInfoFragmentDoc}
`;
export const TestQuestionSingleFragmentDoc = gql`
    fragment TestQuestionSingle on OLTestQuestion {
        id
        subjectId
        text
        photoIds
        answers {
            ...TestAnswer
        }
        photos {
            ...FileInfo
        }
    }
    ${TestAnswerFragmentDoc}
    ${FileInfoFragmentDoc}
`;
export const TestFragmentDoc = gql`
    fragment Test on OLTest {
        id
        userId
        subjectId
        courseId
        questionIds
        maxScore
        resultScore
        resultSuccess
        startedAt
        endTime
        leftTime
        finished
        finishedAt
        answers {
            questionId
            answerIds
        }
    }
`;
export const WikiArticleSingleFragmentDoc = gql`
    fragment WikiArticleSingle on InternalWikiArticle {
        id
        categoryId
        name
        author
        files {
            ...FileDoc
        }
        text
    }
    ${FileDocFragmentDoc}
`;
export const WikiCategorySingleFragmentDoc = gql`
    fragment WikiCategorySingle on InternalWikiCategory {
        id
        name
        articlesCount
    }
`;
export const GetActionLogCollectionDocument = gql`
    query GetActionLogCollection($args: ActionLogCollectionArgs) {
        getActionLogCollection(args: $args) @connection(key: "getActionLogCollection") {
            sections {
                date
                items {
                    ...ActionLog
                }
            }
            page {
                hasNext
                nextPage
                hasPrev
                prevPage
            }
        }
    }
    ${ActionLogFragmentDoc}
`;
export const OnActionLogDocument = gql`
    subscription OnActionLog($filter: ActionLogFilter) {
        onActionLog(filter: $filter) {
            date
            items {
                ...ActionLog
            }
        }
    }
    ${ActionLogFragmentDoc}
`;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($old: String!, $new: String!) {
        changePassword(old: $old, new: $new)
    }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<
    ChangePasswordMutationType,
    ChangePasswordMutationVariables
>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
    ChangePasswordMutationType,
    ChangePasswordMutationVariables
>;
export const RefreshTokenDocument = gql`
    mutation RefreshToken($refreshToken: String!) {
        refreshToken(refreshToken: $refreshToken) {
            ...LoginReply
        }
    }
    ${LoginReplyFragmentDoc}
`;
export type RefreshTokenMutationFn = Apollo.MutationFunction<
    RefreshTokenMutationType,
    RefreshTokenMutationVariables
>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<
    RefreshTokenMutationType,
    RefreshTokenMutationVariables
>;
export const RevokeTokenDocument = gql`
    mutation RevokeToken($refreshToken: String!) {
        revokeToken(refreshToken: $refreshToken)
    }
`;
export type RevokeTokenMutationFn = Apollo.MutationFunction<
    RevokeTokenMutationType,
    RevokeTokenMutationVariables
>;
export type RevokeTokenMutationOptions = Apollo.BaseMutationOptions<
    RevokeTokenMutationType,
    RevokeTokenMutationVariables
>;
export const GetJivositeInfoDocument = gql`
    query GetJivositeInfo {
        getJivositeInfo {
            contactInfo {
                name
                phone
                email
            }
            customData {
                title
                content
            }
            userToken
        }
    }
`;
export const HasAccessForPlatformDocument = gql`
    query HasAccessForPlatform {
        hasAccessForPlatform
    }
`;
export const GetCourseCollectionDocument = gql`
    query GetCourseCollection($args: OLCourseCollectionArgs) {
        getOLCourseCollection(args: $args) {
            items {
                ...CourseCollection
            }
            page {
                ...PageReply
            }
        }
    }
    ${CourseCollectionFragmentDoc}
    ${PageReplyFragmentDoc}
`;
export const GetCourseDocument = gql`
    query GetCourse($id: ID!) {
        getOLCourse(id: $id) {
            id
            name
            certificateUrl
            test {
                questionsCount
                duration
            }
        }
    }
`;
export const GetCoursesDocument = gql`
    query GetCourses {
        getOLCourses {
            ...CourseCollection
        }
    }
    ${CourseCollectionFragmentDoc}
`;
export const GetEgLessonsTimelineDocument = gql`
    query GetEgLessonsTimeline($args: EGLessonsTimelineArgs!) {
        getEGLessonsTimeline(args: $args) {
            items {
                id
                timeRange
                duration
                type
                name
                instructors
                place
                status
            }
        }
    }
`;
export const GetCdbCountsDocument = gql`
    query GetCDBCounts {
        getCDBCounts {
            date {
                date
                time
            }
            weather {
                temp
                icon
                condition
            }
            students
            onlineStudents
            schools
        }
    }
`;
export const GetCdbLessonsScheduleDocument = gql`
    query GetCDBLessonsSchedule($platoonX: Int!) {
        getCDBLessonsSchedule(platoonX: $platoonX) {
            id
            subject
            subjectColor
            place
            instructors
            current
            future
            start
            end
        }
    }
`;
export const GetCdbPlatoonsRatingCollectionDocument = gql`
    query GetCDBPlatoonsRatingCollection {
        getCDBPlatoonsRatingCollection {
            items {
                company
                platoon
                platoonX
                score
            }
            count
        }
    }
`;
export const GetCdbRatingsDocument = gql`
    query GetCDBRatings {
        getCDBRatings {
            companies {
                company
                score
            }
            platoons {
                company
                platoonX
                score
            }
        }
    }
`;
export const GetCdbStudentsRatingCollectionDocument = gql`
    query GetCDBStudentsRatingCollection($args: CDBStudentsRatingCollectionArgs) {
        getCDBStudentsRatingCollection(args: $args)
            @connection(key: "getCDBStudentsRatingCollection") {
            items {
                id
                place
                fullName
                company
                platoon
                platoonX
                location
                shortName
                isCommercial
                isOut
                isOnline
                score
                egScore
                evaluationScore
                photo {
                    ...FileInfo
                }
            }
            page {
                ...PageReply
            }
        }
    }
    ${FileInfoFragmentDoc}
    ${PageReplyFragmentDoc}
`;
export const CreateLessonPlaceDocument = gql`
    mutation CreateLessonPlace($item: EGLessonPlaceArgs!) {
        createEGLessonPlace(item: $item) {
            ...LessonPlaceSingle
        }
    }
    ${LessonPlaceSingleFragmentDoc}
`;
export type CreateLessonPlaceMutationFn = Apollo.MutationFunction<
    CreateLessonPlaceMutationType,
    CreateLessonPlaceMutationVariables
>;
export type CreateLessonPlaceMutationOptions = Apollo.BaseMutationOptions<
    CreateLessonPlaceMutationType,
    CreateLessonPlaceMutationVariables
>;
export const RemoveLessonPlaceDocument = gql`
    mutation RemoveLessonPlace($id: ID!) {
        removeEGLessonPlace(id: $id)
    }
`;
export type RemoveLessonPlaceMutationFn = Apollo.MutationFunction<
    RemoveLessonPlaceMutationType,
    RemoveLessonPlaceMutationVariables
>;
export type RemoveLessonPlaceMutationOptions = Apollo.BaseMutationOptions<
    RemoveLessonPlaceMutationType,
    RemoveLessonPlaceMutationVariables
>;
export const UpdateLessonPlaceDocument = gql`
    mutation UpdateLessonPlace($id: ID!, $item: EGLessonPlaceArgs!) {
        updateEGLessonPlace(id: $id, item: $item) {
            ...LessonPlaceSingle
        }
    }
    ${LessonPlaceSingleFragmentDoc}
`;
export type UpdateLessonPlaceMutationFn = Apollo.MutationFunction<
    UpdateLessonPlaceMutationType,
    UpdateLessonPlaceMutationVariables
>;
export type UpdateLessonPlaceMutationOptions = Apollo.BaseMutationOptions<
    UpdateLessonPlaceMutationType,
    UpdateLessonPlaceMutationVariables
>;
export const GetEgLessonPlaceCollectionDocument = gql`
    query GetEgLessonPlaceCollection($args: EGLessonPlaceCollectionArgs) {
        getEGLessonPlaceCollection(args: $args) @connection(key: "getEGLessonPlaceCollection") {
            ...LessonPlaceCollection
        }
    }
    ${LessonPlaceCollectionFragmentDoc}
`;
export const GetEgLessonPlaceDocument = gql`
    query GetEgLessonPlace($id: ID!) {
        getEGLessonPlace(id: $id) {
            ...LessonPlaceSingle
        }
    }
    ${LessonPlaceSingleFragmentDoc}
`;
export const GetEgLessonPlacesDocument = gql`
    query GetEgLessonPlaces {
        getEGLessonPlaces {
            ...LessonPlaceSingle
        }
    }
    ${LessonPlaceSingleFragmentDoc}
`;
export const CreateEgModuleDocument = gql`
    mutation CreateEGModule($item: EGModuleCreateArgs!) {
        createEGModule(item: $item) {
            ...EGModuleSingle
        }
    }
    ${EgModuleSingleFragmentDoc}
`;
export type CreateEgModuleMutationFn = Apollo.MutationFunction<
    CreateEgModuleMutationType,
    CreateEgModuleMutationVariables
>;
export type CreateEgModuleMutationOptions = Apollo.BaseMutationOptions<
    CreateEgModuleMutationType,
    CreateEgModuleMutationVariables
>;
export const RemoveEgModuleDocument = gql`
    mutation RemoveEGModule($id: ID!) {
        removeEGModule(id: $id)
    }
`;
export type RemoveEgModuleMutationFn = Apollo.MutationFunction<
    RemoveEgModuleMutationType,
    RemoveEgModuleMutationVariables
>;
export type RemoveEgModuleMutationOptions = Apollo.BaseMutationOptions<
    RemoveEgModuleMutationType,
    RemoveEgModuleMutationVariables
>;
export const UpdateEgModuleDocument = gql`
    mutation UpdateEGModule($id: ID!, $item: EGModuleUpdateArgs!) {
        updateEGModule(id: $id, item: $item) {
            ...EGModuleSingle
        }
    }
    ${EgModuleSingleFragmentDoc}
`;
export type UpdateEgModuleMutationFn = Apollo.MutationFunction<
    UpdateEgModuleMutationType,
    UpdateEgModuleMutationVariables
>;
export type UpdateEgModuleMutationOptions = Apollo.BaseMutationOptions<
    UpdateEgModuleMutationType,
    UpdateEgModuleMutationVariables
>;
export const GetEgModuleCollectionDocument = gql`
    query GetEGModuleCollection($args: EGModuleCollectionArgs) {
        getEGModuleCollection(args: $args) @connection(key: "getEGModuleCollection") {
            ...EGModuleCollection
        }
    }
    ${EgModuleCollectionFragmentDoc}
`;
export const GetEgModuleDocument = gql`
    query GetEGModule($id: ID!) {
        getEGModule(id: $id) {
            ...EGModuleSingle
        }
    }
    ${EgModuleSingleFragmentDoc}
`;
export const GetEgModulesDocument = gql`
    query GetEGModules {
        getEGModules {
            ...EGModuleSingle
        }
    }
    ${EgModuleSingleFragmentDoc}
`;
export const CopyEgLessonDocument = gql`
    mutation CopyEgLesson($id: ID!, $params: [EGLessonCopyParam!]!) {
        copyEGLesson(id: $id, params: $params)
    }
`;
export type CopyEgLessonMutationFn = Apollo.MutationFunction<
    CopyEgLessonMutationType,
    CopyEgLessonMutationVariables
>;
export type CopyEgLessonMutationOptions = Apollo.BaseMutationOptions<
    CopyEgLessonMutationType,
    CopyEgLessonMutationVariables
>;
export const CopyEgLessonsDocument = gql`
    mutation CopyEgLessons($params: EGLessonsCopyParams!) {
        copyEGLessons(params: $params)
    }
`;
export type CopyEgLessonsMutationFn = Apollo.MutationFunction<
    CopyEgLessonsMutationType,
    CopyEgLessonsMutationVariables
>;
export type CopyEgLessonsMutationOptions = Apollo.BaseMutationOptions<
    CopyEgLessonsMutationType,
    CopyEgLessonsMutationVariables
>;
export const CreateLessonTimeDocument = gql`
    mutation CreateLessonTime($item: EGLessonTimeArgs!) {
        createEGLessonTime(item: $item) {
            ...LessonTime
        }
    }
    ${LessonTimeFragmentDoc}
`;
export type CreateLessonTimeMutationFn = Apollo.MutationFunction<
    CreateLessonTimeMutationType,
    CreateLessonTimeMutationVariables
>;
export type CreateLessonTimeMutationOptions = Apollo.BaseMutationOptions<
    CreateLessonTimeMutationType,
    CreateLessonTimeMutationVariables
>;
export const CreateLessonDocument = gql`
    mutation CreateLesson($item: EGLessonArgs!) {
        createEGLesson(item: $item) {
            ...Lesson
        }
    }
    ${LessonFragmentDoc}
`;
export type CreateLessonMutationFn = Apollo.MutationFunction<
    CreateLessonMutationType,
    CreateLessonMutationVariables
>;
export type CreateLessonMutationOptions = Apollo.BaseMutationOptions<
    CreateLessonMutationType,
    CreateLessonMutationVariables
>;
export const CreateMoreLessonsDocument = gql`
    mutation CreateMoreLessons($items: [EGLessonArgs!]!) {
        createMoreEGLessons(items: $items) {
            id
        }
    }
`;
export type CreateMoreLessonsMutationFn = Apollo.MutationFunction<
    CreateMoreLessonsMutationType,
    CreateMoreLessonsMutationVariables
>;
export type CreateMoreLessonsMutationOptions = Apollo.BaseMutationOptions<
    CreateMoreLessonsMutationType,
    CreateMoreLessonsMutationVariables
>;
export const MoveEgLessonDocument = gql`
    mutation MoveEgLesson($id: ID!, $params: EGLessonMoveParams!) {
        moveEGLesson(id: $id, params: $params)
    }
`;
export type MoveEgLessonMutationFn = Apollo.MutationFunction<
    MoveEgLessonMutationType,
    MoveEgLessonMutationVariables
>;
export type MoveEgLessonMutationOptions = Apollo.BaseMutationOptions<
    MoveEgLessonMutationType,
    MoveEgLessonMutationVariables
>;
export const RemoveLessonTimeDocument = gql`
    mutation RemoveLessonTime($id: ID!) {
        removeEGLessonTime(id: $id)
    }
`;
export type RemoveLessonTimeMutationFn = Apollo.MutationFunction<
    RemoveLessonTimeMutationType,
    RemoveLessonTimeMutationVariables
>;
export type RemoveLessonTimeMutationOptions = Apollo.BaseMutationOptions<
    RemoveLessonTimeMutationType,
    RemoveLessonTimeMutationVariables
>;
export const RemoveLessonDocument = gql`
    mutation RemoveLesson($id: ID!) {
        removeEGLesson(id: $id)
    }
`;
export type RemoveLessonMutationFn = Apollo.MutationFunction<
    RemoveLessonMutationType,
    RemoveLessonMutationVariables
>;
export type RemoveLessonMutationOptions = Apollo.BaseMutationOptions<
    RemoveLessonMutationType,
    RemoveLessonMutationVariables
>;
export const RemoveMoreEgLessonsDocument = gql`
    mutation RemoveMoreEgLessons($ids: [ID!]!) {
        removeMoreEGLessons(ids: $ids)
    }
`;
export type RemoveMoreEgLessonsMutationFn = Apollo.MutationFunction<
    RemoveMoreEgLessonsMutationType,
    RemoveMoreEgLessonsMutationVariables
>;
export type RemoveMoreEgLessonsMutationOptions = Apollo.BaseMutationOptions<
    RemoveMoreEgLessonsMutationType,
    RemoveMoreEgLessonsMutationVariables
>;
export const SetEgLessonScoreAllDocument = gql`
    mutation SetEGLessonScoreAll($lessonId: ID!, $score: Int!, $attendance: EGAttendance) {
        setEGLessonScoreAll(lessonId: $lessonId, score: $score, attendance: $attendance)
    }
`;
export type SetEgLessonScoreAllMutationFn = Apollo.MutationFunction<
    SetEgLessonScoreAllMutationType,
    SetEgLessonScoreAllMutationVariables
>;
export type SetEgLessonScoreAllMutationOptions = Apollo.BaseMutationOptions<
    SetEgLessonScoreAllMutationType,
    SetEgLessonScoreAllMutationVariables
>;
export const SetEgLessonDisciplineScoreDocument = gql`
    mutation SetEGLessonDisciplineScore($lessonId: ID!, $score: Int!) {
        setEGLessonDisciplineScore(lessonId: $lessonId, score: $score) {
            ...LessonDisciplineScore
        }
    }
    ${LessonDisciplineScoreFragmentDoc}
`;
export type SetEgLessonDisciplineScoreMutationFn = Apollo.MutationFunction<
    SetEgLessonDisciplineScoreMutationType,
    SetEgLessonDisciplineScoreMutationVariables
>;
export type SetEgLessonDisciplineScoreMutationOptions = Apollo.BaseMutationOptions<
    SetEgLessonDisciplineScoreMutationType,
    SetEgLessonDisciplineScoreMutationVariables
>;
export const SetLessonScoreDocument = gql`
    mutation SetLessonScore($lessonId: ID!, $item: EGLessonScoreArgs!) {
        setEGLessonScore(lessonId: $lessonId, item: $item) {
            ...LessonScore
        }
    }
    ${LessonScoreFragmentDoc}
`;
export type SetLessonScoreMutationFn = Apollo.MutationFunction<
    SetLessonScoreMutationType,
    SetLessonScoreMutationVariables
>;
export type SetLessonScoreMutationOptions = Apollo.BaseMutationOptions<
    SetLessonScoreMutationType,
    SetLessonScoreMutationVariables
>;
export const SetMoreEgLessonScoresDocument = gql`
    mutation SetMoreEGLessonScores($lessonId: ID!, $items: [EGLessonScoreArgs!]!) {
        setMoreEGLessonScores(lessonId: $lessonId, items: $items)
    }
`;
export type SetMoreEgLessonScoresMutationFn = Apollo.MutationFunction<
    SetMoreEgLessonScoresMutationType,
    SetMoreEgLessonScoresMutationVariables
>;
export type SetMoreEgLessonScoresMutationOptions = Apollo.BaseMutationOptions<
    SetMoreEgLessonScoresMutationType,
    SetMoreEgLessonScoresMutationVariables
>;
export const UpdateLessonTimeDocument = gql`
    mutation UpdateLessonTime($id: ID!, $item: EGLessonTimeArgs!) {
        updateEGLessonTime(id: $id, item: $item) {
            ...LessonTime
        }
    }
    ${LessonTimeFragmentDoc}
`;
export type UpdateLessonTimeMutationFn = Apollo.MutationFunction<
    UpdateLessonTimeMutationType,
    UpdateLessonTimeMutationVariables
>;
export type UpdateLessonTimeMutationOptions = Apollo.BaseMutationOptions<
    UpdateLessonTimeMutationType,
    UpdateLessonTimeMutationVariables
>;
export const UpdateLessonDocument = gql`
    mutation UpdateLesson($id: ID!, $item: EGLessonArgs!) {
        updateEGLesson(id: $id, item: $item) {
            ...Lesson
        }
    }
    ${LessonFragmentDoc}
`;
export type UpdateLessonMutationFn = Apollo.MutationFunction<
    UpdateLessonMutationType,
    UpdateLessonMutationVariables
>;
export type UpdateLessonMutationOptions = Apollo.BaseMutationOptions<
    UpdateLessonMutationType,
    UpdateLessonMutationVariables
>;
export const GetEgExportSchoolsUrlDocument = gql`
    query GetEgExportSchoolsUrl(
        $locationId: ID!
        $modules: [EGExportSchoolsModule!]!
        $schoolId: ID
    ) {
        getEGExportSchoolsUrl(locationId: $locationId, modules: $modules, schoolId: $schoolId)
    }
`;
export const GetEgExportThroughoutLapUrlDocument = gql`
    query getEGExportThroughoutLapUrl($modules: [EGExportSchoolsModule!]!) {
        getEGExportThroughoutLapUrl(modules: $modules)
    }
`;
export const GetEgExportTimesheetUrlDocument = gql`
    query GetEGExportTimesheetUrl($company: Int, $platoon: Int, $platoonX: Int) {
        getEGExportTimesheetUrl(company: $company, platoon: $platoon, platoonX: $platoonX)
    }
`;
export const GetEgLessonsListDocument = gql`
    query GetEgLessonsList($args: EGLessonsListArgs!) {
        getEGLessonsList(args: $args) {
            timeList {
                ...LessonTime
            }
            items {
                ...EgLessonsListItem
            }
            page {
                ...PageReply
            }
        }
    }
    ${LessonTimeFragmentDoc}
    ${EgLessonsListItemFragmentDoc}
    ${PageReplyFragmentDoc}
`;
export const GetEgLessonDisciplineScoreDocument = gql`
    query GetEGLessonDisciplineScore($lessonId: ID!) {
        getEGLessonDisciplineScore(lessonId: $lessonId) {
            ...LessonDisciplineScore
        }
    }
    ${LessonDisciplineScoreFragmentDoc}
`;
export const GetLessonScoreCollectionSchoolDocument = gql`
    query GetLessonScoreCollectionSchool($args: EGLessonScoreCollectionSchoolArgs) {
        getEGLessonScoreCollectionSchool(args: $args) {
            items {
                ...LessonScoreCollectionSchoolItem
            }
            page {
                ...PageReply
            }
        }
    }
    ${LessonScoreCollectionSchoolItemFragmentDoc}
    ${PageReplyFragmentDoc}
`;
export const GetLessonScoreCollectionDocument = gql`
    query GetLessonScoreCollection($args: EGLessonScoreCollectionArgs) {
        getEGLessonScoreCollection(args: $args)
            @connection(key: "getEGLessonScoreCollection", filter: ["args"]) {
            company
            platoon
            platoonX
            lessonDate
            lessonTime
            lessonIndex
            lessonPlace
            instructors {
                id
                fullName
                attachedUser {
                    id
                    username
                }
            }
            items {
                ...LessonScoreCollectionItem
            }
            page {
                ...PageReply
            }
        }
    }
    ${LessonScoreCollectionItemFragmentDoc}
    ${PageReplyFragmentDoc}
`;
export const GetLessonTimeListDocument = gql`
    query GetLessonTimeList($args: EGLessonTimeListArgs!) {
        getEGLessonTimeList(args: $args) {
            ...LessonTime
        }
    }
    ${LessonTimeFragmentDoc}
`;
export const GetLessonDocument = gql`
    query GetLesson($id: ID!) {
        getEGLesson(id: $id) {
            ...Lesson
        }
    }
    ${LessonFragmentDoc}
`;
export const GetLessonsStudentDocument = gql`
    query GetLessonsStudent($args: EGLessonsStudentArgs) {
        getEGLessonsStudent(args: $args) {
            ...LessonsStudentItem
        }
    }
    ${LessonsStudentItemFragmentDoc}
`;
export const GetLessonsTableDocument = gql`
    query GetLessonsTable($args: EGLessonsTableArgs!) {
        getEGLessonsTable(args: $args) @connection(key: "getEGLessonsTable") {
            timeList {
                ...LessonTime
            }
            rows {
                ...LessonsTableRow
            }
        }
    }
    ${LessonTimeFragmentDoc}
    ${LessonsTableRowFragmentDoc}
`;
export const CreateSubjectDocument = gql`
    mutation CreateSubject($item: EGSubjectCreateArgs!) {
        createEGSubject(item: $item) {
            ...SubjectSingle
        }
    }
    ${SubjectSingleFragmentDoc}
`;
export type CreateSubjectMutationFn = Apollo.MutationFunction<
    CreateSubjectMutationType,
    CreateSubjectMutationVariables
>;
export type CreateSubjectMutationOptions = Apollo.BaseMutationOptions<
    CreateSubjectMutationType,
    CreateSubjectMutationVariables
>;
export const RemoveSubjectDocument = gql`
    mutation removeSubject($id: ID!) {
        removeEGSubject(id: $id)
    }
`;
export type RemoveSubjectMutationFn = Apollo.MutationFunction<
    RemoveSubjectMutationType,
    RemoveSubjectMutationVariables
>;
export type RemoveSubjectMutationOptions = Apollo.BaseMutationOptions<
    RemoveSubjectMutationType,
    RemoveSubjectMutationVariables
>;
export const UpdateSubjectDocument = gql`
    mutation updateSubject($id: ID!, $item: EGSubjectUpdateArgs!) {
        updateEGSubject(id: $id, item: $item) {
            ...SubjectSingle
        }
    }
    ${SubjectSingleFragmentDoc}
`;
export type UpdateSubjectMutationFn = Apollo.MutationFunction<
    UpdateSubjectMutationType,
    UpdateSubjectMutationVariables
>;
export type UpdateSubjectMutationOptions = Apollo.BaseMutationOptions<
    UpdateSubjectMutationType,
    UpdateSubjectMutationVariables
>;
export const GetEgSubjectCollectionDocument = gql`
    query GetEgSubjectCollection($args: EGSubjectCollectionArgs) {
        getEGSubjectCollection(args: $args) @connection(key: "getEGSubjectCollection") {
            ...SubjectCollection
        }
    }
    ${SubjectCollectionFragmentDoc}
`;
export const GetEgSubjectsDocument = gql`
    query GetEgSubjects {
        getEGSubjects {
            ...SubjectSingle
        }
    }
    ${SubjectSingleFragmentDoc}
`;
export const GetSubjectDocument = gql`
    query GetSubject($id: ID!) {
        getEGSubject(id: $id) {
            ...SubjectSingle
        }
    }
    ${SubjectSingleFragmentDoc}
`;
export const ArchiveTheEmployeeDocument = gql`
    mutation archiveTheEmployee($id: ID!, $archive: Boolean!) {
        archiveTheEmployee(id: $id, archive: $archive)
    }
`;
export type ArchiveTheEmployeeMutationFn = Apollo.MutationFunction<
    ArchiveTheEmployeeMutationType,
    ArchiveTheEmployeeMutationVariables
>;
export type ArchiveTheEmployeeMutationOptions = Apollo.BaseMutationOptions<
    ArchiveTheEmployeeMutationType,
    ArchiveTheEmployeeMutationVariables
>;
export const CreateEmployeeDocument = gql`
    mutation CreateEmployee($item: EmployeeArgs!) {
        createEmployee(item: $item) {
            ...EmployeeSingle
        }
    }
    ${EmployeeSingleFragmentDoc}
`;
export type CreateEmployeeMutationFn = Apollo.MutationFunction<
    CreateEmployeeMutationType,
    CreateEmployeeMutationVariables
>;
export type CreateEmployeeMutationOptions = Apollo.BaseMutationOptions<
    CreateEmployeeMutationType,
    CreateEmployeeMutationVariables
>;
export const SendEmployeeAccessDocument = gql`
    mutation SendEmployeeAccess($id: ID!) {
        sendEmployeeAccess(id: $id)
    }
`;
export type SendEmployeeAccessMutationFn = Apollo.MutationFunction<
    SendEmployeeAccessMutationType,
    SendEmployeeAccessMutationVariables
>;
export type SendEmployeeAccessMutationOptions = Apollo.BaseMutationOptions<
    SendEmployeeAccessMutationType,
    SendEmployeeAccessMutationVariables
>;
export const UpdateEmployeeDocument = gql`
    mutation UpdateEmployee($id: ID!, $item: EmployeeArgs!) {
        updateEmployee(id: $id, item: $item) {
            ...EmployeeSingle
        }
    }
    ${EmployeeSingleFragmentDoc}
`;
export type UpdateEmployeeMutationFn = Apollo.MutationFunction<
    UpdateEmployeeMutationType,
    UpdateEmployeeMutationVariables
>;
export type UpdateEmployeeMutationOptions = Apollo.BaseMutationOptions<
    UpdateEmployeeMutationType,
    UpdateEmployeeMutationVariables
>;
export const GetEmployeeCollectionDocument = gql`
    query GetEmployeeCollection($args: EmployeeCollectionArgs) {
        getEmployeeCollection(args: $args) @connection(key: "getEmployeeCollection") {
            items {
                ...EmployeeCollection
            }
            page {
                ...PageReply
            }
        }
    }
    ${EmployeeCollectionFragmentDoc}
    ${PageReplyFragmentDoc}
`;
export const GetEmployeeDocument = gql`
    query GetEmployee($id: ID!) {
        getEmployee(id: $id) {
            ...EmployeeSingle
        }
    }
    ${EmployeeSingleFragmentDoc}
`;
export const GetEmployeesDocument = gql`
    query GetEmployees($args: EmployeeCollectionArgs) {
        getEmployeeCollection(args: $args) {
            items {
                ...EmployeeCollection
            }
            page {
                ...PageReply
            }
        }
    }
    ${EmployeeCollectionFragmentDoc}
    ${PageReplyFragmentDoc}
`;
export const CreateEvaluationCriteriaDocument = gql`
    mutation CreateEvaluationCriteria($item: EvaluationCriteriaArgs!) {
        createEvaluationCriteria(item: $item) {
            ...EvaluationCriteriaSingle
        }
    }
    ${EvaluationCriteriaSingleFragmentDoc}
`;
export type CreateEvaluationCriteriaMutationFn = Apollo.MutationFunction<
    CreateEvaluationCriteriaMutationType,
    CreateEvaluationCriteriaMutationVariables
>;
export type CreateEvaluationCriteriaMutationOptions = Apollo.BaseMutationOptions<
    CreateEvaluationCriteriaMutationType,
    CreateEvaluationCriteriaMutationVariables
>;
export const RemoveEvaluationCriteriaDocument = gql`
    mutation RemoveEvaluationCriteria($id: ID!) {
        removeEvaluationCriteria(id: $id)
    }
`;
export type RemoveEvaluationCriteriaMutationFn = Apollo.MutationFunction<
    RemoveEvaluationCriteriaMutationType,
    RemoveEvaluationCriteriaMutationVariables
>;
export type RemoveEvaluationCriteriaMutationOptions = Apollo.BaseMutationOptions<
    RemoveEvaluationCriteriaMutationType,
    RemoveEvaluationCriteriaMutationVariables
>;
export const UpdateEvaluationCriteriaDocument = gql`
    mutation UpdateEvaluationCriteria($id: ID!, $item: EvaluationCriteriaArgs!) {
        updateEvaluationCriteria(id: $id, item: $item) {
            ...EvaluationCriteriaSingle
        }
    }
    ${EvaluationCriteriaSingleFragmentDoc}
`;
export type UpdateEvaluationCriteriaMutationFn = Apollo.MutationFunction<
    UpdateEvaluationCriteriaMutationType,
    UpdateEvaluationCriteriaMutationVariables
>;
export type UpdateEvaluationCriteriaMutationOptions = Apollo.BaseMutationOptions<
    UpdateEvaluationCriteriaMutationType,
    UpdateEvaluationCriteriaMutationVariables
>;
export const GetEvaluationCriteriaCollectionDocument = gql`
    query GetEvaluationCriteriaCollection($forStudent: Boolean) {
        getEvaluationCriteriaCollection(forStudent: $forStudent)
            @connection(key: "getEvaluationCriteriaCollection") {
            ...EvaluationCriteriaCollection
        }
    }
    ${EvaluationCriteriaCollectionFragmentDoc}
`;
export const GetEvaluationCriteriaDocument = gql`
    query GetEvaluationCriteria($id: ID!) {
        getEvaluationCriteria(id: $id) {
            ...EvaluationCriteriaSingle
        }
    }
    ${EvaluationCriteriaSingleFragmentDoc}
`;
export const SetEvaluationScoreDocument = gql`
    mutation SetEvaluationScore($evaluationId: ID!, $criteriaId: ID!, $score: Int!) {
        setEvaluationScore(evaluationId: $evaluationId, criteriaId: $criteriaId, score: $score) {
            id
            score
            evaluation {
                ...EvaluationSingle
            }
            criteria {
                ...EvaluationCriteriaSingle
            }
            createdAt
            updatedAt
        }
    }
    ${EvaluationSingleFragmentDoc}
    ${EvaluationCriteriaSingleFragmentDoc}
`;
export type SetEvaluationScoreMutationFn = Apollo.MutationFunction<
    SetEvaluationScoreMutationType,
    SetEvaluationScoreMutationVariables
>;
export type SetEvaluationScoreMutationOptions = Apollo.BaseMutationOptions<
    SetEvaluationScoreMutationType,
    SetEvaluationScoreMutationVariables
>;
export const GetEvaluationCollectionDocument = gql`
    query GetEvaluationCollection($date: String!) {
        getEvaluationCollection(date: $date) @connection(key: "getEvaluationCollection") {
            items {
                ...EvaluationSingle
            }
        }
    }
    ${EvaluationSingleFragmentDoc}
`;
export const GetEvaluationScoreCollectionDocument = gql`
    query GetEvaluationScoreCollection($args: EvaluationScoreCollectionArgs) {
        getEvaluationScoreCollection(args: $args) @connection(key: "getEvaluationScoreCollection") {
            items {
                score
                criteriaName
                criteriaId
                createdAt
                updatedAt
            }
        }
    }
`;
export const GetEvaluationDocument = gql`
    query GetEvaluation($id: ID!) {
        getEvaluation(id: $id) {
            ...EvaluationSingle
        }
    }
    ${EvaluationSingleFragmentDoc}
`;
export const CopyEvaluationResponsibleDocument = gql`
    mutation CopyEvaluationResponsible($fromLapId: ID!) {
        copyEvaluationResponsible(fromLapId: $fromLapId)
    }
`;
export type CopyEvaluationResponsibleMutationFn = Apollo.MutationFunction<
    CopyEvaluationResponsibleMutationType,
    CopyEvaluationResponsibleMutationVariables
>;
export type CopyEvaluationResponsibleMutationOptions = Apollo.BaseMutationOptions<
    CopyEvaluationResponsibleMutationType,
    CopyEvaluationResponsibleMutationVariables
>;
export const SetEvaluationResponsibleDocument = gql`
    mutation SetEvaluationResponsible($platoonX: Int!, $item: EvaluationResponsibleArgs!) {
        setEvaluationResponsible(platoonX: $platoonX, item: $item) {
            ...EvaluationResponsibleSingle
        }
    }
    ${EvaluationResponsibleSingleFragmentDoc}
`;
export type SetEvaluationResponsibleMutationFn = Apollo.MutationFunction<
    SetEvaluationResponsibleMutationType,
    SetEvaluationResponsibleMutationVariables
>;
export type SetEvaluationResponsibleMutationOptions = Apollo.BaseMutationOptions<
    SetEvaluationResponsibleMutationType,
    SetEvaluationResponsibleMutationVariables
>;
export const SetMoreEvaluationResponsiblesDocument = gql`
    mutation SetMoreEvaluationResponsibles($items: [MoreEvaluationResponsibleArgs]!) {
        setMoreEvaluationResponsibles(items: $items)
    }
`;
export type SetMoreEvaluationResponsiblesMutationFn = Apollo.MutationFunction<
    SetMoreEvaluationResponsiblesMutationType,
    SetMoreEvaluationResponsiblesMutationVariables
>;
export type SetMoreEvaluationResponsiblesMutationOptions = Apollo.BaseMutationOptions<
    SetMoreEvaluationResponsiblesMutationType,
    SetMoreEvaluationResponsiblesMutationVariables
>;
export const GetEvaluationResponsibleCollectionDocument = gql`
    query GetEvaluationResponsibleCollection {
        getEvaluationResponsibleCollection {
            items {
                ...EvaluationResponsibleSingle
            }
        }
    }
    ${EvaluationResponsibleSingleFragmentDoc}
`;
export const SetEvaluationStudentScoresDocument = gql`
    mutation SetEvaluationStudentScores($args: EvaluationStudentScoresArgs!) {
        setEvaluationStudentScores(args: $args)
    }
`;
export type SetEvaluationStudentScoresMutationFn = Apollo.MutationFunction<
    SetEvaluationStudentScoresMutationType,
    SetEvaluationStudentScoresMutationVariables
>;
export type SetEvaluationStudentScoresMutationOptions = Apollo.BaseMutationOptions<
    SetEvaluationStudentScoresMutationType,
    SetEvaluationStudentScoresMutationVariables
>;
export const GetEvaluationStudentCollectionDocument = gql`
    query GetEvaluationStudentCollection {
        getEvaluationStudentCollection {
            items {
                ...EvaluationStudentCollection
            }
        }
    }
    ${EvaluationStudentCollectionFragmentDoc}
`;
export const GetEvaluationStudentScoreCollectionDocument = gql`
    query GetEvaluationStudentScoreCollection($platoonX: Int!) {
        getEvaluationStudentScoreCollection(platoonX: $platoonX) {
            criteriaList {
                id
                name
            }
            items {
                ...EvaluationStudentScoreCollection
            }
        }
    }
    ${EvaluationStudentScoreCollectionFragmentDoc}
`;
export const GetEvaluationStudentScoresDocument = gql`
    query GetEvaluationStudentScores($studentId: ID!) {
        getEvaluationStudentScores(studentId: $studentId) {
            ...EvaluationStudentScores
        }
    }
    ${EvaluationStudentScoresFragmentDoc}
`;
export const CreateEventDocument = gql`
    mutation CreateEvent($item: EventCreateArgs!) {
        createEvent(item: $item) {
            ...Event
        }
    }
    ${EventFragmentDoc}
`;
export type CreateEventMutationFn = Apollo.MutationFunction<
    CreateEventMutationType,
    CreateEventMutationVariables
>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<
    CreateEventMutationType,
    CreateEventMutationVariables
>;
export const RemoveEventDocument = gql`
    mutation RemoveEvent($id: ID!) {
        removeEvent(id: $id)
    }
`;
export type RemoveEventMutationFn = Apollo.MutationFunction<
    RemoveEventMutationType,
    RemoveEventMutationVariables
>;
export type RemoveEventMutationOptions = Apollo.BaseMutationOptions<
    RemoveEventMutationType,
    RemoveEventMutationVariables
>;
export const UpdateEventDocument = gql`
    mutation UpdateEvent($id: ID!, $item: EventUpdateArgs!) {
        updateEvent(id: $id, item: $item) {
            ...Event
        }
    }
    ${EventFragmentDoc}
`;
export type UpdateEventMutationFn = Apollo.MutationFunction<
    UpdateEventMutationType,
    UpdateEventMutationVariables
>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<
    UpdateEventMutationType,
    UpdateEventMutationVariables
>;
export const GetEventCollectionDocument = gql`
    query GetEventCollection($args: EventCollectionArgs) {
        getEventCollection(args: $args) {
            items {
                ...Event
            }
            page {
                ...PageReply
            }
        }
    }
    ${EventFragmentDoc}
    ${PageReplyFragmentDoc}
`;
export const GetEventDocument = gql`
    query GetEvent($id: ID!) {
        getEvent(id: $id) {
            ...Event
        }
    }
    ${EventFragmentDoc}
`;
export const GetHandbookCenterStudentCollectionDocument = gql`
    query GetHandbookCenterStudentCollection($args: HandbookStudentCollectionArgs) {
        getHandbookCenterStudentCollection(args: $args) {
            items {
                id
                fullName
                score
                isCommercial
                isOut
                isOnline
                school {
                    id
                    shortName
                }
                location {
                    id
                    name
                }
                lapAccessList {
                    id
                    lap {
                        ...HandbookLap
                    }
                }
                photo {
                    ...FileInfo
                }
            }
            page {
                ...PageReply
            }
        }
    }
    ${HandbookLapFragmentDoc}
    ${FileInfoFragmentDoc}
    ${PageReplyFragmentDoc}
`;
export const GetHandbookSchoolCollectionDocument = gql`
    query GetHandbookSchoolCollection($args: HandbookSchoolCollectionArgs) {
        getHandbookSchoolCollection(args: $args)
            @connection(key: "getHandbookSchoolCollection", filter: ["args"]) {
            items {
                id
                shortName
                type
                number
                phone
                email
                location {
                    id
                    name
                }
                lapAccessList {
                    id
                    lap {
                        ...HandbookLap
                    }
                }
            }
            page {
                ...PageReply
            }
        }
    }
    ${HandbookLapFragmentDoc}
    ${PageReplyFragmentDoc}
`;
export const CreateLapAccessDocument = gql`
    mutation CreateLapAccess($item: LapAccessCreateArgs!) {
        createLapAccess(item: $item) {
            ...LapAccess
        }
    }
    ${LapAccessFragmentDoc}
`;
export type CreateLapAccessMutationFn = Apollo.MutationFunction<
    CreateLapAccessMutationType,
    CreateLapAccessMutationVariables
>;
export type CreateLapAccessMutationOptions = Apollo.BaseMutationOptions<
    CreateLapAccessMutationType,
    CreateLapAccessMutationVariables
>;
export const CreateLapDocument = gql`
    mutation CreateLap($item: LapCreateArgs!) {
        createLap(item: $item) {
            ...Lap
        }
    }
    ${LapFragmentDoc}
`;
export type CreateLapMutationFn = Apollo.MutationFunction<
    CreateLapMutationType,
    CreateLapMutationVariables
>;
export type CreateLapMutationOptions = Apollo.BaseMutationOptions<
    CreateLapMutationType,
    CreateLapMutationVariables
>;
export const RemoveLapAccessDocument = gql`
    mutation RemoveLapAccess($id: ID!) {
        removeLapAccess(id: $id)
    }
`;
export type RemoveLapAccessMutationFn = Apollo.MutationFunction<
    RemoveLapAccessMutationType,
    RemoveLapAccessMutationVariables
>;
export type RemoveLapAccessMutationOptions = Apollo.BaseMutationOptions<
    RemoveLapAccessMutationType,
    RemoveLapAccessMutationVariables
>;
export const RemoveLapDocument = gql`
    mutation RemoveLap($id: ID!) {
        removeLap(id: $id)
    }
`;
export type RemoveLapMutationFn = Apollo.MutationFunction<
    RemoveLapMutationType,
    RemoveLapMutationVariables
>;
export type RemoveLapMutationOptions = Apollo.BaseMutationOptions<
    RemoveLapMutationType,
    RemoveLapMutationVariables
>;
export const SetCurrentLapDocument = gql`
    mutation SetCurrentLap($id: ID!, $platform: Platform) {
        setCurrentLap(id: $id, platform: $platform)
    }
`;
export type SetCurrentLapMutationFn = Apollo.MutationFunction<
    SetCurrentLapMutationType,
    SetCurrentLapMutationVariables
>;
export type SetCurrentLapMutationOptions = Apollo.BaseMutationOptions<
    SetCurrentLapMutationType,
    SetCurrentLapMutationVariables
>;
export const UpdateLapAccessDocument = gql`
    mutation UpdateLapAccess($id: ID!, $item: LapAccessUpdateArgs!) {
        updateLapAccess(id: $id, item: $item) {
            ...LapAccess
        }
    }
    ${LapAccessFragmentDoc}
`;
export type UpdateLapAccessMutationFn = Apollo.MutationFunction<
    UpdateLapAccessMutationType,
    UpdateLapAccessMutationVariables
>;
export type UpdateLapAccessMutationOptions = Apollo.BaseMutationOptions<
    UpdateLapAccessMutationType,
    UpdateLapAccessMutationVariables
>;
export const UpdateLapDocument = gql`
    mutation UpdateLap($id: ID!, $item: LapUpdateArgs!) {
        updateLap(id: $id, item: $item) {
            ...Lap
        }
    }
    ${LapFragmentDoc}
`;
export type UpdateLapMutationFn = Apollo.MutationFunction<
    UpdateLapMutationType,
    UpdateLapMutationVariables
>;
export type UpdateLapMutationOptions = Apollo.BaseMutationOptions<
    UpdateLapMutationType,
    UpdateLapMutationVariables
>;
export const GetAccessLapsDocument = gql`
    query GetAccessLaps($args: LapCollectionArgs) {
        getAccessLaps(args: $args) {
            ...Lap
        }
    }
    ${LapFragmentDoc}
`;
export const GetCurrentLapDocument = gql`
    query GetCurrentLap {
        getCurrentLap {
            ...Lap
        }
    }
    ${LapFragmentDoc}
`;
export const GetLapCollectionDocument = gql`
    query GetLapCollection($args: LapCollectionArgs) {
        getLapCollection(args: $args) {
            items {
                ...Lap
            }
            page {
                ...PageReply
            }
        }
    }
    ${LapFragmentDoc}
    ${PageReplyFragmentDoc}
`;
export const GetLapsWithCertificatesDocument = gql`
    query GetLapsWithCertificates($schoolId: ID) {
        getLapsWithCertificates(schoolId: $schoolId) {
            certificatesUrl
            lap {
                id
                name
                from
                to
                course {
                    id
                    name
                }
            }
        }
    }
`;
export const GetLapsDocument = gql`
    query GetLaps($platform: Platform, $courseId: ID) {
        getLaps(platform: $platform, courseId: $courseId) {
            ...Lap
        }
    }
    ${LapFragmentDoc}
`;
export const GetProfileDocument = gql`
    query GetProfile {
        getProfile {
            id
            sections {
                ...ProfileSection
            }
        }
    }
    ${ProfileSectionFragmentDoc}
`;
export const GetRatingsCompanyDocument = gql`
    query GetRatingsCompany {
        getRatingsCompany @connection(key: "getRatingsCompany") {
            company
            score
        }
    }
`;
export const GetRatingsPlatoonDocument = gql`
    query GetRatingsPlatoon {
        getRatingsPlatoon @connection(key: "getRatingsPlatoon") {
            platoonX
            score
        }
    }
`;
export const GetRatingsTableDocument = gql`
    query GetRatingsTable($args: RatingsTableArgs) {
        getRatingsTable(args: $args) @connection(key: "getRatingsTable") {
            modules {
                id
                name
            }
            items {
                id
                company
                platoon
                platoonX
                date
                modulesAverageScores {
                    moduleId
                    score
                }
                finalAverageScore
                finalScore
            }
        }
    }
`;
export const GetCurrentRegionalCenterDocument = gql`
    query GetCurrentRegionalCenter {
        getCurrentRegionalCenter {
            id
            name
            regions {
                id
                name
            }
            location {
                id
                name
            }
        }
    }
`;
export const CreateSchoolDocument = gql`
    mutation CreateSchool($item: SchoolArgs!) {
        createSchool(item: $item) {
            ...SchoolCollection
        }
    }
    ${SchoolCollectionFragmentDoc}
`;
export type CreateSchoolMutationFn = Apollo.MutationFunction<
    CreateSchoolMutationType,
    CreateSchoolMutationVariables
>;
export type CreateSchoolMutationOptions = Apollo.BaseMutationOptions<
    CreateSchoolMutationType,
    CreateSchoolMutationVariables
>;
export const RemoveSchoolDocument = gql`
    mutation RemoveSchool($id: ID!) {
        removeSchool(id: $id)
    }
`;
export type RemoveSchoolMutationFn = Apollo.MutationFunction<
    RemoveSchoolMutationType,
    RemoveSchoolMutationVariables
>;
export type RemoveSchoolMutationOptions = Apollo.BaseMutationOptions<
    RemoveSchoolMutationType,
    RemoveSchoolMutationVariables
>;
export const SendSchoolAccessDocument = gql`
    mutation SendSchoolAccess($id: ID!) {
        sendSchoolAccess(id: $id)
    }
`;
export type SendSchoolAccessMutationFn = Apollo.MutationFunction<
    SendSchoolAccessMutationType,
    SendSchoolAccessMutationVariables
>;
export type SendSchoolAccessMutationOptions = Apollo.BaseMutationOptions<
    SendSchoolAccessMutationType,
    SendSchoolAccessMutationVariables
>;
export const UpdateSchoolDocument = gql`
    mutation UpdateSchool($id: ID!, $item: SchoolArgs!) {
        updateSchool(id: $id, item: $item) {
            ...SchoolSingle
        }
    }
    ${SchoolSingleFragmentDoc}
`;
export type UpdateSchoolMutationFn = Apollo.MutationFunction<
    UpdateSchoolMutationType,
    UpdateSchoolMutationVariables
>;
export type UpdateSchoolMutationOptions = Apollo.BaseMutationOptions<
    UpdateSchoolMutationType,
    UpdateSchoolMutationVariables
>;
export const GetSchoolCollectionLazyDocument = gql`
    query GetSchoolCollectionLazy($args: SchoolCollectionArgs) {
        getSchoolCollection(args: $args)
            @connection(key: "getSchoolCollectionLazy", filter: ["args"]) {
            items {
                id
                shortName
                lapAccessList {
                    ...LapAccess
                }
            }
            page {
                ...PageReply
            }
        }
    }
    ${LapAccessFragmentDoc}
    ${PageReplyFragmentDoc}
`;
export const GetSchoolCollectionDocument = gql`
    query GetSchoolCollection($args: SchoolCollectionArgs) {
        getSchoolCollection(args: $args) @connection(key: "getSchoolCollection", filter: ["args"]) {
            items {
                ...SchoolCollection
            }
            page {
                ...PageReply
            }
        }
    }
    ${SchoolCollectionFragmentDoc}
    ${PageReplyFragmentDoc}
`;
export const GetSchoolSummaryStatsDocument = gql`
    query GetSchoolSummaryStats($schoolId: ID!, $lapId: ID) {
        getSchoolSummaryStats(schoolId: $schoolId, lapId: $lapId) {
            total
            completed
            out
            averageScore
        }
    }
`;
export const GetSchoolDocument = gql`
    query GetSchool($id: ID!) {
        getSchool(id: $id) {
            ...SchoolSingle
        }
    }
    ${SchoolSingleFragmentDoc}
`;
export const GetSchoolsExportUrlDocument = gql`
    query GetSchoolsExportUrl {
        getSchoolsExportUrl
    }
`;
export const UpdateUserGrantsTableDocument = gql`
    mutation UpdateUserGrantsTable($items: [UserGrantsTableItemArgs!]!) {
        updateUserGrantsTable(items: $items)
    }
`;
export type UpdateUserGrantsTableMutationFn = Apollo.MutationFunction<
    UpdateUserGrantsTableMutationType,
    UpdateUserGrantsTableMutationVariables
>;
export type UpdateUserGrantsTableMutationOptions = Apollo.BaseMutationOptions<
    UpdateUserGrantsTableMutationType,
    UpdateUserGrantsTableMutationVariables
>;
export const GetUserGrantsTableDocument = gql`
    query GetUserGrantsTable {
        getUserGrantsTable {
            roles
            rows {
                key
                name
                isCategory
                isAction
                categoryId
                parentCategoryId
                grants {
                    id
                    category
                    action
                    granted
                }
            }
        }
    }
`;
export const UpdateFileCropDocument = gql`
    mutation UpdateFileCrop($id: ID!, $cropInfo: ImageCropInfoArg) {
        updateFileCrop(id: $id, cropInfo: $cropInfo) {
            croppedThumbnail
        }
    }
`;
export type UpdateFileCropMutationFn = Apollo.MutationFunction<
    UpdateFileCropMutationType,
    UpdateFileCropMutationVariables
>;
export type UpdateFileCropMutationOptions = Apollo.BaseMutationOptions<
    UpdateFileCropMutationType,
    UpdateFileCropMutationVariables
>;
export const GetLocationsDocument = gql`
    query GetLocations($regionId: ID, $lapId: ID) {
        getLocations(regionId: $regionId, lapId: $lapId) {
            id
            name
        }
    }
`;
export const GetRegionsDocument = gql`
    query GetRegions($args: RegionArgs) {
        getRegions(args: $args) {
            id
            name
        }
    }
`;
export const GetSchoolNumbersDocument = gql`
    query GetSchoolNumbers($locationId: ID, $regionId: ID) {
        getSchoolNumbers(locationId: $locationId, regionId: $regionId)
    }
`;
export const GetSchoolTypesDocument = gql`
    query GetSchoolTypes($locationId: ID!) {
        getSchoolTypes(locationId: $locationId)
    }
`;
export const GetEgLessonsStatisticDocument = gql`
    query GetEGLessonsStatistic($args: EGLessonStatisticArgs) {
        getEGLessonsStatistic(args: $args) @connection(key: "getEGLessonsStatistic") {
            items {
                ...EGLessonsStatisticItemSingle
            }
            page {
                ...PageReply
            }
        }
    }
    ${EgLessonsStatisticItemSingleFragmentDoc}
    ${PageReplyFragmentDoc}
`;
export const GetEgLessonScoresStatisticDocument = gql`
    query GetEGLessonScoresStatistic($args: EGLessonScoresStatisticArgs) {
        getEGLessonScoresStatistic(args: $args) @connection(key: "getEGLessonScoresStatistic") {
            items {
                ...EGLessonScoresStatisticItemSingle
            }
            dates
        }
    }
    ${EgLessonScoresStatisticItemSingleFragmentDoc}
`;
export const GetEgLessonScoresStudentDocument = gql`
    query GetEGLessonScoresStudent($studentId: ID!, $subjectId: ID!) {
        getEGLessonScoresStudent(studentId: $studentId, subjectId: $subjectId) {
            student {
                ...StudentMiniInfoSingle
            }
            items {
                ...EGLessonScoresStudentItemSingle
            }
        }
    }
    ${StudentMiniInfoSingleFragmentDoc}
    ${EgLessonScoresStudentItemSingleFragmentDoc}
`;
export const GetEgSubjectsInLapDocument = gql`
    query GetEGSubjectsInLap {
        getEGSubjectsInLap {
            ...SubjectSingle
        }
    }
    ${SubjectSingleFragmentDoc}
`;
export const CreateStudentDocument = gql`
    mutation CreateStudent($item: StudentArgs!) {
        createStudent(item: $item) {
            ...StudentSingle
        }
    }
    ${StudentSingleFragmentDoc}
`;
export type CreateStudentMutationFn = Apollo.MutationFunction<
    CreateStudentMutationType,
    CreateStudentMutationVariables
>;
export type CreateStudentMutationOptions = Apollo.BaseMutationOptions<
    CreateStudentMutationType,
    CreateStudentMutationVariables
>;
export const DistributeStudentsDocument = gql`
    mutation DistributeStudents($lapId: ID!) {
        distributeStudents(lapId: $lapId) {
            distributed
            total
        }
    }
`;
export type DistributeStudentsMutationFn = Apollo.MutationFunction<
    DistributeStudentsMutationType,
    DistributeStudentsMutationVariables
>;
export type DistributeStudentsMutationOptions = Apollo.BaseMutationOptions<
    DistributeStudentsMutationType,
    DistributeStudentsMutationVariables
>;
export const ImportStudentsDocument = gql`
    mutation ImportStudents($fileId: ID!) {
        importStudents(fileId: $fileId) {
            createdCount
            updatedCount
            totalCount
            errors {
                line
                texts
            }
        }
    }
`;
export type ImportStudentsMutationFn = Apollo.MutationFunction<
    ImportStudentsMutationType,
    ImportStudentsMutationVariables
>;
export type ImportStudentsMutationOptions = Apollo.BaseMutationOptions<
    ImportStudentsMutationType,
    ImportStudentsMutationVariables
>;
export const RemoveStudentDocument = gql`
    mutation RemoveStudent($id: ID!) {
        removeStudent(id: $id)
    }
`;
export type RemoveStudentMutationFn = Apollo.MutationFunction<
    RemoveStudentMutationType,
    RemoveStudentMutationVariables
>;
export type RemoveStudentMutationOptions = Apollo.BaseMutationOptions<
    RemoveStudentMutationType,
    RemoveStudentMutationVariables
>;
export const SendStudentAccessDocument = gql`
    mutation SendStudentAccess($id: ID!) {
        sendStudentAccess(id: $id)
    }
`;
export type SendStudentAccessMutationFn = Apollo.MutationFunction<
    SendStudentAccessMutationType,
    SendStudentAccessMutationVariables
>;
export type SendStudentAccessMutationOptions = Apollo.BaseMutationOptions<
    SendStudentAccessMutationType,
    SendStudentAccessMutationVariables
>;
export const UpdateStudentDocument = gql`
    mutation UpdateStudent($id: ID!, $item: StudentArgs!) {
        updateStudent(id: $id, item: $item) {
            ...StudentSingle
        }
    }
    ${StudentSingleFragmentDoc}
`;
export type UpdateStudentMutationFn = Apollo.MutationFunction<
    UpdateStudentMutationType,
    UpdateStudentMutationVariables
>;
export type UpdateStudentMutationOptions = Apollo.BaseMutationOptions<
    UpdateStudentMutationType,
    UpdateStudentMutationVariables
>;
export const GetBuildingsDocument = gql`
    query GetBuildings {
        getBuildings {
            id
            name
            rooms {
                id
                name
            }
        }
    }
`;
export const GetStudentCollectionDocument = gql`
    query GetStudentCollection($args: StudentCollectionArgs) {
        getStudentCollection(args: $args) @connection(key: "getStudentCollection") {
            items {
                id
                fullName
                birthdate
                phone
                company
                platoon
                platoonX
                isOnline
                accessSentStatusText
                accessSentStatus
                isCommercial
                isOut
                school {
                    id
                    type
                    shortName
                    number
                    location {
                        id
                        name
                    }
                }
                photo {
                    ...FileInfo
                }
            }
            page {
                ...PageReply
            }
        }
    }
    ${FileInfoFragmentDoc}
    ${PageReplyFragmentDoc}
`;
export const GetStudentProfileCountsDocument = gql`
    query GetStudentProfileCounts(
        $id: ID!
        $lapByStudentId: Boolean
        $lapFrom: Date
        $lapTo: Date
    ) {
        getStudentProfileCounts(
            id: $id
            lapByStudentId: $lapByStudentId
            lapFrom: $lapFrom
            lapTo: $lapTo
        ) {
            fullName
            egScore
            evaluationCriteriaScore
            placeInOverallRatings
            placeInPlatoonRatings
        }
    }
`;
export const GetStudentProfileDocument = gql`
    query GetStudentProfile($id: ID!) {
        getStudentProfile(id: $id) {
            id
            fullName
            photo {
                ...FileInfo
            }
            qrCodeUrl
            sections {
                name
                items {
                    label
                    value
                    link
                }
            }
        }
    }
    ${FileInfoFragmentDoc}
`;
export const GetStudentTroopNumbersDocument = gql`
    query GetStudentTroopNumbers {
        getStudentTroopNumbers {
            companies
            platoons
            platoonsX
        }
    }
`;
export const GetStudentTroopsExportUrlDocument = gql`
    query GetStudentTroopsExportUrl {
        getStudentTroopsExportUrl
    }
`;
export const GetStudentDocument = gql`
    query GetStudent($id: ID!) {
        getStudent(id: $id) {
            ...StudentSingle
        }
    }
    ${StudentSingleFragmentDoc}
`;
export const GetStudentsImportTemplateUrlDocument = gql`
    query GetStudentsImportTemplateUrl($schoolId: ID) {
        getStudentsImportTemplateUrl(schoolId: $schoolId)
    }
`;
export const RemoveOlLectureDocument = gql`
    mutation removeOLLecture($id: ID!) {
        removeOLLecture(id: $id)
    }
`;
export type RemoveOlLectureMutationFn = Apollo.MutationFunction<
    RemoveOlLectureMutationType,
    RemoveOlLectureMutationVariables
>;
export type RemoveOlLectureMutationOptions = Apollo.BaseMutationOptions<
    RemoveOlLectureMutationType,
    RemoveOlLectureMutationVariables
>;
export const UpdateOlLectureDocument = gql`
    mutation updateOLLecture($id: ID!, $item: OLLectureArgs!) {
        updateOLLecture(id: $id, item: $item) {
            ...LectureSingle
        }
    }
    ${LectureSingleFragmentDoc}
`;
export type UpdateOlLectureMutationFn = Apollo.MutationFunction<
    UpdateOlLectureMutationType,
    UpdateOlLectureMutationVariables
>;
export type UpdateOlLectureMutationOptions = Apollo.BaseMutationOptions<
    UpdateOlLectureMutationType,
    UpdateOlLectureMutationVariables
>;
export const GetOlLectureDocument = gql`
    query GetOLLecture($id: ID!) {
        getOLLecture(id: $id) {
            ...LectureSingle
        }
    }
    ${LectureSingleFragmentDoc}
`;
export const CreateOlSubjectDocument = gql`
    mutation CreateOLSubject($item: OLSubjectCreateArgs!) {
        createOLSubject(item: $item) {
            ...OLSubjectSingle
        }
    }
    ${OlSubjectSingleFragmentDoc}
`;
export type CreateOlSubjectMutationFn = Apollo.MutationFunction<
    CreateOlSubjectMutationType,
    CreateOlSubjectMutationVariables
>;
export type CreateOlSubjectMutationOptions = Apollo.BaseMutationOptions<
    CreateOlSubjectMutationType,
    CreateOlSubjectMutationVariables
>;
export const RemoveOlSubjectDocument = gql`
    mutation removeOLSubject($id: ID!) {
        removeOLSubject(id: $id)
    }
`;
export type RemoveOlSubjectMutationFn = Apollo.MutationFunction<
    RemoveOlSubjectMutationType,
    RemoveOlSubjectMutationVariables
>;
export type RemoveOlSubjectMutationOptions = Apollo.BaseMutationOptions<
    RemoveOlSubjectMutationType,
    RemoveOlSubjectMutationVariables
>;
export const SetOlSubjectsToEmployeeDocument = gql`
    mutation setOLSubjectsToEmployee($employeeId: ID!, $olSubjectIds: [ID!]!) {
        setOLSubjectsToEmployee(employeeId: $employeeId, olSubjectIds: $olSubjectIds)
    }
`;
export type SetOlSubjectsToEmployeeMutationFn = Apollo.MutationFunction<
    SetOlSubjectsToEmployeeMutationType,
    SetOlSubjectsToEmployeeMutationVariables
>;
export type SetOlSubjectsToEmployeeMutationOptions = Apollo.BaseMutationOptions<
    SetOlSubjectsToEmployeeMutationType,
    SetOlSubjectsToEmployeeMutationVariables
>;
export const UpdateOlSubjectDocument = gql`
    mutation updateOLSubject($id: ID!, $item: OLSubjectUpdateArgs!) {
        updateOLSubject(id: $id, item: $item) {
            ...OLSubjectSingle
        }
    }
    ${OlSubjectSingleFragmentDoc}
`;
export type UpdateOlSubjectMutationFn = Apollo.MutationFunction<
    UpdateOlSubjectMutationType,
    UpdateOlSubjectMutationVariables
>;
export type UpdateOlSubjectMutationOptions = Apollo.BaseMutationOptions<
    UpdateOlSubjectMutationType,
    UpdateOlSubjectMutationVariables
>;
export const GetEmployeeOlSubjectCollectionDocument = gql`
    query GetEmployeeOlSubjectCollection($employeeId: ID) {
        getEmployeeOLSubjects(employeeId: $employeeId) @connection(key: "getEmployeeOLSubjects") {
            ...OLSubjectSingle
        }
    }
    ${OlSubjectSingleFragmentDoc}
`;
export const GetOlSubjectCollectionDocument = gql`
    query GetOlSubjectCollection($args: OLSubjectCollectionArgs) {
        getOLSubjectCollection(args: $args) @connection(key: "getOLSubjectCollection") {
            ...OLSubjectCollection
        }
    }
    ${OlSubjectCollectionFragmentDoc}
`;
export const GetOlSubjectDocument = gql`
    query GetOlSubject($id: ID!) {
        getOLSubject(id: $id) {
            ...OLSubjectSingle
        }
    }
    ${OlSubjectSingleFragmentDoc}
`;
export const GetOlSubjectsDocument = gql`
    query GetOlSubjects {
        getOLSubjects {
            ...OLSubjectSingle
        }
    }
    ${OlSubjectSingleFragmentDoc}
`;
export const CreateTestQuestionDocument = gql`
    mutation CreateTestQuestion($item: OLTestQuestionArgs!) {
        createOLTestQuestion(item: $item) {
            ...TestQuestionSingle
        }
    }
    ${TestQuestionSingleFragmentDoc}
`;
export type CreateTestQuestionMutationFn = Apollo.MutationFunction<
    CreateTestQuestionMutationType,
    CreateTestQuestionMutationVariables
>;
export type CreateTestQuestionMutationOptions = Apollo.BaseMutationOptions<
    CreateTestQuestionMutationType,
    CreateTestQuestionMutationVariables
>;
export const RemoveTestQuestionDocument = gql`
    mutation RemoveTestQuestion($id: ID!) {
        removeOLTestQuestion(id: $id)
    }
`;
export type RemoveTestQuestionMutationFn = Apollo.MutationFunction<
    RemoveTestQuestionMutationType,
    RemoveTestQuestionMutationVariables
>;
export type RemoveTestQuestionMutationOptions = Apollo.BaseMutationOptions<
    RemoveTestQuestionMutationType,
    RemoveTestQuestionMutationVariables
>;
export const UpdateTestQuestionDocument = gql`
    mutation UpdateTestQuestion($id: ID!, $item: OLTestQuestionArgs!) {
        updateOLTestQuestion(id: $id, item: $item) {
            ...TestQuestionSingle
        }
    }
    ${TestQuestionSingleFragmentDoc}
`;
export type UpdateTestQuestionMutationFn = Apollo.MutationFunction<
    UpdateTestQuestionMutationType,
    UpdateTestQuestionMutationVariables
>;
export type UpdateTestQuestionMutationOptions = Apollo.BaseMutationOptions<
    UpdateTestQuestionMutationType,
    UpdateTestQuestionMutationVariables
>;
export const GetTestQuestionCollectionDocument = gql`
    query GetTestQuestionCollection($subjectId: ID!) {
        getOLTestQuestionCollection(subjectId: $subjectId) {
            items {
                ...TestQuestionSingle
            }
        }
    }
    ${TestQuestionSingleFragmentDoc}
`;
export const GetSummaryLapsGenderStudentsByRegionResultsDocument = gql`
    query GetSummaryLapsGenderStudentsByRegionResults($filter: SummaryResultsFilter) {
        getSummaryLapsGenderStudentsByRegionResults(filter: $filter) {
            regionName
            summary
            sections {
                gender
                summary
                items {
                    age
                    count
                }
            }
        }
    }
`;
export const GetSummaryLapsProgramsResultsDocument = gql`
    query GetSummaryLapsProgramsResults($filter: SummaryResultsFilter) {
        getSummaryLapsProgramsResults(filter: $filter) {
            type
            count
        }
    }
`;
export const GetSummaryLapsResultsDocument = gql`
    query GetSummaryLapsResults($filter: SummaryResultsFilter) {
        getSummaryLapsResults(filter: $filter) {
            allCount
            regionCounts {
                regionName
                count
            }
        }
    }
`;
export const GetSummaryLapsSchoolCollectionDocument = gql`
    query GetSummaryLapsSchoolCollection($args: SummaryLapsSchoolCollectionArgs) {
        getSummaryLapsSchoolCollection(args: $args)
            @connection(key: "getSummaryLapsSchoolCollection", filter: ["args"]) {
            items {
                ...SchoolCollection
            }
            page {
                ...PageReply
            }
        }
    }
    ${SchoolCollectionFragmentDoc}
    ${PageReplyFragmentDoc}
`;
export const GetSummaryLapsStudentCollectionDocument = gql`
    query GetSummaryLapsStudentCollection($args: SummaryLapsStudentCollectionArgs) {
        getSummaryLapsStudentCollection(args: $args)
            @connection(key: "getSummaryLapsStudentCollection", filter: ["args"]) {
            items {
                id
                fullName
                birthdate
                phone
                company
                platoon
                platoonX
                isOnline
                accessSentStatusText
                accessSentStatus
                isCommercial
                isOut
                school {
                    id
                    type
                    shortName
                    number
                    location {
                        id
                        name
                    }
                }
                photo {
                    ...FileInfo
                }
            }
            page {
                ...PageReply
            }
        }
    }
    ${FileInfoFragmentDoc}
    ${PageReplyFragmentDoc}
`;
export const GetSummaryLapsStudentsResultsDocument = gql`
    query GetSummaryLapsStudentsResults($filter: SummaryResultsFilter) {
        getSummaryLapsStudentsResults(filter: $filter) {
            schoolsCount
            passedStudentsCount
            commercialStudentsCount
            outStudentsCount
        }
    }
`;
export const GetSummaryStudentsExportUrlDocument = gql`
    query GetSummaryStudentsExportUrl($filter: SummaryResultsFilter) {
        getSummaryStudentsExportUrl(filter: $filter)
    }
`;
export const AnswerToQuestionDocument = gql`
    mutation AnswerToQuestion($subjectId: ID, $courseId: ID!, $answer: OLTestUserAnswerArgs!) {
        answerTheQuestion(subjectId: $subjectId, courseId: $courseId, answer: $answer) {
            ...Test
        }
    }
    ${TestFragmentDoc}
`;
export type AnswerToQuestionMutationFn = Apollo.MutationFunction<
    AnswerToQuestionMutationType,
    AnswerToQuestionMutationVariables
>;
export type AnswerToQuestionMutationOptions = Apollo.BaseMutationOptions<
    AnswerToQuestionMutationType,
    AnswerToQuestionMutationVariables
>;
export const StartTestDocument = gql`
    mutation StartTest($subjectId: ID, $courseId: ID!) {
        startOLTest(subjectId: $subjectId, courseId: $courseId) {
            ...Test
        }
    }
    ${TestFragmentDoc}
`;
export type StartTestMutationFn = Apollo.MutationFunction<
    StartTestMutationType,
    StartTestMutationVariables
>;
export type StartTestMutationOptions = Apollo.BaseMutationOptions<
    StartTestMutationType,
    StartTestMutationVariables
>;
export const StopTestDocument = gql`
    mutation StopTest($subjectId: ID, $courseId: ID!) {
        stopOLTest(subjectId: $subjectId, courseId: $courseId) {
            ...Test
        }
    }
    ${TestFragmentDoc}
`;
export type StopTestMutationFn = Apollo.MutationFunction<
    StopTestMutationType,
    StopTestMutationVariables
>;
export type StopTestMutationOptions = Apollo.BaseMutationOptions<
    StopTestMutationType,
    StopTestMutationVariables
>;
export const GetActiveTestDocument = gql`
    query GetActiveTest {
        getActiveOLTest {
            name
            exists
            subjectId
            isSubject
        }
    }
`;
export const GetTestDocument = gql`
    query GetTest($subjectId: ID, $courseId: ID!) {
        getOLTest(subjectId: $subjectId, courseId: $courseId) {
            ...Test
        }
    }
    ${TestFragmentDoc}
`;
export const GetTestingQuestionDocument = gql`
    query GetTestingQuestion($id: ID!) {
        getOLTestQuestion(id: $id) {
            id
            subjectId
            text
            photoIds
            correctAnswersCount
            answers {
                id
                text
                photo {
                    ...FileInfo
                }
            }
            photos {
                ...FileInfo
            }
        }
    }
    ${FileInfoFragmentDoc}
`;
export const GetCurrentUserDocument = gql`
    query GetCurrentUser {
        getCurrentUser {
            id
            username
            fullName
            email
            roles
            createdAt
            updatedAt
            student {
                id
                fullName
            }
            school {
                id
                shortName
                currentStudentsCount
                maxStudentsCount
                studentsAddMode
                canDeleteStudent
            }
            grants {
                category
                actions
            }
        }
    }
`;
export const CreateWikiArticleDocument = gql`
    mutation createWikiArticle($item: InternalWikiArticleCreateArgs!) {
        createInternalWikiArticle(item: $item) {
            ...WikiArticleSingle
        }
    }
    ${WikiArticleSingleFragmentDoc}
`;
export type CreateWikiArticleMutationFn = Apollo.MutationFunction<
    CreateWikiArticleMutationType,
    CreateWikiArticleMutationVariables
>;
export type CreateWikiArticleMutationOptions = Apollo.BaseMutationOptions<
    CreateWikiArticleMutationType,
    CreateWikiArticleMutationVariables
>;
export const RemoveWikiArticleDocument = gql`
    mutation removeWikiArticle($id: ID!) {
        removeInternalWikiArticle(id: $id)
    }
`;
export type RemoveWikiArticleMutationFn = Apollo.MutationFunction<
    RemoveWikiArticleMutationType,
    RemoveWikiArticleMutationVariables
>;
export type RemoveWikiArticleMutationOptions = Apollo.BaseMutationOptions<
    RemoveWikiArticleMutationType,
    RemoveWikiArticleMutationVariables
>;
export const UpdateWikiArticleDocument = gql`
    mutation updateWikiArticle($id: ID!, $item: InternalWikiArticleUpdateArgs!) {
        updateInternalWikiArticle(id: $id, item: $item) {
            ...WikiArticleSingle
        }
    }
    ${WikiArticleSingleFragmentDoc}
`;
export type UpdateWikiArticleMutationFn = Apollo.MutationFunction<
    UpdateWikiArticleMutationType,
    UpdateWikiArticleMutationVariables
>;
export type UpdateWikiArticleMutationOptions = Apollo.BaseMutationOptions<
    UpdateWikiArticleMutationType,
    UpdateWikiArticleMutationVariables
>;
export const GetWikiArticleDocument = gql`
    query GetWikiArticle($id: ID!) {
        getInternalWikiArticle(id: $id) {
            ...WikiArticleSingle
        }
    }
    ${WikiArticleSingleFragmentDoc}
`;
export const GetWikiArticlesDocument = gql`
    query GetWikiArticles($categoryId: ID!) {
        getInternalWikiArticles(categoryId: $categoryId) {
            ...WikiArticleSingle
        }
    }
    ${WikiArticleSingleFragmentDoc}
`;
export const CreateWikiCategoryDocument = gql`
    mutation createWikiCategory($item: InternalWikiCategoryArgs!) {
        createInternalWikiCategory(item: $item) {
            ...WikiCategorySingle
        }
    }
    ${WikiCategorySingleFragmentDoc}
`;
export type CreateWikiCategoryMutationFn = Apollo.MutationFunction<
    CreateWikiCategoryMutationType,
    CreateWikiCategoryMutationVariables
>;
export type CreateWikiCategoryMutationOptions = Apollo.BaseMutationOptions<
    CreateWikiCategoryMutationType,
    CreateWikiCategoryMutationVariables
>;
export const RemoveWikiCategoryDocument = gql`
    mutation removeWikiCategory($id: ID!) {
        removeInternalWikiCategory(id: $id)
    }
`;
export type RemoveWikiCategoryMutationFn = Apollo.MutationFunction<
    RemoveWikiCategoryMutationType,
    RemoveWikiCategoryMutationVariables
>;
export type RemoveWikiCategoryMutationOptions = Apollo.BaseMutationOptions<
    RemoveWikiCategoryMutationType,
    RemoveWikiCategoryMutationVariables
>;
export const UpdateWikiCategoryDocument = gql`
    mutation updateWikiCategory($id: ID!, $item: InternalWikiCategoryArgs!) {
        updateInternalWikiCategory(id: $id, item: $item) {
            ...WikiCategorySingle
        }
    }
    ${WikiCategorySingleFragmentDoc}
`;
export type UpdateWikiCategoryMutationFn = Apollo.MutationFunction<
    UpdateWikiCategoryMutationType,
    UpdateWikiCategoryMutationVariables
>;
export type UpdateWikiCategoryMutationOptions = Apollo.BaseMutationOptions<
    UpdateWikiCategoryMutationType,
    UpdateWikiCategoryMutationVariables
>;
export const FindWikiDocument = gql`
    query findWiki($filter: FindInternalWikiFilter) {
        findInternalWiki(filter: $filter) @connection(key: "findInternalWiki") {
            itemsCount
            categories {
                id
                name
                articlesCount
            }
            articles {
                id
                categoryId
                name
            }
        }
    }
`;
export const GetWikiCategoriesDocument = gql`
    query getWikiCategories {
        getInternalWikiCategories {
            ...WikiCategorySingle
        }
    }
    ${WikiCategorySingleFragmentDoc}
`;
export const GetWikiCategoryDocument = gql`
    query GetWikiCategory($id: ID!) {
        getInternalWikiCategory(id: $id) {
            ...WikiCategorySingle
        }
    }
    ${WikiCategorySingleFragmentDoc}
`;
