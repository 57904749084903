import format from 'date-fns/format';

import { BaseEntity, Entity as DecribeEntity, RelationList } from '@lib/entity';
import { i18n, getLocaleForDateFns } from '@lib/i18n';
import { EmployeeEntityList } from '@modules/employees/entities';
import { UserRole } from '@modules/types/graphql';

import type { UserEntity } from '@modules/user/entities';
import type { EmployeeEntity } from '@modules/employees/entities';
import type { EgLessonScoreCollection } from '@modules/types/graphql';

export type Entity = Partial<EgLessonScoreCollection> | null;

@DecribeEntity('LessonScoreEntity')
class LessonScoreEntity extends BaseEntity {
    company: number;
    platoon: number;
    platoonX: number;
    lessonDate: string;
    lessonTime: string;
    lessonPlace: string;
    lessonIndex?: number;

    @RelationList(() => EmployeeEntityList)
    instructors: EmployeeEntity[];

    constructor(lessonScore: Entity) {
        super(lessonScore);

        this.company = lessonScore?.company ?? 0;
        this.platoon = lessonScore?.platoon ?? 0;
        this.platoonX = lessonScore?.platoonX ?? 0;
        this.lessonDate = lessonScore?.lessonDate ?? '';
        this.lessonTime = lessonScore?.lessonTime ?? '';
        this.lessonPlace = lessonScore?.lessonPlace ?? '';
        this.lessonIndex = lessonScore?.lessonIndex ?? undefined;
    }

    getInstructorsName() {
        const names = this.instructors.map(instructor => instructor.fullName || '-');

        return names;
    }

    getCompanyPlatoonLabel() {
        if (this.company === 0 || this.platoon === 0) {
            return '-';
        }

        const result = i18n.t('common:blocks.company_and_platoons.company_and_platoon_with_count', {
            company: this.company,
            platoon: this.platoon,
        });

        return result;
    }

    getSummaryPlatoonLabel() {
        if (this.platoonX === 0) {
            return '-';
        }

        const result = i18n.t('common:blocks.company_and_platoons.platoon_with_count', {
            platoon: this.platoonX,
        });

        return result;
    }

    getLessonIndexLabel() {
        if (this.lessonIndex === undefined) {
            return '';
        }

        const label = i18n.t('diary:schedule.blocks.lesson.lesson_with_index', {
            index: this.lessonIndex,
        });

        return label;
    }

    getDateLabel() {
        if (!this.lessonDate) {
            return '-';
        }

        const date = new Date(this.lessonDate);

        const fullDate = format(date, 'EEEEEE, dd.MM.yyyy', { locale: getLocaleForDateFns() });

        return fullDate;
    }

    canSetScoreBy(user: UserEntity) {
        const role = user.getCurrentRole();

        let can = true;

        if (role && [UserRole.mentor, UserRole.leader].includes(role)) {
            can = this.instructors.some(instructor => instructor.attachedUser.id === user.id);
        }

        return can;
    }
}

export { LessonScoreEntity };
